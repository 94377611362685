<template>
  <v-dialog @input="$emit('input', $event)" :value="true" :max-width="800">
    <div class="popup__profile">
      <div class="popup__profile--inner">
        <div
          class="close-btn"
          @click="$emit('input', false)"
          style="cursor: pointer"
        >
          <img
            src="https://storage.yandexcloud.net/yo-events/advent-2025/img/OTR/icon-close-v2.png"
            style="width: 16px; height: 16px"
          />
        </div>
        <h3>Профиль участника</h3>
        <div class="popup__profile--info">
          <div class="popup__profile--info--img">
            <img :src="avatarContent || userProfile.image || menuWelcomeImg" />
            <div class="popup__profile--info--edit" @click="onEditAvatarClick">
              <img
                src="https://storage.yandexcloud.net/yo-events/advent-2025/img/OTR/icon-profile-edit.png"
              />
              <span>Изменить фото</span>
            </div>
            <h4>{{ userProfile.name }}</h4>
            <input
              type="file"
              style="display: none"
              ref="avatarFile"
              @input="onAvatarChange"
              accept="image/png, image/jpeg, image/heic"
            />
            <button v-if="false">Вызвать на поединок</button>
            <!-- Кнопка для редактирования профиля <button>Сохранить</button> -->
          </div>
          <div class="popup__profile--info--about">
            <!-- Блоки для редактирования -->
            <label for="about">Расскажите о себе</label>
            <textarea
              name="about"
              class="quote"
              v-model="editProfileForm.about"
            />
            <label for="mycity">Родной город</label>
            <input
              type="text"
              name="mycity"
              v-model="editProfileForm.birthCity"
            />
            <label for="interestingFact">Интересный факт из жизни</label>
            <textarea
              name="interestingFact"
              class="quote"
              v-model="editProfileForm.interestingFact"
            />

            <!-- Блоки для редактирования -->
            <label for="eventOfTheYear">Событие года</label>
            <textarea
              name="eventOfTheYear"
              class="quote"
              v-model="editProfileForm.eventOfTheYear"
            />

            <p v-if="userProfile.city">
              <span>Город: </span>{{ userProfile.city }}
            </p>
            <p v-if="userProfile.dept">
              <span>Отдел: </span>{{ userProfile.dept }}
            </p>
            <button @click="onSave">Сохранить</button>
          </div>
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import eventNavBarMixin from "@/mixins/eventNavBarMixin";
import Vue from "vue";
import themeMixin from "@/mixins/eventTheme";
import _ from "lodash";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "MyProfilePopup",
  mixins: [eventNavBarMixin, themeMixin],
  data() {
    return {
      editProfileDialogRules: [
        (value) =>
          !value ||
          value.size < 10000000 ||
          "Avatar size should be less than 10 MB!",
      ],
      editProfileForm: {
        name: null,
        about: null,
        pvp: null,
        eventOfTheYear: null,
      },
      avatarContent: null,
    };
  },
  created() {
    this.initProfileForm();
  },
  methods: {
    ...mapActions({
      updateMyProfile: "event/updateMyProfile",
      updateMyAvatar: "event/updateMyAvatar",
    }),
    initProfileForm() {
      this.editProfileForm = {
        name: _.get(this.userProfile, "name", ""),
        about: _.get(this.userProfile, "about", ""),
        interestingFact: _.get(this.userProfile, "interestingFact", ""),
        birthCity: _.get(this.userProfile, "birthCity", ""),
        eventOfTheYear: _.get(this.userProfile, "eventOfTheYear", ""),
        pvp: _.get(this.userProfile, "pvp", true),
        avatar: null,
      };
    },
    async onSave() {
      await this.updateMyProfile(this.editProfileForm);
      if (this.editProfileForm.avatar != null) {
        await this.updateMyAvatar(this.editProfileForm.avatar);
      }
      this.editProfileDialog = false;
      Vue.$toast.open({
        type: "info",
        message: "Профиль успешно изменен",
        position: "top",
        duration: 5000,
        dismissible: true,
      });
    },
    onEditAvatarClick() {
      this.$refs.avatarFile.click();
    },
    onAvatarChange(evt) {
      const tgt = evt.target || window.event.srcElement;
      const files = tgt.files;

      if (FileReader && files && files.length) {
        const f = files[0];
        if (f.size > 10 * 1000 * 1000) {
          return Vue.$toast.open({
            type: "error",
            message: "Фото не может быть больше 10 мб.",
            position: "top",
            duration: 5000,
            dismissible: true,
          });
        }
        const fr = new FileReader();
        fr.onload = () => {
          this.editProfileForm.avatar = f;
          this.avatarContent = fr.result;
        };
        fr.readAsDataURL(f);
      }
    },
  },
  computed: {
    ...mapGetters({
      event: "event/event",
    }),
    userProfile() {
      return _.get(this.user, "profile");
    },
    menuWelcomeImg() {
      return _.get(this.event, "meta.menu-welcome");
    },
  },
  watch: {},
};
</script>

<style scoped>
.popup__profile {
  box-sizing: border-box;
  z-index: 1000;
  max-width: 800px;
  margin: 0 auto;
  overflow-y: auto;
}
.popup__profile--inner {
  box-sizing: border-box;
  padding: 30px 70px;
  border-radius: 10px;
  border: 1px solid var(--color-primary);
  background: #fbf4e4; /* ЗАМЕНИТЬ НА ПЕРЕМЕННУЮ */
  display: flex;
  flex-direction: column;
  position: relative;
}
.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 24px;
  height: 24px;
  cursor: pointer;
  z-index: 1001;
}
.close-btn img {
  width: 24px;
  height: 24px;
}
.popup__profile--inner::-webkit-scrollbar {
  width: 5px;
}
.popup__profile--inner::-webkit-scrollbar-track {
  background-color: transparent;
}
.popup__profile--inner::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar-color);
  border-radius: 10px;
}
.popup__profile--inner h3 {
  font-family: var(--secondary-font);
  font-size: 20px;
  color: var(--color-primary);
  text-align: center;
  margin-bottom: 20px;
  text-transform: uppercase;
}
.popup__profile--info {
  display: flex;
  gap: 50px;
  justify-content: space-between;
}
.popup__profile--info--img {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.popup__profile--info--img > img {
  width: 150px;
  height: 150px;
  border-radius: 100px;
  margin-bottom: 10px;
  object-fit: cover;
}
.popup__profile--info--img > h4 {
  font-family: var(--main-font);
  color: var(--color-text);
  font-size: 16px;
  text-align: center;
  font-weight: 500;
  margin-bottom: 20px;
}
button {
  padding: 10px;
  border: none;
  border-radius: 20px;
  background: var(--color-primary);
  color: #fff;
  font-family: var(--main-font);
  font-size: 12px;
  cursor: pointer;
  max-width: 200px;
}
.popup__profile--info--edit {
  display: flex;
  align-items: center;
  gap: 4px;
  margin-bottom: 10px;
  cursor: pointer;
}
.popup__profile--info--edit > img {
  width: 19px;
  height: 19px;
}
.popup__profile--info--edit > span {
  font-family: var(--main-font);
  font-size: 14px;
  color: var(--color-text-prof-edit);
}
.popup__profile--info--about {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  gap: 15px;
  font: var(--main-font);
  font-size: 14px;
  color: var(--color-text);
}
.popup__profile--info--about p.quote {
  padding: 10px;
  background: #fff;
  border-radius: 10px;
  position: relative;
  font-family: "Inter", sans-serif;
}
.popup__profile--info--about p.quote::after {
  display: block;
  content: "";
  position: absolute;
  left: -10px;
  top: calc(50% - 10px);
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid #fff;
}
.popup__profile--info--about p > span {
  color: var(--color-primary);
}
.popup__profile--info--about label {
  font: var(--main-font);
  font-size: 14px;
  color: var(--color-primary);
  margin: -5px 0;
}
.popup__profile--info--about textarea,
.popup__profile--info--about input {
  padding: 10px;
  background: #fff;
  border: none;
  border-radius: 10px;
  font-family: "Inter", sans-serif;
  resize: none;
}
.advent-table.profile {
  margin-top: 30px;
}
.advent-table.profile th {
  background: #ffedcf; /* ЗАДАТЬ ПЕРЕМЕННУЮ */
}
@media (max-width: 800px) {
  .popup__profile {
    height: 100vh;
    border-radius: 0;
  }
  .popup__profile--inner {
    height: 100vh;
    border: none;
    border-radius: 0;
  }
  .popup__profile--info {
    flex-direction: column;
    gap: 20px;
  }
}
@media (max-width: 390px) {
  .popup__profile {
    padding: 0;
  }
  .popup__profile--inner {
    width: 100%;
    height: 100%;
    border-radius: 0;
    border: none;
    padding: 30px;
  }
}
</style>