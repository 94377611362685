<template>
  <div class="page-memory">
    <div v-if="!isMemoryGameActivated">
      <h3>MEMORY</h3>
      <div>
        <p>
          🆕 А вот и новиночка для наших Адвентов: первая мини-игра этого
          декабря 🕹️
        </p>

        <p>
          🎮 Все вы играли в аналоги MEMORY - собираем парочки и радуемся
          успеху. Но для чего эта веселая забава? Рассказываем: ⬇️
        </p>

        <p>
          1️⃣ сейчас мы открываем тренировочно-фановый режим, привыкайте к
          механике 👀
          <br />
          2️⃣ а через несколько дней запустим турнир с балльно-призовым
          поощрением 🏆
        </p>
        <p>Приятного времяпрепровождения, адвентоборцы! ❤️🎄</p>
        <div class="page-memory__centered">
          <img :src="memoryWelcomeImage" />
          <div class="page-memory__btn" @click="onActivateMemoryGame">
            Начать игру!
          </div>
        </div>
      </div>
    </div>
    <div v-if="isMemoryGameActivated" class="page-memory">
      <div class="page-memory__header">
        <div class="page-memory__header--status">
          Ход: <span>{{ turn }}</span>
        </div>
        <h3 v-if="!allPairsAreFound">MEMORY</h3>
        <div v-if="allPairsAreFound" class="page-memory__btn" @click="replay">
          Играть снова
        </div>
        <div class="page-memory__header--status">
          Время: <span>{{ timeStr }}</span>
        </div>
      </div>
      <div
        v-if="false && allPairsAreFound"
        style="
          padding: 10px 20px;
          margin: 10px;
          background-color: #ec9222;
          color: white;
          border-radius: 20px;
          cursor: pointer;
          width: 200px;
        "
        class="mb-6"
        @click="replay()"
      >
        Играть снова
      </div>

      <main class="board-game">
        <div
          v-for="(card, index) in deckOfCards"
          @click="onClick(card, index)"
          :class="['card', isFlippedClass(card)]"
          :key="card.id"
        >
          <div
            class="front"
            :style="{ backgroundImage: `url(${memoryCardImage})` }"
          ></div>
          <div class="back">{{ card.content }}</div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import _ from "lodash";
import DefaultStorage from "../../../../utils/storage";
import moment from "moment-timezone";

const isMemoryActivatedStorageKey = "isMemoryGameActivated";

export default {
  name: "AdventMemoryGame",
  data() {
    const sets = [
      // ["1", "2", "3", "4", "5", "6", "7", "8"],
      ["🎅🏼", "🐰", "🐍", "🎄", "🌟", "❄️", "☃️", "🌨️"],
      ["✨", "🍭", "🍬", "🎁", "🎉", "🔔", "🎊", "🍾"],
      ["🚀", "🥁", "⛸️", "🏒", "😀", "🥨", "💫", "🧣"],
    ];
    // const sets = [
    //   [1, 2, 3],
    //   [4, 5, 6],
    // ];
    return {
      timer: null,
      startTime: null,
      endTime: null,
      gameTimer: null,
      turn: 0,
      flippedCards: [],
      deckOfCards: [],
      allPairsAreFound: false,
      isMemoryGameActivated: false,
      cards: _.first(_.shuffle(sets)),
      sets,
    };
  },
  computed: {
    ...mapGetters({
      event: "event/event",
    }),
    memoryCardImage() {
      return _.get(this.event, "meta.memoryCardImage");
    },
    memoryWelcomeImage() {
      return _.get(this.event, "meta.memoryWelcomeImage");
    },
    timeStr() {
      if (this.startTime == null || this.endTime == null) {
        return "---";
      }
      const ms = this.endTime - this.startTime;
      const duration = moment.duration(ms);
      const minutes = duration.minutes();
      const seconds = duration.seconds();
      return `${minutes >= 10 ? minutes : "0" + minutes}:${
        seconds >= 10 ? seconds : "0" + seconds
      }`;
    },
  },
  methods: {
    onActivateMemoryGame() {
      DefaultStorage.setItem(isMemoryActivatedStorageKey, "true");
      this.isMemoryGameActivated = true;
    },
    initCards() {
      // reset timer
      this.startTime = null;
      this.endTime = null;
      if (this.gameTimer) {
        clearInterval(this.gameTimer);
        this.gameTimer = null;
      }

      const set = _.first(_.shuffle(this.sets));

      this.cards = [...set];
      this.cards = this.cards.map((x, index) => {
        return { id: index, content: x, isPairFound: false };
      });

      // Dupplicate obj to get pairs and concat them to the existing array
      this.cards = this.cards.concat(
        this.cards.slice().map(({ content, id }) => {
          return { id: this.cards.length + id, content };
        })
      );

      this.deckOfCards = this.shuffle(this.cards);

      this.turn = 0;
      this.flippedCards = [];

      this.allPairsAreFound = false;
    },
    isFlippedClass({ id, isPairFound }) {
      return {
        flipped: this.flippedCards.find((x) => x.id === id),
        matchingPair: isPairFound,
      };
    },
    onClick(card, index) {
      if (this.startTime == null) {
        this.startTime = new Date();
        this.endTime = this.startTime;
        if (this.gameTimer) {
          clearInterval(this.gameTimer);
          this.gameTimer = null;
        }
        this.gameTimer = setInterval(() => {
          this.endTime = new Date();
        }, 500);
      }
      if (!card.isPairFound) {
        if (this.flippedCards.find((item) => item.index === index) != null) {
          return;
        }
        clearTimeout(this.timer);
        this.flippedCards =
          this.flippedCards.length === 2
            ? [{ ...card, index }]
            : [...this.flippedCards, { ...card, index }];
      }
    },
    shuffle(cards) {
      return cards.sort(() => Math.random() - 0.5);
    },
    replay() {
      clearTimeout(this.timer);
      this.initCards();
    },
  },
  watch: {
    flippedCards(cards) {
      if (cards.length === 2) {
        this.turn++;
        if (cards[0].content === cards[1].content) {
          this.deckOfCards[cards[0].index].isPairFound = true;
          this.deckOfCards[cards[1].index].isPairFound = true;
        } else if (cards[0].content !== cards[1].content) {
          const self = this;
          this.timer = setTimeout(() => {
            self.flippedCards = [];
          }, 2000);
        }
        this.allPairsAreFound = this.deckOfCards.every((x) => x.isPairFound);
      }
    },
    allPairsAreFound(val) {
      if (val) {
        clearInterval(this.gameTimer);
        this.gameTimer = null;
      }
    },
  },
  mounted() {
    this.initCards();
  },
  created() {
    this.isMemoryGameActivated =
      DefaultStorage.getItem(isMemoryActivatedStorageKey) === "true";
  },
};
</script>

<style lang="scss" scoped>
.page-memory {
  box-sizing: border-box;
  padding: 20px;
  overflow-y: auto;
  height: 100%;
  text-align: center;
  align-items: center;
  align-content: center;
}
.page-memory h3 {
  font-family: var(--secondary-font);
  font-size: 20px;
  color: var(--color-primary);
  text-align: center;
  margin-bottom: 10px;
}
.page-memory p {
  font-family: var(--main-font);
  font-size: 14px;
  line-height: 140%;
  color: var(--color-text);
  margin-bottom: 20px;
  text-align: left;
}
.page-memory__centered {
  margin: 30px auto 0;
  display: flex;
  flex-direction: column;
  gap: 30px;
  justify-content: center;
  align-items: center;
}
.page-memory__centered > img {
  width: 205px;
  height: 205px;
}
.page-memory__btn {
  padding: 10px;
  background-color: var(--color-primary);
  color: white;
  border-radius: 20px;
  cursor: pointer;
  width: 160px;
}
.page-memory__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}
.page-memory__header--status {
  font-family: var(--main-font);
  font-size: 14px;
  color: var(--color-text-memory);
}
.page-memory__header--status span {
  color: var(--color-primary);
}
main {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  max-width: 510px;
  margin: 0 auto;
  column-gap: 10px;
  row-gap: 10px;
}
.card {
  width: auto;
  font-size: 3rem;
  font-family: Verdana;
  position: relative;
  transition: opacity 0.7s;
}
.card::after {
  content: "";
  display: block;
  padding-bottom: 100%;
}
.back,
.front {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  backface-visibility: hidden;
  transform: translateZ(0);
  transition: transform 0.8s;
  transform-style: preserve-3d;
  border-radius: 5px;
  background: white;
}
.card .front {
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
}
.card .back {
  transform: rotateY(-180deg);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border: 1px solid gray;
}
.card.matchingPair .front,
.card.flipped .front {
  transform: rotateY(180deg);
}
.card.matchingPair .back,
.card.flipped .back {
  transform: rotateY(0deg);
}
.card.matchingPair {
  opacity: 0.3;
}
button {
  margin-top: 20px;
  font-size: 0.8rem;
  padding: 8px 10px;
  text-transform: uppercase;
  background: #f2add2;
  border: none;
  color: white;
  border-radius: 4px;
}
@media (max-width: 640px) {
  main {
    grid-template-columns: repeat(4, 1fr);
  }
  .page-memory {
    padding: 0px;
  }
}
</style>