var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticStyle:{"background-color":"white !important"}},[_c('v-card-title',[_vm._v("Техническая поддержка"),_c('v-spacer'),_c('v-btn',{attrs:{"text":"","small":"","color":"secondary"},on:{"click":function($event){return _vm.$emit('close')}}},[_c('v-icon',[_vm._v(" mdi-close ")])],1)],1),_c('v-card-text',{staticClass:"pb-0 mb-0"},_vm._l((_vm.roomMessages),function(item){return _c('div',{key:("chat-message-" + (item.id)),style:({
        cursor:
          _vm.userId === item.author.id || item.room === 'tech-support'
            ? 'default'
            : 'pointer',
      }),attrs:{"title":_vm.userId === item.author.id || item.room === 'tech-support'
          ? ''
          : 'Двойное нажатия для игнора'},on:{"dblclick":function($event){return _vm.onMessageDblClick(item)}}},[_c('strong',[_vm._v(_vm._s(_vm.userId === item.author.id ? "Я" : item.author.name))]),_vm._v(": "+_vm._s(item.data.text)+" ")])}),0),_c('v-card-actions',{staticClass:"pl-6 pr-6"},[_c('v-text-field',{attrs:{"placeholder":"Введите ваше сообщение (до 300 символов)","dense":"","value":_vm.message},on:{"keyup":_vm.onKeyPress,"input":_vm.onMessageChange}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }