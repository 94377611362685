<template>
  <v-card :elevation="4">
    <v-img :src="url" @dblclick="onDoubleClick" />
    <v-card-title class="gallery-title" v-if="author">{{
      author
    }}</v-card-title>
    <v-card-actions v-if="likeable">
      <v-icon color="red" @click="onDoubleClick">{{
        isLikedByUser ? "mdi-heart" : "mdi-heart-outline"
      }}</v-icon>
    </v-card-actions>
  </v-card>
</template>

<script>
import likeableMixin from "@/mixins/likeable";

export default {
  name: "KIAGalleryItem",
  props: ["url", "id", "author", "likeable"],
  mixins: [likeableMixin],
  computed: {
    likeableObjectId() {
      return this.id;
    },
  },
  methods: {
    onDoubleClick() {
      if (this.likeable) {
        this.isLikedByUser = !this.isLikedByUser;
      }
    },
  },
};
</script>