<template>
  <v-select
    label="Выберите задание"
    item-color="orange"
    color="black"
    :items="items"
    :value="value"
    @input="$emit('change', $event)"
    item-value="id"
    item-text="title"
  />
</template>

<script>
import _ from "lodash";
import ThemeMixin from "../../../../mixins/eventTheme";

export default {
  name: "QuestTasksPicker",
  props: ["groups", "selected-game-task"],
  mixins: [ThemeMixin],
  data() {
    return {};
  },
  computed: {
    value() {
      return this.selectedGameTask && this.selectedGameTask._id;
    },
    items() {
      return this.groups.reduce((acc, item) => {
        const { gameTask, questTask, enabled, childs } = item;
        const { title } = questTask;
        const id = gameTask._id;
        if (!_.isEmpty(childs)) {
          acc.push({ divider: true, title: "------" });
        }
        acc.push({
          title,
          disabled: !enabled,
          id,
        });
        if (!_.isEmpty(childs)) {
          for (const childItem of childs) {
            const { gameTask, questTask, enabled } = childItem;
            const { title } = questTask;
            const id = gameTask._id;
            acc.push({
              title: `${title}`,
              disabled: !enabled,
              id,
            });
          }
        }
        return acc;
      }, []);
    },
  },
};
</script>

<style>
.theme--light.v-select-list {
  background-color: white !important;
}
</style>