<template>
  <div class="page-memory">
    <div v-if="!isMemoryGameActivated">
      <h3>🧩 MEMORY ТУРНИР СТАРТОВАЛ 🧩</h3>
      <div>
        <p>
          Многие из вас уже попробовали функционал в формате песочницы. Спасибо
          всем тестерам ❤️
        </p>
        <p>
          А с сегодняшнего дня до 20:00 заключительного дня Адвента вы можете
          урвать до 10 баллов в копилку 🍊
        </p>
        <p>Что для этого требуется? 🧐</p>

        <p>
          🧩 играйте в MEMORY сколько угодно (в нерабочее время, конечно же)<br />
          🧩 система фиксирует вашу лучшую попытку<br />
          🧩 в турнирку по игре улетает ваше количество ходов и время на игру<br />
          🧩 лучший результат фиксируем по наименьшему количеству сделанных
          ходов<br />
          🧩 дополнительный параметр — время<br />
          🧩 в завершении турнира мы начислим лучшим мемористам долгожданные
          баллы
        </p>

        <p>
          🟥 По законам всех интриганских кодексов таблица с результатами
          Турнира откроется только ЗАВТРА. Но а начать занимать положения в ее
          верхушке вы можете уже сегодня ✅
        </p>

        <p>
          ⚠️ Убедительная просьба от техподдержки — получите удовольствие! Не
          ищите подвоха, будьте честными с собой и вашими коллегами 🤝
        </p>

        <p>Мы исключительно за удовольствие! ❤️</p>

        <div class="page-memory__centered">
          <img :src="memoryWelcomeImage" />
          <div class="page-memory__btn" @click="onActivateMemoryGame">
            Начать игру!
          </div>
        </div>
      </div>
    </div>
    <div v-if="isMemoryGameActivated" class="page-memory">
      <div class="page-memory__header">
        <div class="page-memory__header--status">
          Ход: <span>{{ turn }}</span>
        </div>
        <h3 v-if="!allPairsAreFound">MEMORY ТУРНИР</h3>
        <div v-if="allPairsAreFound" class="page-memory__btn" @click="replay">
          Играть снова
        </div>
        <div
          v-if="allPairsAreFound"
          class="page-memory__btn"
          @click="onDeactivateMemoryGame"
        >
          Правила
        </div>
        <div class="page-memory__header--status">
          Время: <span>{{ timeStr }}</span>
        </div>
      </div>

      <main class="board-game">
        <div
          v-for="(card, index) in deckOfCards"
          @click="onClick(card, index)"
          :class="['card', isFlippedClass(card)]"
          :key="card.id"
        >
          <div
            class="front"
            :style="{ backgroundImage: `url(${memoryCardImage})` }"
          ></div>
          <div class="back">{{ card.content }}</div>
        </div>
      </main>
    </div>

    <div class="d-flex justify-center align-center pa-2">
      <v-btn color="orange darken-2" dark @click="onStanding">
        Турнирная таблица
      </v-btn>
    </div>
    <v-dialog
      v-model="isTournamentDialog"
      scrollable
      :fullscreen="$vuetify.breakpoint.mobile"
      :max-width="$vuetify.breakpoint.mobile ? null : 500"
    >
      <v-card style="background-color: white !important">
        <v-card-title>Турнирная таблица</v-card-title>
        <v-card-text>
          <v-simple-table class="mе-4 mb-4 ml-0 mr-0">
            <template v-slfot:default>
              <thead>
                <tr>
                  <th class="text-left">Место</th>
                  <th class="text-left">Игрок</th>
                  <th class="text-left">Ходов</th>
                  <th class="text-center">Время</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in standings"
                  :key="item.id"
                  class="pa-2"
                >
                  <td class="text-left">
                    {{ index + 1 }}
                  </td>
                  <td class="text-left" style="text-transform: capitalize">
                    {{ item.name }}
                  </td>
                  <td class="text-left">
                    {{ item.turn }}
                  </td>
                  <td class="text-left">
                    {{ parseDuration(item.duration) }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            color="orange darken-2"
            @click="isTournamentDialog = false"
            >Закрыть</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import _ from "lodash";
import DefaultStorage from "../../../../utils/storage";
import moment from "moment-timezone";

const isMemoryActivatedStorageKey = "isMemoryTournamentGameActivated";

const formatN = (input) => {
  return `${input < 10 ? "0" : ""}${input}`;
};

const parseDuration = (dur) => {
  const duration = moment.duration(dur);
  let mi = `${duration.milliseconds()}`;
  if (mi.length === 1) {
    mi = `0${mi}`;
  }
  if (mi.length === 2) {
    mi = `0${mi}`;
  }
  return `${formatN(Math.floor(duration.asMinutes()))}:${formatN(
    duration.seconds()
  )}.${mi}`;
};

export default {
  name: "AdventMemoryTournamentGame",
  props: ["itemId", "type", "params"],
  data() {
    return {
      timer: null,
      startTime: null,
      endTime: null,
      finishDate: null,
      gameTimer: null,
      turn: 0,
      flippedCards: [],
      deckOfCards: [],
      allPairsAreFound: false,
      isMemoryGameActivated: false,
      cards: [],
      clickInProgress: false,
      flipTimer: null,
      isTournamentDialog: false,
      isTournamentLoading: false,
      standings: [],
      parseDuration,
    };
  },
  computed: {
    ...mapGetters({
      event: "event/event",
    }),
    memoryCardImage() {
      return _.get(this.event, "meta.memoryCardImage");
    },
    memoryWelcomeImage() {
      return _.get(this.event, "meta.memoryWelcomeImage");
    },
    timeStr() {
      if (this.startTime == null || (this.finishDate || this.endTime) == null) {
        return "---";
      }
      const ms = (this.finishDate || this.endTime) - this.startTime;
      const duration = moment.duration(ms);
      const minutes = duration.minutes();
      const seconds = duration.seconds();
      return `${minutes >= 10 ? minutes : "0" + minutes}:${
        seconds >= 10 ? seconds : "0" + seconds
      }`;
    },
  },
  methods: {
    ...mapActions({
      runCommand: "event/runCommand",
    }),
    onStanding() {
      this.fetchStanding();
    },
    async fetchStanding() {
      try {
        this.isTournamentLoading = true;
        this.isTournamentDialog = true;

        const itemId = this.itemId;
        const command = "getStandings";
        const params = {};
        const payload = {
          itemId,
          command,
          params,
        };
        const response = await this.runCommand(payload);
        this.standings = response;
      } catch (err) {
        console.error(err);
      } finally {
        this.isTournamentLoading = false;
      }
    },
    async fetchActiveGame() {
      try {
        const itemId = this.itemId;
        const command = "getActiveGame";
        const params = {};
        const payload = {
          itemId,
          command,
          params,
        };
        const response = await this.runCommand(payload);
        const { activeGame } = response;

        // reset timer
        this.startTime = null;
        this.endTime = null;
        if (this.gameTimer) {
          clearInterval(this.gameTimer);
          this.gameTimer = null;
        }

        this.deckOfCards = activeGame.cards;

        this.flippedCards = activeGame.flippedCards;

        this.turn = activeGame.turn;
        this.allPairsAreFound = activeGame.status === "success";
        this.startTime = activeGame.startDate && new Date(activeGame.startDate);
        this.finishDate =
          activeGame.finishDate && new Date(activeGame.finishDate);
      } catch (err) {
        console.error(err);
      }
    },
    onActivateMemoryGame() {
      DefaultStorage.setItem(isMemoryActivatedStorageKey, "true");
      this.isMemoryGameActivated = true;
    },
    onDeactivateMemoryGame() {
      DefaultStorage.setItem(isMemoryActivatedStorageKey, "false");
      this.isMemoryGameActivated = false;
    },
    isFlippedClass({ id, isPairFound }) {
      return {
        flipped: this.flippedCards.find((x) => x.id === id),
        matchingPair: isPairFound,
      };
    },
    async onClick(card, index) {
      if (this.clickInProgress) return;
      if (card.isPairFound) return;

      clearTimeout(this.flipTimer);

      try {
        this.clickInProgress = true;

        const itemId = this.itemId;
        const command = "makeMove";
        const params = {
          card,
          index,
        };
        const payload = {
          itemId,
          command,
          params,
        };
        const response = await this.runCommand(payload);
        const { activeGame } = response;
        this.flippedCards = activeGame.flippedCards;
        this.deckOfCards = activeGame.cards;
        this.turn = activeGame.turn;
        this.allPairsAreFound = activeGame.status === "success";
        this.startTime = activeGame.startDate && new Date(activeGame.startDate);
        this.finishDate =
          activeGame.finishDate && new Date(activeGame.finishDate);
      } catch (err) {
        console.error(err);
      } finally {
        this.clickInProgress = false;
      }
    },
    replay() {
      clearTimeout(this.timer);
      this.fetchActiveGame();
    },
  },
  watch: {
    flippedCards(cards) {
      if (cards.length === 2) {
        if (cards[0].content !== cards[1].content) {
          clearTimeout(this.flipTimer);
          this.flipTimer = setTimeout(() => {
            this.flippedCards = [];
          }, 2000);
        }
      }
    },
    startTime() {
      clearInterval(this.gameTimer);
      this.gameTimer = setInterval(() => {
        this.endTime = new Date();
      }, 500);
    },
    allPairsAreFound() {
      clearInterval(this.gameTimer);
    },
  },
  created() {
    this.fetchActiveGame();
    this.isMemoryGameActivated =
      DefaultStorage.getItem(isMemoryActivatedStorageKey) === "true";
  },
};
</script>

<style lang="scss" scoped>
.page-memory {
  box-sizing: border-box;
  padding: 20px;
  overflow-y: auto;
  height: 100%;
  text-align: center;
  align-items: center;
  align-content: center;
}
.page-memory h3 {
  font-family: var(--secondary-font);
  font-size: 20px;
  color: var(--color-primary);
  text-align: center;
  margin-bottom: 10px;
}
.page-memory p {
  font-family: var(--main-font);
  font-size: 14px;
  line-height: 140%;
  color: var(--color-text);
  margin-bottom: 20px;
  text-align: left;
}
.page-memory__centered {
  margin: 30px auto 0;
  display: flex;
  flex-direction: column;
  gap: 30px;
  justify-content: center;
  align-items: center;
}
.page-memory__centered > img {
  width: 205px;
  height: 205px;
}
.page-memory__btn {
  padding: 10px;
  background-color: var(--color-primary);
  color: white;
  border-radius: 20px;
  cursor: pointer;
  width: 160px;
}
.page-memory__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}
.page-memory__header--status {
  font-family: var(--main-font);
  font-size: 20px;
  color: var(--color-text-memory);
}
.page-memory__header--status span {
  color: var(--color-primary);
  font-weight: 600;
}
main {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  max-width: 660px;
  margin: 0 auto;
  column-gap: 10px;
  row-gap: 10px;
}
.card {
  width: auto;
  font-size: 3.5rem;
  font-family: Verdana;
  position: relative;
  transition: opacity 0.7s;
}
.card::after {
  content: "";
  display: block;
  padding-bottom: 100%;
}
.back,
.front {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  backface-visibility: hidden;
  transform: translateZ(0);
  transition: transform 0.8s;
  transform-style: preserve-3d;
  border-radius: 5px;
  background: white;
}
.card .front {
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
}
.card .back {
  transform: rotateY(-180deg);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border: 1px solid gray;
}
.card.matchingPair .front,
.card.flipped .front {
  transform: rotateY(180deg);
}
.card.matchingPair .back,
.card.flipped .back {
  transform: rotateY(0deg);
}
.card.matchingPair {
  opacity: 0.3;
}
button {
  margin-top: 20px;
  font-size: 0.8rem;
  padding: 8px 10px;
  text-transform: uppercase;
  background: #f2add2;
  border: none;
  color: white;
  border-radius: 4px;
}
@media (max-width: 640px) {
  main {
    grid-template-columns: repeat(4, 1fr);
  }
  .page-memory {
    padding: 0px;
  }
}
</style>