<template>
  <div>
    <game
      :user-id="userId"
      :challenge="challenge"
      @command="onCommand"
      :remaining="remaining"
    />

    <div
      v-if="isTournament"
      class="d-flex justify-center align-center pt-4 pa-2"
    >
      <v-btn color="primary" @click="isRulesDialog = true">Правила</v-btn>
    </div>

    <v-dialog
      v-model="isRulesDialog"
      scrollable
      :fullscreen="$vuetify.breakpoint.mobile"
      :max-width="$vuetify.breakpoint.mobile ? null : 500"
      style="background-color: white !important"
    >
      <v-card style="background-color: white !important">
        <v-card-title>⚠️ ПРАВИЛА ПОЕДИНКА ⚠️</v-card-title>
        <v-card-text>
          <p>
            ❄️ Система рандомно дает право первого хода одному из участников
          </p>

          <p>
            ❄️ И далее поединок проходит по классическим правилам “морского боя”
            до выявления победителя
          </p>

          <p>❄️ У каждого участника есть таймер на ход — 30 секунд</p>

          <p>❄️ В случае истечения таймера — ход переходит сопернику</p>

          <p>❄️ В случае успешного попадания — участник делает еще один ход</p>

          <p>
            ❄️ Игра завершается как только все игровые объекты одного из
            участников поражены (за исключением особых случаев)
          </p>

          <p>🔴 Система штрафов:</p>

          <p>
            ❌ Если участник пропустил 10 ходов подряд — ему засчитывается
            автоматическое поражение со счётом Х:20 (где Х — количество разбитых
            новогодних шаров соперника к моменту завершения поединка)
          </p>

          <p>🔝 Выявление преимущества в таблице при равных показателях:</p>

          <p>
            1️⃣ Преимущество получает участник, чья разница в общем счете за 10
            поединков больше
          </p>

          <p>
            2️⃣ При равенстве и этих показателей — выше в таблице тот, кто набрал
            больше очков (пораженных новогодних шаров соперника)
          </p>

          <p>
            3️⃣ В случае и этого равенства — мы считаем количество пораженных
            "однопалубных", далее "двухпалубных" и так далее
          </p>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn text @click="isRulesDialog = false">Закрыть</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import _ from "lodash";
import Game from "./Game/Index.vue";

const getInitials = (name) => {
  if (typeof name !== "string") {
    return null;
  }
  if (name === "") return "";
  return name
    .split(" ")
    .filter((item) => item !== "")
    .map((item) => `${item[0]}`.toUpperCase())
    .reduce((acc, item) => {
      if (acc.length >= 2) return acc;
      return `${acc}${item}`;
    }, "");
};

export default {
  props: [
    "challenge",
    "is-my",
    "user-id",
    "to-user-id",
    "from-user-id",
    "remaining",
    "is-tournament",
  ],
  components: {
    Game,
  },
  name: "AirBattle",
  data() {
    return {
      getInitials,
      isRulesDialog: false,
    };
  },
  computed: {
    isFinished() {
      return ["cancelled", "expired", "resolved"].includes(
        this.challenge.status
      );
    },
    isCancelled() {
      return this.challenge.status === "cancelled";
    },
    isWin() {
      return (
        this.isFinished &&
        !this.isCancelled &&
        this.challenge.winner === this.userId
      );
    },
    isLoose() {
      return (
        this.isFinished &&
        !this.isCancelled &&
        this.challenge.price > 0 &&
        this.challenge.winner !== this.userId
      );
    },
    isDraw() {
      return this.isFinished && !this.isCancelled && this.challenge.price === 0;
    },
    rolls() {
      const items = this.challenge.state.rolls;
      return _.times(3, (index) => {
        return {
          from: this.getRollNumber(items, index, "from"),
          to: this.getRollNumber(items, index, "to"),
        };
      });
    },
    property() {
      return this.isMy ? "from" : "to";
    },
    opponentProperty() {
      return this.isMy ? "to" : "from";
    },
    rollEnabled() {
      if (this.isFinished) return false;
      const items = this.challenge.state.rolls;
      if (items.length === 0) return true;
      const last = _.last(items);
      return (
        last[this.property] == null ||
        (last[this.property] != null &&
          last[this.opponentProperty] != null &&
          items.length < 3)
      );
    },
  },
  methods: {
    onCommand(args) {
      this.$emit("command", args);
    },
    onMakeRoll() {
      this.$emit("command", { command: "roll", params: {} });
    },
    getRollNumber(rolls, index, property) {
      if (rolls.length < index) {
        return "-";
      } else if (rolls[index] && rolls[index][property] != null) {
        return rolls[index][property];
      }
      return "-";
    },
  },
};
</script>

<style>
.img-responsive {
  max-width: 100%;
  height: auto;
}
.ab__main {
  width: 100%;
  height: 100%;
}
.ab__menu-wrapper {
  background: #12275c;
  box-sizing: border-box;
  width: 256px;
  height: 100%;
  padding: 20px 13px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
.ab__menu-logo {
  background: #fff;
  box-sizing: border-box;
  padding: 15px;
  border-radius: 10px;
  margin-bottom: 60px;
  height: 230px;
}
.ab__menu {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.ab__menu-item {
  box-sizing: border-box;
  height: 40px;
  padding: 10px;
  background: transparent;
  border: 1px solid #fff;
  border-radius: 10px;
  /* text-align: center;
  vertical-align: middle; */
}
.ab__menu-item.active {
  background: #16a3d9;
}
.ab__menu-item:hover {
  cursor: pointer;
}
.ab__menu-item > span {
  line-height: 19.36px;
  font-weight: 400;
  font-size: 16px;
  color: #fff;
}
.ab__content {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 60px 120px;
  overflow: auto;
  background: #efeeee;
}
.ab__content-header {
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: baseline;
  color: #12275c;
  gap: 20px;
  font-weight: 700;
  font-size: 60px;
  line-height: 72.61px;
  letter-spacing: -2px;
  text-transform: uppercase;
}
.ab__content-header-profile {
  margin-bottom: 30px;
}
.ab__content-header img[src="img/ab-ap-v4.png"] {
  height: 51.46px;
  width: auto;
}
.ab__content-header img {
  height: 72.61px;
  width: auto;
}
.ab__content-controls {
  display: flex;
  flex-direction: row;
  gap: 30px;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 20px;
  line-height: 24.2px;
  color: rgba(18, 39, 92, 0.5);
}
.ab__content-controls-item {
  cursor: pointer;
}
.ab__content-controls-item.active {
  color: rgba(18, 39, 92, 1);
}
.ab__content-table {
  border-collapse: collapse;
  margin: 20px 0;
  width: 100%;
}
.ab__content-table,
.ab__content-table td,
.ab__content-table td {
  border: 1px solid #12275c;
}
.ab__content-table td {
  background: #fff;
}
.ab__content-table thead {
  text-transform: uppercase;
  color: #fff;
}
.ab__content-table th {
  background: #16a3d9;
  font-weight: 400;
  font-size: 20px;
  line-height: 24.2px;
  border: 1px solid #12275c;
}
.ab__content-table td {
  font-weight: 400;
  font-size: 16px;
  line-height: 19.36px;
}
.ab__content-table td,
.ab__content-table th {
  outline: 0;
  padding: 10px;
  vertical-align: middle;
}
.ab__textblock-item {
  background: #fff;
  max-width: 1000px;
  box-sizing: border-box;
  padding: 30px;
  border-radius: 10px;
}
.ab__textblock-item-header {
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #16a3d9;
}
.ab__textblock-item-list {
  padding-left: 15px;
  color: #000;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}
.ab__profile {
  display: flex;
  flex-direction: row;
  gap: 60px;
  margin-bottom: 50px;
  max-width: 1000px;
}
.ab__profile-person {
  width: 200px;
  height: 200px;
  position: relative;
  z-index: 1;
  flex-shrink: 0;
}
.ab__profile-personphoto {
  border-radius: 10px;
}
.ab__profile-personrating {
  position: absolute;
  z-index: 2;
  top: -22px;
  right: -48px;
  width: 109px;
  height: 55px;
  background-image: url(./assets/ab-profilerating.png);
  background-repeat: no-repeat;
  background-size: contain;
  color: #fff;
  font-weight: 500;
  font-size: 16px;
  line-height: 19.36px;
  text-align: center;
}
.ab__profile-personrating > span {
  line-height: 65px;
  /* position: absolute;
    top: 23px;
    left: 39px; */
}
.ab__profile-info h2 {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 20px;
  line-height: 24.2px;
  margin-bottom: 15px;
}
.ab__profile-info p {
  font-weight: 500;
  font-size: 16px;
  line-height: 19.36px;
  margin-bottom: 15px;
}
.ab__profile-controls {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 30px;
}
.ab__button {
  padding: 10px 30px;
  border: 1px solid #16a3d9;
  border-radius: 10px;
  color: #fff;
  font-family: "Inter", sans-serif;
  background: #16a3d9;
  text-transform: uppercase;
  cursor: pointer;
  font-weight: 600;
  font-size: 20px;
  line-height: 24.2px;
  flex-shrink: 0;
}
.ab__btn-gray {
  background: #efeeee;
  color: #16a3d9;
}
.ab__content-battle {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  gap: 25px;
  margin: 30px 0;
}
@media (width <= 800px) {
  .ab__content-battle {
    flex-direction: column;
  }
}
.ab__content-bf-player {
  width: 100%;
  box-sizing: border-box;
  padding: 0 15px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.ab__content-bf-player-name {
  font-weight: 500;
  font-size: 16px;
  line-height: 19.36px;
}
.ab__content-bf-player-score {
  font-weight: 500;
  font-size: 16px;
  line-height: 19.36px;
  color: #a10202;
}
.ab__content-bf {
  width: 425px;
  height: 423px;
}
.ab__content-bf.bf-await {
  filter: blur(5px);
}
.ab__content-status-wrap {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 5;
}
.ab__content-bf-status {
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  position: absolute;
  width: 400px;
  height: 400px;
  padding: 0 40px;
  bottom: 0;
  right: 0;
}
.ab__content-bf-status h4 {
  font-weight: 700;
  font-size: 30px;
  line-height: 36.31px;
  letter-spacing: -2px;
  text-align: center;
}
.ab__content-bf-status p {
  font-weight: 500;
  font-size: 20px;
  line-height: 24.2px;
  text-align: center;
}
.ab__content-bf-turn {
  width: 155px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
}
.ab__content-bf-turn > img {
  height: 85px;
  width: 85px;
}
.ab__content-bf-turn > h4 {
  font-weight: 700;
  font-size: 20px;
  line-height: 24.2px;
  text-align: center;
  text-transform: uppercase;
}
@media (width <= 1000px) {
  .ab__content {
    padding: 30px 40px;
  }
}
@media (width <= 500px) {
  .ab__content {
    padding: 30px 20px;
  }
  .ab__content-header {
    font-size: 40px;
    line-height: 42px;
    gap: 5px;
  }
  .ab__content-header img {
    height: 42px;
    width: auto;
  }
  .ab__content-table {
  }
}
</style>