<template>
  <div>
    <div class="page-snowbattle__bf">
      <div class="page-snowbattle__bf--letters">
        <span>А</span>
        <span>Б</span>
        <span>В</span>
        <span>Г</span>
        <span>Д</span>
        <span>Е</span>
        <span>Ж</span>
        <span>З</span>
        <span>И</span>
        <span>К</span>
      </div>
      <div class="page-snowbattle__bf--numbers">
        <span>1</span>
        <span>2</span>
        <span>3</span>
        <span>4</span>
        <span>5</span>
        <span>6</span>
        <span>7</span>
        <span>8</span>
        <span>9</span>
        <span>10</span>
      </div>
      <div class="page-snowbattle__bf--grid">
        <div class="page-snowbattle__bf--grid--item"></div>
        <div class="page-snowbattle__bf--grid--item"></div>
        <div class="page-snowbattle__bf--grid--item"></div>
        <div class="page-snowbattle__bf--grid--item"></div>
        <div class="page-snowbattle__bf--grid--item"></div>
        <div class="page-snowbattle__bf--grid--item"></div>
        <div class="page-snowbattle__bf--grid--item"></div>
        <div class="page-snowbattle__bf--grid--item"></div>
        <div class="page-snowbattle__bf--grid--item"></div>
        <div class="page-snowbattle__bf--grid--item"></div>
        <div class="page-snowbattle__bf--grid--item"></div>
        <div class="page-snowbattle__bf--grid--item"></div>
        <div class="page-snowbattle__bf--grid--item"></div>
        <div class="page-snowbattle__bf--grid--item"></div>
        <div class="page-snowbattle__bf--grid--item"></div>
        <div class="page-snowbattle__bf--grid--item"></div>
        <div class="page-snowbattle__bf--grid--item"></div>
        <div class="page-snowbattle__bf--grid--item"></div>
        <div class="page-snowbattle__bf--grid--item"></div>
        <div class="page-snowbattle__bf--grid--item"></div>
        <div class="page-snowbattle__bf--grid--item"></div>
        <div class="page-snowbattle__bf--grid--item"></div>
        <div class="page-snowbattle__bf--grid--item"></div>
        <div class="page-snowbattle__bf--grid--item"></div>
        <div class="page-snowbattle__bf--grid--item"></div>
        <div class="page-snowbattle__bf--grid--item"></div>
        <div class="page-snowbattle__bf--grid--item"></div>
        <div class="page-snowbattle__bf--grid--item"></div>
        <div class="page-snowbattle__bf--grid--item"></div>
        <div class="page-snowbattle__bf--grid--item"></div>
        <div class="page-snowbattle__bf--grid--item"></div>
        <div class="page-snowbattle__bf--grid--item"></div>
        <div class="page-snowbattle__bf--grid--item"></div>
        <div class="page-snowbattle__bf--grid--item"></div>
        <div class="page-snowbattle__bf--grid--item"></div>
        <div class="page-snowbattle__bf--grid--item"></div>
        <div class="page-snowbattle__bf--grid--item"></div>
        <div class="page-snowbattle__bf--grid--item"></div>
        <div class="page-snowbattle__bf--grid--item"></div>
        <div class="page-snowbattle__bf--grid--item"></div>
        <div class="page-snowbattle__bf--grid--item"></div>
        <div class="page-snowbattle__bf--grid--item"></div>
        <div class="page-snowbattle__bf--grid--item"></div>
        <div class="page-snowbattle__bf--grid--item"></div>
        <div class="page-snowbattle__bf--grid--item"></div>
        <div class="page-snowbattle__bf--grid--item"></div>
        <div class="page-snowbattle__bf--grid--item"></div>
        <div class="page-snowbattle__bf--grid--item"></div>
        <div class="page-snowbattle__bf--grid--item"></div>
        <div class="page-snowbattle__bf--grid--item"></div>
        <div class="page-snowbattle__bf--grid--item"></div>
        <div class="page-snowbattle__bf--grid--item"></div>
        <div class="page-snowbattle__bf--grid--item"></div>
        <div class="page-snowbattle__bf--grid--item"></div>
        <div class="page-snowbattle__bf--grid--item"></div>
        <div class="page-snowbattle__bf--grid--item"></div>
        <div class="page-snowbattle__bf--grid--item"></div>
        <div class="page-snowbattle__bf--grid--item"></div>
        <div class="page-snowbattle__bf--grid--item"></div>
        <div class="page-snowbattle__bf--grid--item"></div>
        <div class="page-snowbattle__bf--grid--item"></div>
        <div class="page-snowbattle__bf--grid--item"></div>
        <div class="page-snowbattle__bf--grid--item"></div>
        <div class="page-snowbattle__bf--grid--item"></div>
        <div class="page-snowbattle__bf--grid--item"></div>
        <div class="page-snowbattle__bf--grid--item"></div>
        <div class="page-snowbattle__bf--grid--item"></div>
        <div class="page-snowbattle__bf--grid--item"></div>
        <div class="page-snowbattle__bf--grid--item"></div>
        <div class="page-snowbattle__bf--grid--item"></div>
        <div class="page-snowbattle__bf--grid--item"></div>
        <div class="page-snowbattle__bf--grid--item"></div>
        <div class="page-snowbattle__bf--grid--item"></div>
        <div class="page-snowbattle__bf--grid--item"></div>
        <div class="page-snowbattle__bf--grid--item"></div>
        <div class="page-snowbattle__bf--grid--item"></div>
        <div class="page-snowbattle__bf--grid--item"></div>
        <div class="page-snowbattle__bf--grid--item"></div>
        <div class="page-snowbattle__bf--grid--item"></div>
        <div class="page-snowbattle__bf--grid--item"></div>
        <div class="page-snowbattle__bf--grid--item"></div>
        <div class="page-snowbattle__bf--grid--item"></div>
        <div class="page-snowbattle__bf--grid--item"></div>
        <div class="page-snowbattle__bf--grid--item"></div>
        <div class="page-snowbattle__bf--grid--item"></div>
        <div class="page-snowbattle__bf--grid--item"></div>
        <div class="page-snowbattle__bf--grid--item"></div>
        <div class="page-snowbattle__bf--grid--item"></div>
        <div class="page-snowbattle__bf--grid--item"></div>
        <div class="page-snowbattle__bf--grid--item"></div>
        <div class="page-snowbattle__bf--grid--item"></div>
        <div class="page-snowbattle__bf--grid--item"></div>
        <div class="page-snowbattle__bf--grid--item"></div>
        <div class="page-snowbattle__bf--grid--item"></div>
        <div class="page-snowbattle__bf--grid--item"></div>
        <div class="page-snowbattle__bf--grid--item"></div>
        <div class="page-snowbattle__bf--grid--item"></div>
        <div class="page-snowbattle__bf--grid--item"></div>
        <div class="page-snowbattle__bf--grid--item"></div>
        <div class="page-snowbattle__bf--grid--item"></div>
      </div>
      <div class="page-snowbattle__bf--ships">
        <div
          v-for="gridItem in items"
          :key="`snow-fight-2025-${gridItem.index}`"
          :class="{
            'page-snowbattle__bf--ships--item':
              gridItem.ship || gridItem.miss || gridItem.missAuto,
            destroyed: gridItem.ship && gridItem.played,
            missed: gridItem.miss || gridItem.missAuto,
          }"
          :style="{
            'grid-column': gridItem.x + 1,
            'grid-row': gridItem.y + 1,
          }"
        >
          <img
            v-if="gridItem.ship && !gridItem.done && !gridItem.hit"
            src="https://storage.yandexcloud.net/yo-events/advent-2025/img/snow-battle-shar-3.png"
          />
          <img
            v-if="gridItem.ship && gridItem.played"
            src="https://storage.yandexcloud.net/yo-events/advent-2025/img/snow-battle-snezhok-destroyed.png"
          />
          <img
            v-else-if="gridItem.hit && !gridItem.done"
            src="https://storage.yandexcloud.net/yo-events/advent-2025/img/snow-battle-snezhok-hit.png"
          />
          <img
            v-if="gridItem.miss || gridItem.missAuto"
            src="https://storage.yandexcloud.net/yo-events/advent-2025/img/snow-battle-snezhok.png"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";

export default {
  name: "Battlefield",
  props: ["store", "lost"],
  computed: {
    items() {
      const result = _.reduce(
        this.store.gridItems,
        (acc, item) => {
          return [...acc, ...item];
        },
        []
      );
      return result;
    },
  },
  created: function () {
    this.createMyShips();
  },
  methods: {
    createMyShips() {},
  },
};
</script>

<style scoped>
.ship-alive {
  outline: 2px solid #16a3d9;
  border: 1px solid #16a3d9;
  z-index: 5;
}
.ship-dead {
  outline: 2px solid #ec0606;
  border: 1px solid #ec0606;
  z-index: 5;
}
</style>