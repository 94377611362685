<template>
  <div class="popup__profile--inner" style="width: 100%">
    <div class="close-btn" @click="$emit('close')">
      <img
        src="https://storage.yandexcloud.net/yo-events/advent-2025/img/OTR/icon-close-v2.png"
      />
    </div>
    <h3>Профиль участника</h3>
    <div class="popup__profile--info">
      <div class="popup__profile--info--img">
        <img :src="user.image || menuWelcomeImg" style="object-fit: cover" />
        <h4>{{ user.name }}</h4>
        <div v-if="user.achievements && user.achievements.length > 0">
          <template v-for="(item, index) in user.achievements">
            <v-tooltip
              bottom
              :key="`${user.userId}-achievements-${index}-tooltip`"
              color="deep-orange darken-4"
            >
              <template v-slot:activator="{ on, attrs }">
                <span
                  v-bind="attrs"
                  class="ma-2"
                  v-on="on"
                  style="font-size: 40px"
                  >{{ item.symbol }}</span
                >
              </template>
              {{ item.label }}
            </v-tooltip>
          </template>
        </div>
      </div>
      <div class="popup__profile--info--about">
        <p v-if="user.birthCity && user.birthCity != user.region">
          <span>Родной город: </span>{{ user.birthCity }}
        </p>
        <p v-if="user.region"><span>Регион: </span>{{ user.region }}</p>
        <p v-if="user.dept"><span>Отдел: </span>{{ user.dept }}</p>
        <div v-if="user.about">
          <span>О себе: </span>
          <p class="quote">
            {{ user.about }}
          </p>
        </div>

        <div v-if="user.interestingFact">
          <span>Интересный факт: </span>
          <p class="quote">
            {{ user.interestingFact }}
          </p>
        </div>

        <div v-if="user.eventOfTheYear">
          <span>Событие года: </span>
          <p class="quote" style="background-color: transparent">
            {{ user.eventOfTheYear }}
          </p>
        </div>

        <p><span>Всего баллов: </span>{{ user.points }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import _ from "lodash";

export default {
  props: ["user"],
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      event: "event/event",
    }),
    menuWelcomeImg() {
      return _.get(this.event, "meta.menu-welcome");
    },
  },
};
</script>

<style scoped>
.popup__profile {
  background-color: white;
  box-sizing: border-box;
  padding: 50px 20px;
  max-width: 840px;
  height: 100%;
  z-index: 1000;
  margin: 0 auto;
}
.popup__profile--inner {
  box-sizing: border-box;
  height: 100%;
  padding: 30px 70px;
  border-radius: 10px;
  border: 1px solid var(--color-primary);
  background: #fbf4e4; /* ЗАМЕНИТЬ НА ПЕРЕМЕННУЮ */
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  position: relative;
}
.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 24px;
  height: 24px;
  cursor: pointer;
  z-index: 1001;
}
.close-btn img {
  width: 24px;
  height: 24px;
}
.popup__profile--inner::-webkit-scrollbar {
  width: 5px;
}
.popup__profile--inner::-webkit-scrollbar-track {
  background-color: transparent;
}
.popup__profile--inner::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar-color);
  border-radius: 10px;
}
.popup__profile--inner h3 {
  font-family: var(--secondary-font);
  font-size: 20px;
  color: var(--color-primary);
  text-align: center;
  margin-bottom: 20px;
}
.popup__profile--info {
  display: flex;
  gap: 50px;
}
.popup__profile--info--img {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.popup__profile--info--img > img {
  width: 150px;
  height: 150px;
  border-radius: 100px;
  margin-bottom: 10px;
}
.popup__profile--info--img > h4 {
  font-family: var(--main-font);
  color: var(--color-text);
  font-size: 16px;
  text-align: center;
  font-weight: 500;
  margin-bottom: 20px;
}
.popup__profile--info--img > button {
  padding: 10px;
  border: none;
  border-radius: 20px;
  background: var(--color-primary);
  color: #fff;
  font-family: var(--main-font);
  font-size: 12px;
  cursor: pointer;
}
.popup__profile--info--edit {
  display: flex;
  align-items: center;
  gap: 4px;
  margin-bottom: 10px;
  cursor: pointer;
}
.popup__profile--info--edit > img {
  width: 19px;
  height: 19px;
}
.popup__profile--info--edit > span {
  font-family: var(--main-font);
  font-size: 14px;
  color: var(--color-text-prof-edit);
}
.popup__profile--info--about {
  display: flex;
  flex-direction: column;
  gap: 15px;
  font: var(--main-font);
  font-size: 14px;
  color: var(--color-text);
}
.popup__profile--info--about p.quote {
  padding: 10px;
  background: #fff;
  border-radius: 10px;
  position: relative;
  font-family: "Inter", sans-serif;
}
.popup__profile--info--about p.quote::after {
  display: block;
  content: "";
  position: absolute;
  left: -10px;
  top: calc(50% - 10px);
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid #fff;
}
.popup__profile--info--about p > span,
.popup__profile--info--about div > span {
  color: var(--color-primary);
}
.popup__profile--info--about label {
  font: var(--main-font);
  font-size: 14px;
  color: var(--color-primary);
  margin: -5px 0;
}
.popup__profile--info--about textarea,
.popup__profile--info--about input {
  padding: 10px;
  background: #fff;
  border: none;
  border-radius: 10px;
  font-family: "Inter", sans-serif;
  resize: none;
}
</style>