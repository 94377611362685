<template>
  <div style="width: 100%; height: 100%">
    <div
      class="popup-quest"
      @scroll="onScrollHandler"
      ref="container"
      style="width: 100%"
    >
      <div class="popup-quest__inner" style="position: relative; width: 100%">
        <div
          class="close-btn"
          @click="$emit('close')"
          style="cursor: pointer"
          v-if="!isInline"
        >
          <img
            src="https://storage.yandexcloud.net/yo-events/advent-2025/img/OTR/icon-close-v2.png"
            style="width: 16px; height: 16px"
          />
        </div>
        <div
          class="popup-quest__header--controls scrolled"
          style="position: absolute; right: 0"
          v-if="
            !isInline &&
            isButtonsContainerEnabled &&
            scrollTop > buttonsContainerOffset + 16
          "
          :style="{ top: `${scrollTop}px` }"
          ref="buttonsContainer"
        >
          <div
            class="popup-quest__header--control btn-blue"
            title="Интересный факт"
            v-if="isFactButtonEnabled"
            @click="onActivateFactClick"
          >
            <img
              src="https://storage.yandexcloud.net/yo-events/advent-2025/img/OTR/icon-lamp.png"
            />
          </div>
          <div
            class="popup-quest__header--control btn-blue"
            v-if="isHintsButtonEnabled"
            @click="onActivateHintClick"
            title="Получить подсказку"
          >
            <img
              src="https://storage.yandexcloud.net/yo-events/advent-2025/img/OTR/icon-lamp.png"
            />
          </div>
          <div
            class="popup-quest__header--control btn-orange"
            v-if="isAnswerEnabled"
            @click="onAnswerClick"
            :title="answerButtonToolTip"
          >
            <img :src="answerButtonIcon" />
          </div>
        </div>
        <slot name="answer-status"></slot>
        <slot name="pick-task"></slot>
        <div class="popup-quest__header" v-if="!isInline">
          <div class="popup-quest__header--status">
            <p>
              <span>Статус</span>
              <v-chip class="ml-2" :color="taskStatusColor" small dark>
                {{ taskStatusText }}
              </v-chip>
            </p>
            <p v-if="allHints.length > 0">
              <span>Подсказок: </span>
              {{ gameTask.hintsUsed }} / {{ allHints.length }}
            </p>
            <p v-if="questTask.attempts > 0">
              <span>Попытки: </span>{{ gameTask.attempts }} /
              {{ questTask.attempts }}
            </p>
            <p v-else>Количество попыток не ограничено</p>
            <p>
              <span>Баллы: </span
              ><del v-if="questTask.points != gameTask.points">{{
                questTask.points
              }}</del>
              {{ gameTask.points }}
            </p>
            <p v-if="displayRemaining && remainingStr">
              {{ remainingStr }}
            </p>
            <p class="text-expired" v-if="false">
              Время начисления 100% баллов истекло.
              <br />Но половину еще можно получить!
            </p>
            <p class="text-success" v-if="false">
              Вы ответили частично правильно и набрали 8 баллов!
            </p>
            <!-- Статус задания -->
            <quest-task-status :gameTask="gameTask" :questTask="questTask" />
          </div>
          <div
            class="popup-quest__header--controls"
            v-if="isButtonsContainerEnabled"
          >
            <div
              class="popup-quest__header--control btn-blue"
              v-if="isHintsButtonEnabled"
              @click="onActivateHintClick"
              title="Получить подсказку"
            >
              <img
                src="https://storage.yandexcloud.net/yo-events/advent-2025/img/OTR/icon-lamp.png"
              /><span>Подсказка</span>
            </div>
            <div
              class="popup-quest__header--control btn-orange"
              v-if="isAnswerEnabled"
              @click="onAnswerClick"
              :title="answerButtonToolTip"
            >
              <img :src="answerButtonIcon" /><span>Ответить</span>
            </div>
          </div>
        </div>
        <div class="popup-quest__content">
          <h2 v-if="questTask.title">{{ questTask.title }}</h2>
          <img
            v-if="questTask.image"
            :src="`https://admin.yo.bahinsky.ru/api/public/image/${questTask.image}?width=800`"
            class="popup-quest__content--img"
          />
          <div
            v-if="isLeftMenuButtonsAvailable"
            style="
              width: 100%;
              display: flex;
              justify-content: center;
              gap: 15px;
            "
          >
            <div
              v-for="(item, index) in leftMenuButtons"
              :key="`left-menu-button-${index}-${item.id}`"
              style="
                padding: 10px 20px;
                margin: 10px;
                background-color: #ec9222;
                color: white;
                border-radius: 20px;
                cursor: pointer;
              "
              @click="onLeftMenuButtonClick(item)"
            >
              {{ item.label }}
            </div>
          </div>
          <div
            class="popup-quest__header--control btn-blue"
            title="Интересный факт"
            v-if="isFactButtonEnabled"
            @click="onActivateFactClick"
          >
            <img
              src="https://storage.yandexcloud.net/yo-events/advent-2025/img/OTR/icon-lamp.png"
            />
            <span>Интересный факт</span>
          </div>
          <div
            class="popup-quest__content--tip"
            v-if="questTask.fact && gameTask.factActivated"
            :key="'fact-content'"
          >
            <h6>Интересный факт</h6>
            <div class="popup-quest__content--tip--desc">
              <v-img
                v-if="questTask.factImage"
                :src="`https://admin.yo.bahinsky.ru/api/public/image/${questTask.factImage}?width=640`"
                contain
              />
              <quest-task-text-content
                v-if="questTask.fact !== ''"
                :value="linkifyHtml(questTask.fact.replace(/\n/gi, '<br/>'))"
                css-class="pa-2 text-body"
              />
            </div>
          </div>
          <div
            class="popup-quest__content--tip"
            v-for="(hint, i) in activatedHints"
            :key="`hint-content-${i}`"
          >
            <h6>Подсказка {{ i + 1 }}</h6>
            <div class="popup-quest__content--tip--desc">
              <v-img
                v-if="hint.image"
                :src="`https://admin.yo.bahinsky.ru/api/public/image/${hint.image}?width=640`"
                contain
              />
              <quest-task-text-content
                v-if="hint.description"
                :value="linkifyHtml(hint.description.replace(/\n/gi, '<br/>'))"
                css-class="pa-2 text-body with-link"
              />
            </div>
          </div>
          <div class="description">
            <!-- Описание задания -->
            <template v-if="questTaskDescription">
              <v-divider></v-divider>
              <quest-task-text-content
                v-if="questTaskDescription"
                :value="
                  linkifyHtml(questTaskDescription.replace(/\n/gi, '<br/>'))
                "
                css-class="ma-2"
              />
            </template>
            <!-- Видео плеер -->
            <div class="mx-2" v-if="video">
              <video controls playsinline style="max-width: 100%" :key="video">
                <source :src="video" type="video/mp4" />
              </video>
            </div>
            <!-- Аудио плеер -->
            <div class="mx-2" v-if="audio">
              <vuetify-audio
                :file="audio"
                color="primary"
                :key="audio"
                flat
              ></vuetify-audio>
            </div>
          </div>
          <div
            class="description"
            v-if="isAnswerEnabled && isInline"
            style="background-color: white"
          >
            <h2>Отправка задания</h2>
            <div>
              <v-text-field
                :key="`inline-text-${gameTask._id}`"
                v-model="inlineAnswer"
                label="Описание"
              />
              <v-file-input
                v-model="inlineAnswerFile"
                :key="`inline-file-${gameTask._id}`"
                label="Выберите файл"
                :accept="uploadAcceptMimeType"
              />
              <v-btn
                color="primary"
                :loading="uploading"
                :disabled="uploading || !inlineAnswer || !inlineAnswerFile"
                @click="onInlineAnswer"
                >Отправить</v-btn
              >
            </div>
          </div>
        </div>
        <div style="height: 100px">&nbsp;</div>
      </div>
    </div>

    <!-- input с файлов-ответом -->
    <input
      type="file"
      ref="file"
      :accept="uploadAcceptMimeType"
      @change="onFilePicked"
      style="display: none"
    />

    <!-- Диалог с ответом -->
    <quest-task-answer-dialog
      v-model="answerDialog"
      :gameTask="gameTask"
      :questTask="questTask"
      :multiline="isMultiLine"
      @answer="onSubmitAnswerClick"
    />

    <!-- Диалог с активацией подсказки -->
    <quest-task-hint-dialog
      v-model="hintDialog"
      :gameTask="gameTask"
      :questTask="questTask"
      @hint-confirm="onHintConfirm"
      :show-price="true"
    />
  </div>
</template>

<script>
import get from "lodash/get";
import map from "lodash/map";
import omit from "lodash/omit";
import pick from "lodash/pick";
import find from "lodash/find";
import slice from "lodash/slice";
import last from "lodash/last";
import first from "lodash/first";
import linkify from "linkifyjs/html";
import moment from "moment-timezone";

import { mapMutations } from "vuex";

import QuestTaskStatus from "./QuestTask/Status";
import QuestTaskAnswerDialog from "./QuestTask/AnswerDialog";
import QuestTaskHintDialog from "./QuestTask/HintDialog";
import QuestTaskTextContent from "./QuestTask/TextContent.vue";

import ThemeMixin from "../../../../mixins/eventTheme";

export default {
  name: "QuestTask",
  mixins: [ThemeMixin],
  props: [
    "questTask",
    "gameTask",
    "date",
    "is-admin",
    "yesterday-points-ratio",
    "uploading",
  ],
  components: {
    VuetifyAudio: () => import("vuetify-audio"),
    QuestTaskStatus,
    QuestTaskAnswerDialog,
    QuestTaskHintDialog,
    QuestTaskTextContent,
  },
  data() {
    return {
      okStatuses: [
        "ready",
        "current",
        "pending",
        "postponed",
        "partial-success",
      ],
      wrongStatuses: ["success", "expired", "failed"],
      answerDialog: false,
      hintDialog: false,
      ok: false,
      showAnswerMessage: true,
      now: new Date(),
      nowTimer: null,
      container: null,
      buttonsContainer: null,
      scrollTop: null,
      taskDropDownVisible: false,
      inlineAnswer: null,
      inlineAnswerFile: null,
    };
  },
  created() {
    if (this.date) {
      this.nowTimer = setInterval(() => {
        this.now = new Date();
      }, 1000);
    }
  },
  beforeDestroy() {
    if (this.nowTimer) {
      clearInterval(this.nowTimer);
      this.nowTimer = null;
    }
  },
  watch: {
    parentElement(el, prevEl) {
      if (prevEl != null) {
        prevEl.removeEventListener("scroll", this.onScrollHandler);
      }
      if (el != null) {
        el.addEventListener("scroll", this.onScrollHandler);
      }
    },
  },
  computed: {
    audio() {
      return this.questTask && this.questTask.audio;
    },
    video() {
      return this.questTask && this.questTask.video;
    },
    isAnswerEnabled() {
      if (!this.isAdmin && this.isTooEarly === true) return false;
      const { status: gameTaskStatus, attempts: gameTaskAttempts } =
        this.gameTask;
      const { attempts: questTaskAttempts, kind: questTaskType } =
        this.questTask;
      return (
        questTaskType !== "noanswer" &&
        this.okStatuses.includes(gameTaskStatus) &&
        (gameTaskAttempts < questTaskAttempts || questTaskAttempts === 0)
      );
    },
    isFactButtonEnabled() {
      const factActivated = get(this, "gameTask.factActivated") || false;
      const fact = get(this, "questTask.fact") || "";
      return !factActivated && !!fact;
    },
    isHintsButtonEnabled() {
      return this.activatedHints.length < this.allHints.length;
    },
    isButtonsContainerEnabled() {
      return (
        this.isAnswerEnabled ||
        this.isFactButtonEnabled ||
        this.isHintsButtonEnabled
      );
    },
    isMultiLine() {
      return !!find(get(this.questTask, "tags"), (t) => t === "multiline");
    },
    isInline() {
      return !!find(get(this.questTask, "tags"), (t) => t === "inline");
    },
    isLeftMenuButtonsAvailable() {
      return this.leftMenuButtons.length > 0;
    },
    leftMenuButtons() {
      return get(this.questTask, "tags")
        .filter((s) => typeof s === "string")
        .filter((s) => s.startsWith("leftmenu|"))
        .map((s) => s.split("|"))
        .filter((a) => a.length === 3)
        .map((items) => {
          const [f, id, label] = items;
          return {
            f,
            id,
            label,
          };
        });
    },
    allHints() {
      return map(get(this, "questTask.hints"), (hint) => {
        const locale = omit(
          find(get(hint, "localization"), (l) => l.language === "ru"),
          ["_id", "language"]
        );
        return {
          ...pick(hint, ["_id", "image", "points"]),
          ...locale,
        };
      });
    },
    activatedHints() {
      const hintsUsed = get(this, "gameTask.hintsUsed") || 0;
      return slice(this.allHints, 0, hintsUsed);
    },
    questTaskKind() {
      return get(this, "questTask.kind");
    },
    isUploadableTask() {
      return ["video", "photo", "audio"].includes(this.questTaskKind);
    },
    uploadAcceptMimeType() {
      const kindMimeMapping = {
        video: "video/*",
        audio: "audio/*",
        photo: "image/*",
      };
      return kindMimeMapping[this.questTaskKind];
    },
    answerButtonIcon() {
      switch (this.questTaskKind) {
        case "video":
          return "https://storage.yandexcloud.net/yo-events/advent-2025/img/icons/icon-video.png";
        case "photo":
          return "https://storage.yandexcloud.net/yo-events/advent-2025/img/icons/icon-photo.png";
        case "audio":
          return "https://storage.yandexcloud.net/yo-events/advent-2025/img/OTR/icon-chat-edit.png";
        default:
          return "https://storage.yandexcloud.net/yo-events/advent-2025/img/OTR/icon-chat-edit.png";
      }
    },
    answerButtonToolTip() {
      switch (this.questTaskKind) {
        case "video":
          return "Отправить видео файл";
        case "photo":
          return "Отправить фото / картинку";
        case "audio":
          return "Отправить аудио файл";
        default:
          return "Ответить";
      }
    },
    taskStatus() {
      return this.gameTask.status;
    },
    taskStatusColor() {
      if (get(this.gameTask, "pendingStatus") === "pending") {
        return "info";
      }
      switch (get(this.gameTask, "status")) {
        case "ready":
        case "current":
        case "postponed":
          return "primary";
        case "success":
          return "green";
        case "partial-success":
          return "success";
        case "pending":
          return "info";
        case "expired":
        case "failed":
          return "error";
        default:
          return null;
      }
    },
    taskStatusText() {
      if (get(this.gameTask, "pendingStatus") === "pending") {
        return "На проверке";
      }
      switch (get(this.gameTask, "status")) {
        case "ready":
        case "current":
        case "postponed":
          return "Активно";
        case "success":
          return "Верный ответ";
        case "partial-success":
          return "Частично верный ответ";
        case "pending":
          return "Ожидает проверки";
        case "expired":
          return "Время истекло";
        case "failed":
          return "Неверный ответ";
        default:
          return null;
      }
    },
    startDate() {
      if (this.date == null) return null;
      const m = moment(this.date);
      if (!m.isValid()) return null;
      return m.startOf("day");
    },
    deadline() {
      if (this.date == null) return null;
      let m = moment(this.date);
      if (!m.isValid()) return null;

      let currentDay = m.format("YYYY-MM-DD");
      const tags = get(this.questTask, "tags") || [];
      while (tags.includes(currentDay)) {
        m = moment(currentDay);
        const d = moment(currentDay).add(1, "day");
        currentDay = d.format("YYYY-MM-DD");
      }
      return m.endOf("day");
    },
    nowStr() {
      if (this.now == null) return null;
      return moment(this.now).format("HH:mm:ss");
    },
    isTooLate() {
      if (this.now == null || this.deadline == null) return null;
      return this.deadline.isBefore(this.now, "days");
    },
    isTooEarly() {
      if (this.now == null || this.startDate == null) return null;
      return this.startDate.isAfter(this.now, "days");
    },
    displayRemaining() {
      const { status: gameTaskStatus } = this.gameTask;
      return this.okStatuses.includes(gameTaskStatus);
    },
    remainingStr() {
      if (this.isTooLate == true) {
        if (this.questTask.points > 0) {
          if (this.yesterdayPointsRatio === 0.5) {
            return "Время начисления 100% баллов истекло. Но половину еще можно получить…";
          } else if (this.yesterdayPointsRatio === 1.0) {
            return null;
          } else {
            return `Время начисления 100% баллов истекло. Но ${this.yesterdayPointsRatio}x еще можно получить…`;
          }
        } else {
          return null;
        }
      }
      if (this.deadline == null) return null;
      if (!this.isAnswerEnabled) return null;
      const ms = moment(this.deadline).toDate().getTime() - this.now.getTime();
      const duration = moment.duration(ms);
      const days = duration.days();
      const hours = days > 0 ? duration.hours() + days * 24 : duration.hours();
      const minutes = duration.minutes();
      const seconds = duration.seconds();
      return `До конца осталось: ${hours}:${
        minutes >= 10 ? minutes : "0" + minutes
      }:${seconds >= 10 ? seconds : "0" + seconds}`;
    },
    parentElement() {
      const val = this.container;
      return val;
    },
    buttonsContainerOffset() {
      try {
        return (
          this.buttonsContainer.offsetTop + this.buttonsContainer.offsetHeight
        );
      } catch (err) {
        return 0;
      }
    },
    questTaskDescription() {
      const description = get(this.questTask, "description") || "";
      const shortDescription = get(this.questTask, "shortDescription") || "";

      if (!this.isInline) {
        return description;
      }

      if (this.isAnswerEnabled) {
        return description;
      } else {
        return shortDescription || description;
      }
    },
  },
  mounted() {
    this.container = this.$refs.container;
    this.buttonsContainer = this.$refs.buttonsContainer?.$el;
  },
  methods: {
    ...mapMutations({
      setActiveLeftMenuItem: "event/activeItemId",
    }),
    onInlineAnswer() {
      const payload = {
        file: this.inlineAnswerFile,
        answer: this.inlineAnswer,
      };
      this.$emit("inline-answer", payload);
    },
    setTaskDropDownVisible(newValue) {
      this.taskDropDownVisible = newValue;
    },
    onLeftMenuButtonClick(item) {
      const { id } = item;
      this.setActiveLeftMenuItem(id);
      this.$emit("close", id);
    },
    onAnswerClick() {
      if (!this.isUploadableTask) {
        this.answerDialog = true;
      } else {
        this.$refs.file.click();
      }
    },
    onSubmitAnswerClick(answer) {
      this.$emit("answer", answer);
      this.answerDialog = false;
    },
    onActivateFactClick() {
      this.$emit("fact-activation");
    },
    onActivateHintClick() {
      this.hintDialog = true;
    },
    onHintConfirm() {
      this.hintDialog = false;
      this.$emit("hint-activation");
    },
    onFilePicked(event) {
      const file = get(event, "target.files[0]");
      if (file != null) {
        this.$emit("answer-file-selected", file);
      }
    },
    linkifyHtml(raw) {
      const r = /\[([^\]]+)\]/gi;
      if (r.test(raw || "")) {
        return raw.replace(r, (str) => {
          const strWithourBrackets = str.substring(1, str.length - 1);
          const items = strWithourBrackets.split("|");
          if (items.length < 2) {
            return str;
          }
          const type = first(items);
          const link = last(items);
          const params = slice(items, 1, items.length).reduce((acc, item) => {
            const [name, ...rest] = item.split("=");
            if (!name || !rest || !rest.length) {
              return acc;
            }
            acc[name] = rest.join("");
            return acc;
          }, {});
          switch (type) {
            case "audio":
              return `
              ${
                params.title ? `<div class='text-h6'>${params.title}</div>` : ""
              }
              <audio controls class='mt-2' style='width: 100%;'>
                <source src='${link}' />
              </audio>`;
            case "video":
              return `
              ${
                params.title ? `<div class='text-h6'>${params.title}</div>` : ""
              }
              <video controls class='mt-2' style='width: 100%;'>
                <source src='${link}' />
              </video>`;
            case "image":
              return `
              ${
                params.title ? `<div class='text-h6'>${params.title}</div>` : ""
              }
              <a href="${link}" target="_blank">
                <img src="${link}" style="width: 100%; max-height: calc(100vh - 200px); object-fit: contain;" />
              </a>
              `;
            case "file":
              return `<a href="${link}" target="_blank">${
                params.title ? params.title : link
              }</a>`;
            case "link":
              return `<a href="${link}" target="_blank">${
                params.title ? params.title : link
              }</a>`;
            default:
              return str;
          }
        });
      } else {
        return linkify(raw || "");
      }
    },
    onScrollHandler(e) {
      this.scrollTop = e.target.scrollTop;
    },
  },
};
</script>

<style>
a.linkified {
  color: #42a5f5 !important;
}
</style>

<style>
.popup-quest {
  box-sizing: border-box;
  padding: 0;
  /* height: calc(100vh - 80px); */
  height: 100%;
  width: 100%;
  z-index: 1000;
  margin: 0 auto;
  display: flex;
  overflow: hidden;
}
.popup-quest * p {
  margin: 0 !important;
}
.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 24px;
  height: 24px;
  cursor: pointer;
  z-index: 1001;
}
.popup-quest__inner {
  box-sizing: border-box;
  height: 100%;
  padding-bottom: 200px;
  width: auto;
  padding: 15px;
  border-radius: 10px;
  border: 1px solid var(--color-primary);
  background: #fbf4e4; /* ЗАМЕНИТЬ НА ПЕРЕМЕННУЮ */
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  position: relative;
}
.popup-quest__inner::-webkit-scrollbar {
  width: 5px;
}
.popup-quest__inner::-webkit-scrollbar-track {
  background-color: transparent;
}
.popup-quest__inner::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar-color);
  border-radius: 10px;
}
.popup-quest__q {
  padding: 20px 30px;
  border-radius: 20px;
  background: var(--content-bck);
  margin-bottom: 20px;
  overflow: visible;
}
.popup-quest__q > h6 {
  font-family: var(--main-font);
  font-size: 14px;
  color: var(--color-primary);
  margin-bottom: 15px;
}
.popup-quest__q--btn {
  position: relative;
  overflow: visible;
}
.popup-quest__q--btn--inner {
  padding: 10px 15px 10px 25px;
  border: 1px solid var(--color-primary);
  border-radius: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
}
.popup-quest__q--btn--inner > span {
  font-family: var(--main-font);
  font-size: 15px;
  color: var(--color-text);
  width: 100%;
  text-align: center;
}
.popup-quest__q--btn--inner > img {
  width: 10px;
  height: 5px;
}
.popup-quest__q--drop {
  position: absolute;
  left: 0;
  top: 40px;
  width: 100%;
  border-radius: 10px;
  background: #fbf4e4; /* СОЗДАТЬ ПЕРЕМЕННУЮ */
}
.popup-quest__q--drop--item {
  box-sizing: border-box;
  padding: 10px 15px;
  width: 100%;
  text-align: center;
  font-family: var(--main-font);
  color: var(--color-text);
  font-size: 15px;
  border-bottom: 1px solid var(--color-primary);
  cursor: pointer;
}
.popup-quest__q--drop--item:last-child {
  border: 0;
}
.popup-quest__header {
  display: flex;
  gap: 50px;
  justify-content: space-between;
  padding: 20px 30px;
  background: var(--content-bck);
  border-radius: 20px;
}
.popup-quest__header--status {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.popup-quest__header--status p {
  font-family: var(--main-font);
  font-size: 14px;
  color: var(--color-text);
}
.popup-quest__header--status p > span {
  color: var(--color-primary);
}
.popup-quest__header--status p del {
  color: #d54a38;
}
.popup-quest__header--status p.text-expired {
  font-size: 12px;
  color: #6d6d6d; /* ЗАМЕНИТЬ НА ПЕРЕМЕННУЮ */
}
.popup-quest__header--status p.text-success {
  color: #05acbc; /* ЗАМЕНИТЬ НА ПЕРЕМЕННУЮ */
}
.popup-quest__header--controls {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.popup-quest__header--control {
  box-sizing: border-box;
  width: 120px;
  display: flex;
  gap: 5px;
  padding: 6px 5px 6px 15px;
  align-items: center;
  cursor: pointer;
  border-radius: 20px;
}
.popup-quest__header--control.btn-blue {
  background: #05acbc;
}
.popup-quest__header--control.btn-orange {
  background: var(--color-primary);
}
.popup-quest__header--control.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.popup-quest__header--control > img {
  width: 20px;
  height: 20px;
}
.popup-quest__header--control > span {
  font-size: 12px;
  color: #fff;
}
.popup-quest__header--controls.scrolled {
  padding: 5px 10px;
  flex-direction: row;
  background: #fbf4e4; /* ЗАМЕНИТЬ НА ПЕРЕМЕННУЮ */
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  position: absolute;
  top: 20px;
  right: 0;
}
.popup-quest__header--controls.scrolled .popup-quest__header--control {
  padding: 3px;
  width: 26px;
}
.popup-quest__content {
  display: flex;
  flex-direction: column;
  gap: 20px;
  background: #fff;
  border-radius: 20px;
  padding: 30px 15px;
  margin-top: 20px;
  position: relative;
}
.popup-quest__content h2 {
  font-family: var(--secondary-font);
  font-size: 20px;
  color: var(--color-primary);
  text-align: center;
  text-transform: uppercase;
}
.popup-quest__content--img {
  width: 100%;
  height: auto;
}
.popup-quest__content .popup-quest__header--control {
  width: 100%;
  justify-content: center;
}
.popup-quest__content--tip {
  background: #fbf4e4; /* ЗАМЕНИТЬ НА ПЕРЕМЕННУЮ */
  border-radius: 10px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.popup-quest__content--tip h6 {
  font-family: var(--main-font);
  color: var(--color-primary);
  font-size: 14px;
  font-weight: 500;
  text-align: center;
}
.popup-quest__content--tip p {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  color: var(--color-text);
  line-height: 20px;
}
.popup-quest__content > .description {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  color: var(--color-text);
  line-height: 20px;
}
.popup-quest__content--tip.answers {
  margin-top: 20px;
  padding: 20px 30px;
  border-radius: 20px;
  background: var(--content-bck);
}
.popup-quests__content--answer {
  display: flex;
  gap: 5px;
  align-items: center;
}
.popup-quests__content--answer > img {
  width: 20px;
  height: 20px;
}
.popup-quests__content--answer > span {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  color: var(--color-text);
}
.popup-quest__content--tip--desc {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  color: var(--color-text);
  line-height: 20px;
}
@media (max-width: 1280px) {
  .popup-quest {
    height: 100%;
  }
  .popup-quest__inner {
    border: none;
  }
}
@media (max-width: 900px) {
  .popup-quest {
    padding: 0;
    height: 100%;
  }
  .popup-quest__inner {
    border-radius: 0;
    height: 100%;
    max-width: 100vw;
    min-width: 0;
    border: none;
  }
}
</style>