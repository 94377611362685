<template>
  <div>
    <div class="page-wrap">
      <header>
        <div class="header__welcome">
          <img
            class="header__welcome--nav-btn"
            src="https://storage.yandexcloud.net/yo-events/advent-2025/img/OTR/icon-nav.png"
            @click="setIsLeftMenuForMobileVisible(true)"
          />
          <img
            class="header__welcome--av"
            :src="profileThumbnail || menuWelcomeImg"
            style="object-fit: cover"
            :style="{ borderRadius: profileThumbnail ? '100px' : '' }"
          />
          <h2>
            Привет,<br /><span>{{ user.profile.name }}</span>
          </h2>
        </div>
        <div class="header__controls">
          <div class="header__control control-user" v-if="activeChallenge">
            <v-tooltip bottom color="primary">
              <template v-slot:activator="{ on, attrs }">
                <img
                  v-bind="attrs"
                  v-on="on"
                  @click="onSnowFightClick"
                  src="https://storage.yandexcloud.net/yo-events/advent-2025/img/icons/icon-snow-fight.png"
                />
              </template>
              <span>Вызов на снежный бой</span>
            </v-tooltip>
          </div>
          <div class="header__control control-user">
            <v-menu bottom close-on-content-click>
              <template v-slot:activator="{ on, attrs }">
                <img
                  v-bind="attrs"
                  v-on="on"
                  src="https://storage.yandexcloud.net/yo-events/advent-2025/img/OTR/icon-user.png"
                />
              </template>

              <div class="header__control--menu">
                <div class="header__control--menu--item" @click="onIsMyProfile">
                  <img
                    src="https://storage.yandexcloud.net/yo-events/advent-2025/img/OTR/icon-user.png"
                  />
                  <span>Мой профиль</span>
                </div>
                <div class="header__control--menu--item" @click="onSignOut">
                  <img
                    src="https://storage.yandexcloud.net/yo-events/advent-2025/img/OTR/icon-logout.png"
                  />
                  <span>Выйти</span>
                </div>
              </div>
            </v-menu>
          </div>
        </div>
      </header>
      <menu
        id="menu"
        :class="{
          'menu-mobile-hidden': !isLeftMenuForMobileVisible,
          'menu-mobile-visible': isLeftMenuForMobileVisible,
        }"
      >
        <div class="menu__close" @click="isLeftMenuForMobileVisible = false">
          <img
            src="https://storage.yandexcloud.net/yo-events/advent-2025/img/OTR/icon-close.png"
            class="menu__close--img"
            @click="isLeftMenuForMobileVisible = false"
          />
        </div>
        <div class="menu__logo">
          <!-- <img
            :src="menuLogo"
            :width="menuLogoWidth"
            :height="menuLogoHeight"
          /> -->
          <img :src="menuLogo" width="210" height="60" />
          <!-- <img
            v-if="menuLogoHat"
            class="menu__logo--hat"
            :src="menuLogoHat"
            width="19.38"
            height="19.38"
          /> -->
        </div>
        <div class="menu">
          <div class="menu__items">
            <div
              class="menu__item"
              v-for="(item, index) in visibleItems"
              :key="index"
              @click="onItemSelect(item.id)"
              :class="{ active: item.id == activeItemId }"
            >
              {{ item.title }}
            </div>
          </div>
          <div
            class="menu__happy-new-year"
            style="
              display: none;
              background-image: url(https://storage.yandexcloud.net/yo-events/advent-2025/img/OTR/menu-happy-ny.png);
            "
          >
            <div class="menu__happy-new-year--count">
              До Нового года осталось
            </div>
            <div class="menu__happy-new-year--counter">28 дней</div>
          </div>
        </div>
      </menu>
      <div class="content">
        <event-content
          v-if="activeItem"
          :content="activeItem.content"
          :layout="activeItem.layout"
          :mapping="mapping"
        />
      </div>
    </div>
    <my-profile
      v-if="isMyProfileVisible"
      @input="isMyProfileVisible = $event"
    />
  </div>
</template>

<script>
import eventMixin from "@/mixins/eventMixin.js";
import themeMixin from "@/mixins/eventTheme";
import eventMenuMixin from "@/mixins/eventMenuMixin";
import _ from "lodash";
import { mapActions, mapGetters } from "vuex";
import MyProfile from "./MyProfile.vue";

export default {
  mixins: [eventMixin, eventMenuMixin, themeMixin],
  components: {
    MyProfile,
  },
  data() {
    return {
      isMyProfileVisible: false,
      isLeftMenuForMobileVisible: false,
    };
  },
  created() {},
  methods: {
    ...mapActions({
      authSignOut: "event/authSignOut",
    }),
    onSnowFightClick() {
      this.onItemSelect("battle");
    },
    onSignOut() {
      this.authSignOut();
    },
    onIsMyProfile() {
      this.isMyProfileVisible = true;
    },
    setIsLeftMenuForMobileVisible(val) {
      this.isLeftMenuForMobileVisible = val;
    },
  },
  computed: {
    ...mapGetters({
      activeChallenge: "event/challenge/activeChallenge",
    }),
    menuWelcomeImg() {
      return _.get(this.event, "meta.menu-welcome");
    },
    profileThumbnail() {
      return _.get(this.user, "profile.thumbnail");
    },
    menuLogo() {
      return _.get(this.event, "meta.menu-logo");
    },
    menuLogoWidth() {
      return _.get(this.event, "meta.menu-logo-width");
    },
    menuLogoHeight() {
      return _.get(this.event, "meta.menu-logo-height");
    },
    menuLogoHat() {
      return _.get(this.event, "meta.menu-logo-hat");
    },
  },
};
</script>

<style>
textarea {
  width: 100% !important;
}
.v-dialog {
  overflow: hidden !important;
  box-shadow: none !important;
}
.v-menu__content {
  z-index: 500;
}
@media (max-width: 1040px) {
  .menu-mobile-hidden {
    left: -300px;
  }
  .menu-mobile-hidden .menu__close {
    display: none;
  }
  .menu-mobile-visible {
    left: 0px;
  }
  .v-dialog {
    padding: 0px !important;
    margin: 0px !important;
  }
}
</style>