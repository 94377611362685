<template>
  <div class="page-wrap-full">
    <div class="menu__logo">
      <img :src="menuLogo" :width="menuLogoWidth" :height="menuLogoHeight" />
      <img
        v-if="menuLogoHat"
        class="menu__logo--hat"
        :src="menuLogoHat"
        width="19.38"
        height="19.38"
      />
    </div>
    <div class="page-signin">
      <img
        src="https://storage.yandexcloud.net/yo-events/advent-2025/img/OTR/signin-top.png"
        class="page-signin__img"
      />
      <h2>Добро пожаловать в Адвент 2025!</h2>
      <div class="page-signin__form" v-if="!isSuccess">
        <p>
          Введите свою почту, и мы вышлем вам ссылку для входа в свой профиль.
        </p>
        <div class="page-signin__input--wrapper">
          <input class="page-signin__input" v-model="email" type="text" />
          <p class="text-error" v-if="isError && message">
            {{ message }}
          </p>
        </div>
        <button
          class="page-signin__button"
          :disabled="isDisabled"
          @click="onSubmit"
        >
          Отправить
        </button>
      </div>
      <div class="page-signin__status" v-if="isSuccess">
        <p>Спасибо! <br />Мы отправили ссылку для входа на вашу почту.</p>
      </div>
    </div>
  </div>
</template>

<script>
import validator from "validator";
import { mapActions } from "vuex";
import eventMixin from "@/mixins/eventMixin.js";
import themeMixin from "@/mixins/eventTheme";
import eventMenuMixin from "@/mixins/eventMenuMixin";
import _ from "lodash";

export default {
  mixins: [eventMixin, eventMenuMixin, themeMixin],
  data() {
    return {
      email: "",
      mode: "form", // form, success, error
      loading: false,
      message: null,
    };
  },
  computed: {
    isValid() {
      return validator.isEmail((this.email || "").trim());
    },
    isDisabled() {
      if (!this.isValid) return true;
      if (this.mode === "success") return true;
      return false;
    },
    isSuccess() {
      return this.mode === "success";
    },
    isError() {
      return this.mode === "error";
    },
    menuLogo() {
      return _.get(this.event, "meta.menu-logo");
    },
    menuLogoWidth() {
      return _.get(this.event, "meta.menu-logo-width");
    },
    menuLogoHeight() {
      return _.get(this.event, "meta.menu-logo-height");
    },
    menuLogoHat() {
      return _.get(this.event, "meta.menu-logo-hat");
    },
  },
  methods: {
    ...mapActions({
      authSignIn: "event/authSignIn",
      authSignUp: "event/authSignUp",
      authSignOut: "event/authSignOut",
      authEmailSignIn: "event/authEmailSignIn",
      getGratitudeSummary: "event/getGratitudeSummary",
      getGratitudeStats: "event/getGratitudeStats",
      getGratitudeUsers: "event/getGratitudeUsers",
      getGratitudeMessages: "event/getGratitudeMessages",
      postGratitudeMessage: "event/postGratitudeMessage",
    }),
    async onSubmit() {
      if (!this.isDisabled) {
        const email = (this.email || "").trim().toLowerCase();
        const data = { email };
        try {
          this.loading = true;
          const result = await this.authEmailSignIn(data);
          if (result && result.ok === true) {
            this.mode = "success";
            this.message = result.message;
          } else {
            this.mode = "error";
            this.message = result.message;
          }
        } catch (err) {
          console.error(err);
        } finally {
          this.loading = false;
        }
      }
    },
  },
};
</script>

<style scoped>
.page-wrap-full {
  box-sizing: border-box;
  width: 100%;
  height: 100vh;
  padding: 0 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.page-signin {
  width: 330px;
  min-height: 217px;
  background: var(--content-bck);
  border-radius: 10px;
  padding: 40px 15px 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow: visible;
  position: relative;
}
.page-signin__img {
  width: 384px;
  height: 77.9px;
  position: absolute;
  left: -12px;
  top: -23px;
}
.page-signin h2 {
  font-family: var(--secondary-font);
  font-size: 20px;
  color: var(--color-primary);
  text-align: center;
  text-transform: uppercase;
}
.page-signin__form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
}
.page-signin__form > p {
  padding: 0 30px;
  font-family: var(--main-font);
  font-size: 14px;
  color: var(--color-text);
  text-align: center;
}
.page-signin__input--wrapper p {
  margin-top: 10px;
  font-family: "Inter";
  font-size: 12px;
  line-height: 16px;
  color: var(--color-text);
}
.page-signin__input--wrapper p.text-error {
  color: #b20303;
}
.page-signin__input {
  box-sizing: border-box;
  width: 100%;
  padding: 15px;
  background: #fff;
  border: none;
  border-radius: 10px;
  font-family: var(--main-font);
  color: var(--color-text);
  font-size: 14px;
}
.page-signin__button {
  width: 100px;
  height: 26px;
  padding: 6px 5px;
  background: var(--color-primary);
  border: 0;
  outline: 0;
  border-radius: 20px;
  font-family: var(--main-font);
  font-size: 12px;
  color: #fff;
  cursor: pointer;
}
.page-signin__button[disabled] {
  opacity: 0.5;
  cursor: not-allowed;
}
.page-signin__status {
  margin-top: 30px;
}
.page-signin__status p {
  padding: 0 30px;
  font-family: var(--main-font);
  font-size: 14px;
  color: #276a5e;
  text-align: center;
}
.page-wrap-full .menu__logo {
  position: absolute;
  top: 30px;
  left: 25px;
  width: 210px;
}
@media (max-width: 390px) {
  .page-wrap-full .menu__logo {
    left: calc(50% - 210px / 2);
  }
}
@media (max-height: 620px) {
  .page-wrap-full .menu__logo {
    display: none;
  }
}
</style>