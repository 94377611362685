<template>
  <v-app>
    <v-main>
      <loading v-if="loading" />
      <sign-in v-else-if="isSignIn" />
      <countdown
        v-else-if="isCountdown"
        @click="onCountDownClick"
        :diff="countDownDiff"
        :clickable="isAdmin"
      />
      <event-content v-else-if="isContent" :mapping="mapping" />
      <v-btn
        fab
        :color="isNewMessage && !chatDialog ? 'accent' : 'info'"
        @click="chatDialog = true"
        style="position: fixed; bottom: 20px; right: 20px"
        v-if="chatEnabled"
      >
        <v-icon>mdi-chat</v-icon>
      </v-btn>
      <v-dialog
        v-if="chatEnabled"
        v-model="chatDialog"
        :max-width="500"
        scrollable
        :fullscreen="$vuetify.breakpoint.mobile"
      >
        <chat v-if="chatEnabled && chatDialog" @close="chatDialog = false">
        </chat>
      </v-dialog>
    </v-main>
  </v-app>
</template>

<script>
import Chat from "@/components/event/Content/Chat/Index.vue";
import eventMixin from "@/mixins/eventMixin.js";
import themeMixin from "@/mixins/eventTheme";
import { mapActions, mapGetters, mapMutations } from "vuex";
import SignIn from "./Advent2025/SignIn.vue";
import Countdown from "./Advent2025/Countdown.vue";
import Loading from "./Advent2025/Loading.vue";
import EventContent from "./Advent2025/Content.vue";
import _ from "lodash";
import moment from "moment-timezone";

export default {
  name: "Advent2025Event",
  mixins: [eventMixin, themeMixin],
  data() {
    return {
      chatDialog: false,
      socket: null,
      isMounted: false,
      isContentForced: false,
      adventStartTimer: null,
      countDownDiff: null,
      chatCheckTimer: null,
    };
  },
  components: {
    Chat,
    SignIn,
    Countdown,
    Loading,
    EventContent,
  },
  created() {
    if (this.user) {
      this.chatCheckTimer = setInterval(() => {
        this.getChatMessages();
      }, 15000);
      this.getChatMessages();
    }
  },
  computed: {
    ...mapGetters({
      chatRooms: "event/chat/rooms",
      activeChallenge: "event/challenge/activeChallenge",
      challengeDialog: "event/challenge/challengeDialog",
      challengeTargetUser: "event/challenge/challengeTargetUser",
      supportedChallengeGames: "event/challenge/supportedGames",
      selectedChallengeGameId: "event/challenge/selectedGameId",
    }),
    chatShouldBePresented() {
      return this.chatEnabled && !!this.userId;
    },
    roomId() {
      return this.eventId;
    },
    userName() {
      return _.get(this.user, "profile.name");
    },
    isSignIn() {
      return this.user == null;
    },
    isCountdown() {
      return (
        this.countDownDiff && this.countDownDiff > 0 && !this.isContentForced
      );
    },
    isContent() {
      return (
        !this.isSignIn &&
        (!this.isCountdown || (this.isAdmin && this.isContentForced))
      );
    },
    adventStartDate() {
      const d = _.get(this.event, "meta.advent-start");
      if (d == null) return null;
      const m = moment(d);
      if (!m.isValid()) return null;
      return m;
    },
    backgroundImage() {
      return _.get(this.event, "meta.background-image");
    },
  },
  methods: {
    ...mapMutations({
      closeChallengeDialog: "event/challenge/closeChallengeDialog",
      setSelectedGameId: "event/challenge/setSelectedGameId",
    }),
    ...mapActions({
      sendChallenge: "event/challenge/sendChallenge",
      getChatMessages: "event/chat/getChatMessages",
    }),
    onCountDownClick() {
      if (this.isAdmin) {
        this.isContentForced = true;
      }
    },
    onSendChallenge() {
      this.sendChallenge();
    },
    initChat() {},
    disableChat() {},
    onChangeChallengeDialog() {
      this.closeChallengeDialog();
    },
    onChallengeGameChange(value) {
      this.setSelectedGameId(value);
    },
    stopAdventChatTimer() {
      if (this.chatCheckTimer) {
        try {
          window.clearInterval(this.chatCheckTimer);
          this.chatCheckTimer = null;
        } catch (err) {
          console.error(err);
        }
      }
    },
    stopAdventStartTimer() {
      if (this.adventStartTimer) {
        try {
          window.clearInterval(this.adventStartTimer);
          this.adventStartTimer = null;
        } catch (err) {
          console.error(err);
        }
      }
    },
    initBackgroundImage() {
      if (this.backgroundImage != null) {
        document.body.style.backgroundImage = `url(${this.backgroundImage})`;
      }
    },
  },
  beforeDestroy() {
    if (this.socket) {
      this.socket.close();
      this.socket = null;
    }
    this.stopAdventStartTimer();
    this.stopAdventChatTimer();
  },
  mounted() {
    this.isMounted = true;
    if (this.chatShouldBePresented) {
      this.$nextTick(() => {
        this.initChat();
      });
    }
  },
  watch: {
    adventStartDate(val) {
      this.stopAdventStartTimer();
      if (val != null && val.isAfter(Date())) {
        const diff = this.adventStartDate.diff(moment());
        this.countDownDiff = diff;
        this.stopAdventStartTimer = window.setInterval(() => {
          const diff = this.adventStartDate.diff(moment());
          this.countDownDiff = diff;
        }, 1000);
      }
    },
    backgroundImage() {
      this.initBackgroundImage();
    },
    isSignIn(val) {
      console.log("isSignIn", val);
      this.stopAdventChatTimer();
      if (!val) {
        this.chatCheckTimer = setInterval(() => {
          this.getChatMessages();
        }, 15000);
        this.getChatMessages();
      }
    },
  },
};
</script>

<style>
@font-face {
  font-family: "Maler";
  src: url("/advent-2025/fonts/Maler.ttf");
}
* {
  padding: 0;
  margin: 0;
  font-family: sans-serif;
  font-weight: 400;
  line-height: normal;
}
body {
  /* background: url("https://storage.yandexcloud.net/yo-events/advent-2025/img/OTR/bck-otr.jpg"); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom 0px;
}
.page-wrap {
  max-width: 1440px;
  height: 100vh;
  box-sizing: border-box;
  padding: 20px 75px 32px 70px;
  margin: 0 auto;
  display: grid;
  grid-template-areas:
    "menu header"
    "menu content";
  grid-template-columns: 250px 1fr;
  grid-template-rows: 100px calc(100vh - 52px - 100px - 20px);
  gap: 20px;
}
header {
  grid-area: header;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 32px;
  background: url("https://storage.yandexcloud.net/yo-events/advent-2025/img/OTR/header-bck.png");
  background-repeat: no-repeat;
  background-size: 50%;
  background-position: top 0 right 25%;
  box-sizing: border-box;
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius);
}
.header__welcome {
  display: flex;
  gap: 20px;
  align-items: center;
}
.header__welcome--nav-btn {
  display: none;
  width: 36px;
  height: 36px;
  cursor: pointer;
}
.header__welcome img {
  width: 70px;
  height: 70px;
}
.header__welcome h2 {
  font-family: var(--font-header-hello);
  font-size: 30px;
  color: var(--color-primary);
  text-transform: uppercase;
}
.header__welcome h2 > span {
  font-family: var(--secondary-font);
  font-size: 30px;
  color: var(--color-secondary);
}
.header__controls {
  display: flex;
  gap: 30px;
  align-self: flex-end;
  margin-bottom: 20px;
}
.header__control {
  width: 20px;
  height: 20px;
  overflow: visible;
  position: relative;
  cursor: pointer;
}
.header__control > img {
  width: 20px;
  height: 20px;
}
.header__control > span {
  font: var(--main-font);
  font-size: 8px;
  color: #fff;
  background-color: #d54a38;
  width: 14px;
  height: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  position: absolute;
  top: -14px;
  right: -14px;
}
.header__control--menu {
  border-radius: var(--border-radius);
  z-index: 5000;
}
.header__control--menu--item {
  display: flex;
  align-items: center;
  padding: 10px;
  gap: 5px;
  width: 150px;
  background: var(--content-bck-secondary);
  cursor: pointer;
}
.header__control--menu--item:hover {
  background: var(--content-bck);
}
.header__control--menu--item > img {
  width: 20px;
  height: 20px;
}
.header__control--menu--item > span {
  font-family: var(--main-font);
  font-size: 14px;
  color: var(--color-text);
}
menu {
  grid-area: menu;
  display: block;
  border-radius: var(--border-radius);
  border: 1px solid var(--border-color);
  padding: 15px 20px 20px;
  box-sizing: border-box;
  position: relative;
  z-index: 200;
  overflow: visible;
}
.menu {
  overflow: hidden;
  overflow-y: auto;
}
.menu::-webkit-scrollbar {
  width: 5px;
}
.menu::-webkit-scrollbar-track {
  background-color: transparent;
}
.menu::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar-color);
  border-radius: 10px;
}
menu .menu__close {
  width: calc(100vw - 260px);
  height: 100%;
  position: absolute;
  top: 0;
  left: 249px;
  cursor: pointer;
  z-index: 600;
  display: none;
}
.menu__close--img {
  width: 24px;
  height: 24px;
  position: absolute;
  top: 5px;
  left: 5px;
}
.menu__logo {
  height: 60px;
  overflow: visible;
  margin-bottom: 30px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: var(--border-radius);
}
.menu {
  height: calc(100% - 60px - 30px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.menu__logo--hat {
  position: absolute;
  top: -10px;
  left: -10px;
}
.menu {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
}
.menu__items {
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-family: var(--main-font);
}
.menu__item {
  display: flex;
  gap: 10px;
  align-items: center;
  font-size: 15px;
  color: var(--menu-item-color);
  padding: 10px 15px;
  border-radius: var(--border-radius);
  cursor: pointer;
}
.menu__item.active {
  background-color: var(--menu-item-active-bck);
  color: var(--menu-item-active-color);
}
.menu__happy-new-year {
  width: 100%;
  height: 190px;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 10px;
}
.menu__happy-new-year--count {
  margin-top: 20px;
  padding-right: 17px;
  text-align: right;
  font-family: var(--secondary-font);
  font-size: 16px;
  text-transform: uppercase;
  color: var(--color-primary);
}
.menu__happy-new-year--counter {
  margin-top: 15px;
  padding: 0 20px 0 100px;
  text-align: center;
  font-family: var(--secondary-font);
  font-size: 26px;
  text-transform: uppercase;
  color: var(--color-secondary);
}
.content {
  grid-area: content;
  overflow: hidden;
  overflow-y: auto;
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius);
  background: var(--content-bck);
}
.content::-webkit-scrollbar {
  width: 5px;
}
.content::-webkit-scrollbar-track {
  background-color: transparent;
}
.content::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar-color);
  border-radius: 10px;
}
.page-main {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-areas:
    "calendar quests"
    "info info";
  grid-template-columns: 1fr 390px;
  grid-template-rows: 1fr max-content;
  gap: 20px;
  box-sizing: border-box;
  padding: 20px 30px;
  overflow: hidden;
}
.content__info {
  position: relative;
  overflow: visible;
  grid-area: info;
}
.content__info--support {
  position: absolute;
  top: -11px;
  right: -55px;
  cursor: pointer;
  width: 50px;
  height: 50px;
}
.content__info--support > img {
  width: 50px;
  height: 50px;
}
.content__info--items {
  display: flex;
  column-gap: 98px;
  row-gap: 8px;
  justify-content: center;
  flex-wrap: wrap;
  background-color: var(--content-bck);
  padding: 8px 30px;
  border-radius: var(--border-radius);
}
.content__info--item {
  font-family: "Golos Text", sans-serif;
  font-size: 12px;
  color: var(--color-text);
  display: flex;
  align-items: center;
  gap: 5px;
}
.content__info--item > img {
  width: 20px;
  height: 20px;
}
@media (max-width: 1040px) {
  .page-wrap {
    padding: 20px 5px 32px 5px;
    grid-template-areas:
      "header header"
      "content content";
    grid-template-columns: 390px 1fr;
    grid-template-rows: 100px calc(100vh - 52px - 100px - 20px);
    gap: 20px;
  }
  .header__welcome--nav-btn {
    display: block;
  }
  .header__welcome--nav-btn {
    width: 36px !important;
    height: 36px !important;
    cursor: pointer;
  }
  menu {
    grid-area: none;
    position: absolute;
    left: -300px;
    width: 250px;
    height: calc(100vh - 40px);
    background: var(--content-bck-secondary);
    transition: left ease-in 0.2s;
  }
  menu .menu__close {
    display: block;
    top: 5px;
    right: -30px;
  }
  .content__info--support {
    position: absolute;
    top: -11px;
    right: -30px;
  }
}
@media (max-width: 768px) {
  body {
    background-image: none !important;
    background-color: var(--content-bck-secondary);
  }
  .page-wrap {
    grid-template-columns: auto;
    grid-template-rows: auto;
    gap: 20px;
    padding: 20px 15px 32px 15px;
  }
  .content {
    border: none;
  }
  .page-main {
    grid-template-areas:
      "calendar calendar"
      "info info";
    grid-template-columns: auto;
    grid-template-rows: auto;
    gap: 20px;
  }
  .content__calendar {
    background: none;
    border: none;
  }
  .content__quests {
    display: none;
    grid-area: none;
  }
  .content__info--items {
    flex-direction: column;
    justify-content: flex-start;
  }
  .content__quests--mobile {
    display: block;
  }
  .content__calendar--prev {
    display: flex;
  }
  .header__welcome img.header__welcome--av {
    display: none;
  }
  header {
    padding: 10px 25px 10px 10px;
  }
  .header__welcome {
    gap: 5px;
  }
  .header__welcome h2 {
    font-size: 21px;
  }
  .header__welcome h2 > span {
    font-size: 21px;
  }
  .header__controls {
    margin-bottom: 5px;
  }
  .header__control {
    width: 24px;
    height: 24px;
  }
  .header__control > img {
    width: 24px;
    height: 24px;
  }
}
</style>
