<template>
  <div class="content__item">
    <v-img
      v-if="image"
      :src="image"
      :aspect-ratio="imageAspectRatio"
      :contain="imageMode === 'contain'"
    />
    <div v-if="title || text" class="mt-4">
      <h3 v-if="title">
        {{ title }}
      </h3>
      <template v-if="text">
        <p v-for="(str, i) in text" v-text="str" :key="i" />
      </template>
    </div>
    <component
      v-if="cmp != null"
      v-bind:is="cmp"
      :params="params"
      :item-id="itemId"
    />
  </div>
</template>

<script>
import Video from "./Video";
import Countdown from "./Countdown";
import Calendar from "./Calendar";
import QuestContent from "./Quest/Quest";
import QuestTasks from "./Quest/Tasks";
import QuestTask from "./Quest/Task";
import Chat from "./Chat/Index";
import Gallery from "./Gallery/Index";
import ImageGallery from "./ImageGallery/Index.vue";
import MemoryGame from "../Advent2025/Memory/Index.vue";
import MemoryTournamentGame from "../Advent2025/Memory/Tournament.vue";
import SnowFight from "../Advent2025/SnowFight/Index.vue";
import Faq from "./FAQ/Index.vue";
import get from "lodash/get";

export default {
  name: "AppContentItem",
  props: ["params", "type", "itemId", "mapping"],
  computed: {
    cmp() {
      if (this.mapping && this.mapping[this.type]) {
        return this.mapping[this.type];
      }
      switch (this.type) {
        case "video":
          return Video;
        case "countdown":
          return Countdown;
        case "calendar":
          return Calendar;
        case "quest":
          return QuestContent;
        case "quest-tasks":
          return QuestTasks;
        case "quest-task":
          return QuestTask;
        case "gallery":
          return Gallery;
        case "chat":
          return Chat;
        case "image-gallery":
          return ImageGallery;
        case "faq":
          return Faq;
        case "memory-2025":
          return MemoryGame;
        case "snowfight-2025-release":
          return SnowFight;
        case "memory-tournament-dev":
          return MemoryTournamentGame;
        case "memory-tournament-release":
          return MemoryTournamentGame;
        default:
          return null;
      }
    },
    image() {
      return get(this.params, "image") || null;
    },
    imageAspectRatio() {
      return get(this.params, "image-aspect-ratio") || null;
    },
    imageMode() {
      return get(this.params, "image-mode") || null;
    },
    title() {
      return get(this.params, "title") || null;
    },
    text() {
      const text = get(this.params, "text") || null;
      if (typeof text === "string") {
        return text.split("\n");
      }
      return null;
    },
  },
};
</script>

<style lang="scss">
.content__item {
  img {
    object-fit: contain;
    width: 100%;
  }
}
</style>

<style scoped>
h3 {
  font-family: var(--secondary-font);
  font-size: 20px;
  color: var(--quests-header-color); /* ПЕРЕНАЗВАТЬ */
  text-align: center;
  margin-bottom: 10px;
}
p {
  font-family: var(--main-font);
  font-size: 14px;
  line-height: 140%;
  color: var(--color-text);
  text-align: center;
}
</style>