<template>
  <div>
    <game
      v-if="player != null"
      :engine="engine"
      :ready="player.ready"
      :status="game.status"
      :store="player.store"
      :active="player.active"
      :score="player.score"
      :opponent-score="opponent.score"
      :opponent-name="opponent.user.name"
      :player-name="player.user.name"
      :remaining="remaining"
      @ready="onReady(player)"
      @start-ships-update="onStartShipsUpdate(player, $event)"
      @attack="onAttack"
      @close="onClose"
    />
  </div>
</template>

<script>
import Game from "./Game.vue";
import { Engine } from "./startEngine";
import _ from "lodash";

const initStore = () => {
  return {
    count: 0,
    startShips: [],
    gridItems: _.times(10, (x) => {
      return _.times(10, (y) => {
        return {
          x,
          y,
          played: false,
          index: x * 10 + y,
          miss: false,
          missAuto: false,
          hit: false,
          done: false,
          empty: false,
          ship: false,
          classes: ["b-grid-item--x-" + x, "b-grid-item--y-" + y],
        };
      });
    }),
    rivalGridItems: _.times(10, (x) => {
      return _.times(10, (y) => {
        return {
          x,
          y,
          played: false,
          index: x * 10 + y,
          miss: false,
          missAuto: false,
          hit: false,
          done: false,
          empty: false,
          ship: false,
          classes: ["b-grid-item--x-" + x, "b-grid-item--y-" + y],
        };
      });
    }),
  };
};

export default {
  name: "Aero2024AirBattleBattlefield",
  props: ["challenge", "user-id", "remaining"],
  components: {
    Game,
  },
  created() {},
  data() {
    return {
      engine: Engine(),
      game_: {
        status: "deployment",
        players: [
          {
            ready: false,
            active: false,
            score: 0,
            store: initStore(),
            engine: Engine(),
          },
          {
            ready: false,
            active: false,
            score: 0,
            store: initStore(),
            engine: Engine(),
          },
        ],
      },
    };
  },
  computed: {
    game() {
      return _.get(this.challenge, "state.game");
    },
    player() {
      return _.find(
        _.get(this.game, "players"),
        (item) => item.user.id === this.userId
      );
    },
    opponent() {
      return _.find(
        _.get(this.game, "players"),
        (item) => item.user.id !== this.userId
      );
    },
  },
  methods: {
    onReady() {
      if (this.game.status === "deployment") {
        const ships = this.engine.arShips.map((ship) => _.omit(ship, ["rect"]));
        const args = { command: "ready", params: { ships } };
        this.$emit("command", args);
      }
    },
    onClose() {
      this.$emit("command", { command: "close" });
    },
    onStartShipsUpdate(player, ships) {
      player.store.startShips = ships;
      player.store.gridItems = _.times(10, (x) => {
        return _.times(10, (y) => {
          return {
            x,
            y,
            played: false,
            index: x * 10 + y,
            miss: false,
            missAuto: false,
            hit: false,
            done: false,
            empty: false,
            ship: false,
            classes: ["b-grid-item--x-" + x, "b-grid-item--y-" + y],
          };
        });
      });
      for (const ship of ships) {
        const { long: length, vertical, x, y } = ship;
        if (vertical) {
          for (let i = y; i < y + length; i++) {
            player.store.gridItems[x][i].ship = true;
          }
        } else {
          for (let i = x; i < x + length; i++) {
            player.store.gridItems[i][y].ship = true;
          }
        }
      }
    },
    onAttack({ x, y }) {
      const args = { command: "attack", params: { x, y } };
      this.$emit("command", args);
    },
  },
};
</script>