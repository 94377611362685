import Vue from 'vue';
import VueRouter from 'vue-router';
import Event from '@/pages/Event';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: Event,
  },
  {
    path: '/advent-demo',
    name: 'advent-demo',
    component: () =>
      import(
        /* webpackChunkName: "advent-demo" */ '@/pages/advent-demo/Index.vue'
      ),
  },
  {
    path: '/sminex-2023',
    name: 'sminex-2023',
    meta: {
      eventId: 'sminex-2023'
    },
    component: () =>
      import(
        /* webpackChunkName: "sminex-2023" */ '@/pages/sminex-2023/Index.vue'
      ),
  },
  {
    path: '/sminex-2023-clone',
    name: 'sminex-2023-clone',
    meta: {
      eventId: 'sminex-2023-clone'
    },
    component: () =>
      import(
        /* webpackChunkName: "sminex-2023-clone" */ '@/pages/sminex-2023/Index.vue'
      ),
  },
  {
    path: '/schlumberger-2022',
    name: 'schlumberger-2022',
    component: () =>
      import(
        /* webpackChunkName: "schlumberger-2022" */ '@/pages/schlumberger-2022/Index.vue'
      ),
  },
  {
    path: '/glowbyte-no-limits',
    name: 'glowbyte-no-limits',
    component: () =>
      import(
        /* webpackChunkName: "glowbyte-no-limits" */ '@/pages/glowbyte-no-limits/Index.vue'
      ),
  },
  {
    path: '/bimeister-2022-04',
    name: 'bimeister-2022-04',
    component: () =>
      import(
        /* webpackChunkName: "bimeister-2022-04" */ '@/pages/bimeister-2022-04/Index.vue'
      ),
  },
  {
    path: '/merck-2023',
    name: 'merck-2023',
    component: () =>
      import(
        /* webpackChunkName: "merck-2023" */ '@/pages/merck-2023/Index.vue'
      ),
  },
  {
    path: '/kia-2020',
    name: 'kia-2020',
    component: () =>
      import(/* webpackChunkName: "kia-2020" */ '@/pages/kia-2020/Index.vue'),
  },
  {
    path: '/lokotech',
    name: 'lokotech',
    component: () =>
      import(/* webpackChunkName: "lokotech" */ '@/pages/lokotech/Index.vue'),
  },
  {
    path: '/roche',
    name: 'roche',
    component: () =>
      import(/* webpackChunkName: "roche" */ '@/pages/roche/Index.vue'),
  },
  {
    path: '/kontur',
    name: 'kontur',
    component: () =>
      import(/* webpackChunkName: "kontur" */ '@/pages/kontur/Index.vue'),
  },
  {
    path: '/cpw',
    name: 'cpw',
    component: () =>
      import(/* webpackChunkName: "cpw" */ '@/pages/cpw/Index.vue'),
  },
  {
    path: '/gsk',
    name: 'gsk',
    component: () =>
      import(/* webpackChunkName: "gsk" */ '@/pages/gsk/Index.vue'),
  },
  {
    path: '/glowbyte-2020',
    name: 'glowbyte-2020',
    component: () =>
      import(
        /* webpackChunkName: "glowbyte-2020" */ '@/pages/glowbyte-2020/Index.vue'
      ),
  },
  {
    path: '/glowbyte-2022',
    name: 'glowbyte-2022',
    component: () =>
      import(
        /* webpackChunkName: "glowbyte-2022" */ '@/pages/glowbyte-2022/Index.vue'
      ),
  },
  {
    path: '/glowbyte-2023',
    name: 'glowbyte-2023',
    component: () =>
      import(
        /* webpackChunkName: "glowbyte-2023" */ '@/pages/glowbyte-2023/Index.vue'
      ),
  },
  {
    path: '/glowbyte-2024',
    name: 'glowbyte-2024',
    meta: {
      isEventSupportPvp: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "glowbyte-2024" */ '@/pages/glowbyte-2024/Index.vue'
      ),
  },
  {
    path: '/glowbyte-2024-clone',
    name: 'glowbyte-2024-clone',
    meta: {
      isEventSupportPvp: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "glowbyte-2024-clone" */ '@/pages/glowbyte-2024-clone/Index.vue'
      ),
  },
  {
    path: '/otr-2022',
    name: 'otr-2022',
    component: () =>
      import(/* webpackChunkName: "otr-2022" */ '@/pages/otr-2022/Index.vue'),
  },
  {
    path: '/otr-2023',
    name: 'otr-2023',
    component: () =>
      import(/* webpackChunkName: "otr-2023" */ '@/pages/otr-2023/Index.vue'),
  },
  {
    path: '/otr-2024',
    name: 'otr-2024',
    component: () =>
      import(/* webpackChunkName: "otr-2024" */ '@/pages/otr-2024/Index.vue'),
  },
  {
    path: '/otr-2025',
    name: 'otr-2025',
    meta: {
      isEventSupportPvp: true,
    },
    component: () =>
      import(/* webpackChunkName: "otr-2024" */ '@/pages/otr-2025/Index.vue'),
  },
  {
    path: '/rtk-service-2025',
    name: 'rtk-service-2025',
    component: () =>
      import(/* webpackChunkName: "rtk-service-2025" */ '@/pages/rtk-service-2025/Index.vue'),
  },
  {
    path: '/glowbyte-2025',
    name: 'glowbyte-2025',
    meta: {
      isEventSupportPvp: true,
    },
    component: () =>
      import(/* webpackChunkName: "glowbyte-2025" */ '@/pages/glowbyte-2025/Index.vue'),
  },
  {
    path: '/aero-2024',
    name: 'aero-2024',
    meta: {
      isEventSupportPvp: true,
    },
    component: () =>
      import(/* webpackChunkName: "aero-2024" */ '@/pages/aero-2024/Index.vue'),
  },
  {
    path: '/alfa-2022',
    name: 'alfa-2022',
    component: () =>
      import(/* webpackChunkName: "alfa-2022" */ '@/pages/alfa-2022/Index.vue'),
  },
  {
    path: '/otr2022',
    name: 'otr2022',
    component: () =>
      import(/* webpackChunkName: "otr2022" */ '@/pages/otr-2022/Index.vue'),
  },
  {
    path: '/evraz-no-limits',
    name: 'evraz-no-limits',
    component: () =>
      import(
        /* webpackChunkName: 'evraz-no-limits' */ '@/pages/evraz-no-limits-2021-12-09/Index.vue'
      ),
  },
  {
    path: '/pro-nas',
    name: 'pronas-2021',
    component: () =>
      import(
        /* webpackChunkName: "pronas-2021" */ '@/pages/pronas-2021/Index.vue'
      ),
  },
  {
    path: '/partyon-mail.ru',
    name: 'partyon-mailru',
    component: () =>
      import(
        /* webpackChunkName: "partyon-mailru" */ '@/pages/partyon-mail.ru/Index.vue'
      ),
  },
  {
    path: '/3ds_picasso',
    name: '3ds_picasso',
    component: () =>
      import(
        /* webpackChunkName: "3ds_picasso" */ '@/pages/3ds_picasso/Index.vue'
      ),
  },
  {
    path: '/zinforo',
    name: 'zinforo',
    component: () =>
      import(/* webpackChunkName: "zinforo" */ '@/pages/zinforo/Index.vue'),
  },
  {
    path: '/superheroes',
    name: 'pfizer-superheroes-2021-august',
    component: () =>
      import(
        /* webpackChunkName: "pfizer-superheroes-2021-august" */ '@/pages/superheroes-pfizer-2021/Index.vue'
      ),
  },
  {
    path: '/fizer-mission-heart',
    name: 'fizer-mission-heart-2021',
    component: () =>
      import(
        /* webpackChunkName: "fizer-mission-heart-2021" */ '@/pages/fizer-mission-heart/Index.vue'
      ),
  },
  {
    path: '/pfizer-mission-heart',
    name: 'pfizer-mission-heart-2021',
    component: () =>
      import(
        /* webpackChunkName: "pfizer-mission-heart-2021" */ '@/pages/fizer-mission-heart/Index.vue'
      ),
  },
  {
    path: '/no-limits',
    name: 'no-limits',
    component: () =>
      import(/* webpackChunkName: "no-limits" */ '@/pages/no-limits/Index.vue'),
  },
  {
    path: '/dhl-no-limits',
    name: 'dhl-no-limits',
    component: () =>
      import(
        /* webpackChunkName: "dhl-no-limits" */ '@/pages/dhl-no-limits/Index.vue'
      ),
  },
  {
    path: '/experimental',
    name: 'experimental',
    component: () =>
      import(
        /* webpackChunkName: "experimental" */ '@/pages/experimental/Index.vue'
      ),
  },
  {
    path: '/expo',
    name: 'expo',
    component: () =>
      import(/* webpackChunkName: "expo" */ '@/pages/expo/Index.vue'),
  },
  {
    path: '/:id',
    name: 'event',
    component: () =>
      import(/* webpackChunkName: "event" */ '@/pages/Event.vue'),
  },
];

const router = new VueRouter({ mode: 'history', routes });

export default router;
