<template>
  <div class="page-memory">
    <div v-if="!isTournament">
      <h3>БИТВА СНЕЖКАМИ ⛄️</h3>
      <div>
        <p>🆕 А вот и новиночка - БИТВА СНЕЖКАМИ ⛄️</p>

        <p>
          😁 Самая зимняя забава, к тому же по известным всем правилам морского
          боя 🛳️
        </p>

        <p>
          ❄️ вызываете соперника из таблицы (пока любого)<br />
          ❄️ оппоненту прилетает прилашение на почту, с временем жизни 10
          минут<br />
          ❄️ рекомендуем отыскать напарника по внутрикорпоративным каналам для
          уверенности в битве<br />
          ❄️ после подтверждения поединка у вас 10 минут на расстановку своих
          новогодних укреплений<br />
          ❄️ жмем кнопку готовности и... понеслась!<br />
          ❄️ регламент игры прописан по в разделе ПРАВИЛА на экране<br />
          ❄️ веселимся, задуряемся, готовимся к турниру<br />
          ❄️ скоро стартанет и сам турнир, где будут жесткие правила по выбору
          соперника и ограниченные 10 игр<br />
          ❄️ ожидайте анонса Турнира в шапке Адвента<br />
        </p>

        <p>Ух, уже прилетел снежок. Пошли поднимать настроение... 🥳</p>

        <div class="page-memory__centered" @click="onOpenNewChallengeDialog">
          <img :src="memoryWelcomeImage" />
          <div class="page-memory__btn">Играть!</div>
        </div>
      </div>
    </div>
    <div v-else>
      <h3>⛄️БИТВА СНЕЖКАМИ НЕ НА ЖИЗНЬ, А ЗА БАЛЛЫ⛄️</h3>
      <div>
        <p>⚠️Основные принципы игры:</p>

        <p>
          уважение соперника 🤝<br />
          респект правилам игры ❤️<br />
          кристальность совести каждого 🤓<br />
        </p>

        <p>⚙️Механика турнира:</p>
        <p>
          ❄️ вызываете соперника из таблицы (только тех, кто с вами в диапазоне
          +/-30 баллов в табличке)
          <br />

          ❄️ оппоненту прилетает прилашение на почту, с временем жизни 10 минут
          <br />

          ❄️ рекомендуем отыскать напарника по внутрикорпоративным каналам для
          уверенности в битве
          <br />

          ❄️ после подтверждения поединка у вас 10 минут на расстановку своих
          новогодних укреплений
          <br />
          ❄️ жмем кнопку готовности и... понеслась!<br />
          ❄️ регламент игры прописан в разделе ПРАВИЛА на экране<br />

          ❄️ отправлять приглашение можно только одному свободному от игры
          участнику
          <br />

          ❄️ по итогам игры в турнирную таблицу записываем статус (победа или
          нет) и счет (20:16, к примеру)
          <br />
          ❄️ в турнире можно сыграть максимум 10 игр, помните про это<br />

          ❄️ сперва считаем количество побед, затем разницу в счете, затем -
          количество ваших пораженных клеточек
          <br />
          ❄️ баллы получат ТОП-15 (от 15 до 1 балла соотвественно)<br />
        </p>

        <p>
          ❗️Получите удовольствие от игры. О, наш двухпалубный зацепили...
          убежали отбрасываться снежинками!❤️
        </p>
        <div class="page-memory__centered" @click="onOpenNewChallengeDialog">
          <img :src="memoryWelcomeImage" />
          <div class="page-memory__btn">Играть!</div>
        </div>
      </div>
    </div>
    <v-dialog v-model="newChallengeDialog" :max-width="400">
      <v-card style="background-color: white !important">
        <v-card-title class="text-subtitle-1">
          Выберите вашего соперника
        </v-card-title>

        <v-card-text>
          <v-autocomplete
            :items="enabledPlayers"
            item-value="userId"
            item-text="name"
            label="Соперник"
            v-model="selectedPlayer"
          ></v-autocomplete>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="secondary" text @click="newChallengeDialog = false">
            Отмена
          </v-btn>

          <v-btn color="primary" text @click="onSendChallenge">
            Позвать на бой!
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import _ from "lodash";

export default {
  props: ["params", "type", "itemId"],
  data() {
    return {
      newChallengeDialog: false,
      players: [],
      selectedPlayer: null,
    };
  },
  created() {
    this.fetchUsers();
  },
  computed: {
    ...mapGetters({
      event: "event/event",
      user: "event/user",
    }),
    memoryWelcomeImage() {
      return _.get(this.event, "meta.memoryWelcomeImage");
    },
    isTournament() {
      return this.params.mode === "tournament";
    },
    userId() {
      return _.get(this.user, "id");
    },
    enabledPlayers() {
      return _.filter(
        this.players,
        (item) => item.userId !== this.userId && item.enabled === true
      );
    },
  },
  methods: {
    ...mapActions({
      runCommand: "event/runCommand",
      sendChallenge: "event/challenge/sendChallenge",
    }),
    async fetchUsers() {
      const itemId = this.itemId;
      const command = "players";
      const params = {};
      const payload = {
        itemId,
        command,
        params,
      };
      const { players } = await this.runCommand(payload);
      this.players = players;
    },
    onOpenNewChallengeDialog() {
      this.fetchUsers();
      this.selectedPlayer = null;
      this.newChallengeDialog = true;
    },
    async onSendChallenge() {
      const player = this.players.find(
        (item) => item.userId === this.selectedPlayer
      );
      if (player == null) return;

      const usr = {
        id: this.selectedPlayer,
        name: player.name,
        thumbnail: null,
        image: null,
      };

      await this.sendChallenge({
        selectedGameId: "air-battle",
        challengeTargetUser: usr,
      });
    },
  },
  watch: {},
};
</script>

<style lang="scss" scoped>
.page-memory {
  box-sizing: border-box;
  padding: 20px;
  overflow-y: auto;
  height: 100%;
  text-align: center;
  align-items: center;
  align-content: center;
}
.page-memory h3 {
  font-family: var(--secondary-font);
  font-size: 20px;
  color: var(--color-primary);
  text-align: center;
  margin-bottom: 10px;
}
.page-memory p {
  font-family: var(--main-font);
  font-size: 14px;
  line-height: 140%;
  color: var(--color-text);
  margin-bottom: 20px;
  text-align: left;
}
.page-memory__centered {
  margin: 30px auto 0;
  display: flex;
  flex-direction: column;
  gap: 30px;
  justify-content: center;
  align-items: center;
}
.page-memory__centered > img {
  width: 205px;
  height: 205px;
}
.page-memory__btn {
  padding: 10px;
  background-color: var(--color-primary);
  color: white;
  border-radius: 20px;
  cursor: pointer;
  width: 160px;
}
.page-memory__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}
.page-memory__header--status {
  font-family: var(--main-font);
  font-size: 14px;
  color: var(--color-text-memory);
}
.page-memory__header--status span {
  color: var(--color-primary);
}
main {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  max-width: 510px;
  margin: 0 auto;
  column-gap: 10px;
  row-gap: 10px;
}
button {
  margin-top: 20px;
  font-size: 0.8rem;
  padding: 8px 10px;
  text-transform: uppercase;
  background: #f2add2;
  border: none;
  color: white;
  border-radius: 4px;
}
@media (max-width: 640px) {
  main {
    grid-template-columns: repeat(4, 1fr);
  }
  .page-memory {
    padding: 0px;
  }
}
</style>