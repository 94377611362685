<template>
  <v-btn color="red" @click="onLikeClick" text fab small>
    <v-icon>{{ isLikedByUser ? "mdi-heart" : "mdi-heart-outline" }}</v-icon>
  </v-btn>
</template>

<script>
import likeableMixin from "@/mixins/likeable";

export default {
  name: "LikeImageButton",
  props: ["id", "likeable"],
  mixins: [likeableMixin],
  computed: {
    likeableObjectId() {
      return this.id;
    },
  },
  methods: {
    onLikeClick() {
      if (this.likeable) {
        this.isLikedByUser = !this.isLikedByUser;
      }
    },
  },
};
</script>