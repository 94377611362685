<template>
  <v-card style="background-color: white !important">
    <v-card-title
      >Техническая поддержка<v-spacer /><v-btn
        text
        small
        color="secondary"
        @click="$emit('close')"
      >
        <v-icon> mdi-close </v-icon>
      </v-btn></v-card-title
    >
    <v-card-text class="pb-0 mb-0">
      <div
        v-for="item of roomMessages"
        :key="`chat-message-${item.id}`"
        :style="{
          cursor:
            userId === item.author.id || item.room === 'tech-support'
              ? 'default'
              : 'pointer',
        }"
        :title="
          userId === item.author.id || item.room === 'tech-support'
            ? ''
            : 'Двойное нажатия для игнора'
        "
        @dblclick="onMessageDblClick(item)"
      >
        <strong>{{ userId === item.author.id ? "Я" : item.author.name }}</strong
        >: {{ item.data.text }}
      </div>
    </v-card-text>
    <v-card-actions class="pl-6 pr-6">
      <v-text-field
        placeholder="Введите ваше сообщение (до 300 символов)"
        @keyup="onKeyPress"
        dense
        :value="message"
        @input="onMessageChange"
      >
      </v-text-field>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import _ from "lodash";

export default {
  name: "ChatContent",
  props: ["params", "item-id"],
  data() {
    return {
      message: "",
    };
  },
  watch: {
    userId() {
      this.resetChat();
    },
    roomMessages() {},
  },
  methods: {
    ...mapActions({
      runCommand: "event/runCommand",
      banUser: "event/chat/banUser",
    }),
    ...mapMutations({
      appendMyMessage: "event/chat/appendMyMessage",
      resetChat: "event/chat/resetChat",
      setSelectedRoomIndex: "event/chat/setSelectedRoomIndex",
      setLastChatReadTimeStamp: "event/setLastChatReadTimeStamp",
    }),
    onKeyPress(event) {
      const { code } = event;
      if (code === "Enter") {
        this.sendMessage();
      }
    },
    onMessageChange(event) {
      if (event && event.length < 300) {
        this.message = event;
      } else if (typeof event === "string" && event) {
        this.message = event.substring(0, 300);
      }
    },
    async sendMessage() {
      if (this.message) {
        const message = {
          id: new Date().getTime(),
          author: {
            id: this.userId,
            me: true,
          },
          data: {
            text: this.message,
          },
          type: "text",
          room: this.selectedRoomId,
        };
        if (this.selectedRoomId === "tech-support") {
          this.appendMyMessage(message);
        }
        this.message = null;

        const { _id: itemId } = this.chatItem;
        const { text } = message.data;
        const command = "chat|sendMessage";
        const params = {
          message: text,
          chatId: this.selectedRoomId,
        };
        try {
          await this.runCommand({
            itemId,
            command,
            params,
          });
        } catch (err) {
          if (err && err.message) {
            alert(err.message);
          }
        }
      }
    },
    onMessageDblClick(item) {
      if (item.author.id !== this.userId && item.author.id !== "tech-support") {
        this.banUser(item.author.id);
      }
    },
  },

  beforeDestroy() {
    this.setLastChatReadTimeStamp();
  },

  computed: {
    ...mapGetters({
      chatItem: "event/chatItem",
      user: "event/user",
      bans: "event/chat/bannedUsers",
    }),
    ...mapGetters({
      rooms: "event/chat/rooms",
      selectedRoomIndex: "event/chat/selectedRoomIndex",
      messages: "event/chat/messages",
    }),
    roomMessages() {
      return _.filter(
        this.messages,
        (msg) =>
          msg.room === this.selectedRoomId && !this.bans.includes(msg.author.id)
      );
    },
    userId() {
      return this.user ? this.user.id : null;
    },
    selectedRoomId() {
      return this.selectedRoom && this.selectedRoom.id;
    },
    selectedRoom() {
      return this.rooms[this.selectedRoomIndex];
    },
  },
};
</script>
