<template>
  <div>
    <app-event event-id="glowbyte-2025" auth="login-only" :mapping="mapping">
      <template v-slot:logo>
        <div
          style="
            padding: 1px 4px 4px 0px;
            border-radius: 8px;
            background-color: transparent;
            margin: 16px;
          "
        >
          <v-img :src="require('./assets/logo.png')" class="ma-4" />
        </div>
      </template>
      <template v-slot:left-menu>
        <v-navigation-drawer
          v-if="true"
          app
          :value="drawer || !$vuetify.breakpoint.mobile"
          @input="setDrawer"
          dark
          class="primary"
          :style="{
            backgroundColor: `#6890C2 !important`,
            backdropFilter: 'blur(10px)',
          }"
        >
          <div class="d-flex flex-column justify-center align-center pl-4 pr-4">
            <v-img :src="require('./assets/logo.png')" class="ma-4" />
          </div>
          <v-list nav rounded color="transparent">
            <v-list-item-group :value="selectedItem">
              <v-list-item
                v-for="(item, index) in visibleItems"
                :key="index"
                @click="onItemSelect(item.id)"
              >
                <v-list-item-content>
                  <v-list-item-title
                    v-text="item.title"
                    class="d-flex justify-center align-center"
                    style="text-shadow: 1px 1px 2px black"
                  ></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item @click="onTgClick">
                <v-list-item-content>
                  <v-list-item-title
                    v-text="'Чат Адвента в Telegram'"
                    class="d-flex justify-center align-center"
                    style="text-shadow: 1px 1px 2px black"
                  ></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-navigation-drawer>
      </template>
    </app-event>
  </div>
</template>

<script>
import eventMenuMixin from "../../mixins/eventMenuMixin";
import themeMixin from "@/mixins/eventTheme";
import AppEvent from "@/components/event/Advent2025Event.vue";
import Quest from "./Quest";
import Shop from "../rtk-service-2025/Shop/Index.vue";
import { mapGetters, mapActions, mapState, mapMutations } from "vuex";
import _ from "lodash";

export default {
  name: "AppRTKService2025",
  components: {
    AppEvent,
  },
  mixins: [eventMenuMixin, themeMixin],
  data() {
    return {
      name: "",
      updating: false,
      mapping: {
        quest: Quest,
        shop: Shop,
      },
    };
  },
  created() {
    this.name = this.userName;

    const favicon = document.getElementById("favicon");
    favicon.type = "image/ico";
    favicon.href = "/otr-snowflake.ico";
  },
  watch: {
    userName() {
      this.name = this.userName;
    },
  },
  computed: {
    ...mapGetters({
      user: "event/user",
    }),
    ...mapState(["drawer"]),
    profile() {
      return _.get(this.user, "profile");
    },
    userName() {
      return _.get(this.profile, "name");
    },
  },
  methods: {
    ...mapActions({
      updateMyProfile: "event/updateMyProfile",
    }),
    ...mapMutations({
      setDrawer: "drawer",
    }),
    onTgClick() {
      window.open("https://t.me/+jxKw7LxKy-A4M2Zi", "_blank");
      // window.location.assign("https://t.me/+jxKw7LxKy-A4M2Zi");
    },
    async onSaveUserName() {
      const update = { name: this.name };
      this.updating = true;
      await this.updateMyProfile(update);
    },
  },
};
</script>

<style>
:root {
  --border-color: #ff6902;
  --border-radius: 2px;
  --main-font: Montserrat, sans-serif;
  --font-header-hello: "Maler", sans-serif;
  --secondary-font: "uni0553", sans-serif;
  --menu-item-color: #162e42;
  --menu-item-active-bck: #347d76;
  --menu-item-active-color: #fff;
  --color-primary: #347d76;
  --color-secondary: #ff6902;
  --color-text: #162e42;
  --days-bck: #fff;
  --scrollbar-color: #e6dbd4;
  --content-bck: #f3f3f3;
  --content-bck-secondary: #eef9fd;
  --color-text-memory: #015152;
}
</style>