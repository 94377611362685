<template>
  <div>
    <div
      class="content__calendar--day"
      :class="{ finished, disabled }"
      @click="clickable && $emit('click')"
      :style="{
        cursor: !clickable || disabled ? 'not-allowed' : 'pointer',
      }"
    >
      <div class="content__calendar--day--front">
        <img
          src="https://storage.yandexcloud.net/yo-events/advent-2025/img/OTR/icon-done.png"
          class="icon-done"
        />
        <div class="content__calendar--day--img-wrapper">
          <img :src="backgroundImage" />
        </div>
        <div
          class="content__calendar--day--stats"
          v-if="finished || points != null || icons.length > 0"
        >
          <span
            ><template v-if="finished || points != null"
              >Баллы: {{ points }}</template
            ></span
          >
          <div class="content__calendar--day--controls" v-if="icons.length > 0">
            <template v-for="(item, index) in icons">
              <v-tooltip
                bottom
                :key="`${index}-tooltip-${item.icon}`"
                color="deep-orange darken-4"
              >
                <template v-slot:activator="{ on, attrs }">
                  <img
                    :key="`icon-${item.icon}`"
                    :src="item.icon"
                    :title="item.label"
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <span>{{ item.label }}</span>
              </v-tooltip>
            </template>
          </div>
        </div>
      </div>
    </div>
    <div
      class="calendar-item"
      :class="classList"
      :style="styleList"
      @click="clickable && $emit('click')"
      v-if="false"
    >
      <div class="calendar-item__number" v-if="showNumber">
        {{ day }}
      </div>
    </div>
  </div>
</template>

<script>
import themeMixin from "@/mixins/eventTheme";
export default {
  name: "DayOfMonth",
  mixins: [themeMixin],
  props: {
    day: {
      type: [Number, String],
      default: null,
    },
    fileNameTemplate: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      default: false,
    },
    forceBackground: {
      type: Boolean,
      default: false,
    },
    clickable: {
      type: Boolean,
      default: true,
    },
    backgroundImage: {
      type: String,
      default: null,
    },
    showBorder: {
      type: Boolean,
      default: true,
    },
    showNumber: {
      type: Boolean,
      default: true,
    },
    points: {
      type: Number,
      default: null,
    },
    finished: {
      type: Boolean,
      default: false,
    },
    icons: {
      type: Array,
      default: null,
    },
  },
  computed: {
    classList() {
      return [
        this.active && "is-active",
        this.disabled && "is-disabled",
        !this.clickable && "is-not-clickable",
      ];
    },
    styleList() {
      if (this.backgroundImage) {
        return {
          backgroundImage: `url(${this.backgroundImage})`,
          border: this.showBorder ? "2px solid #fff" : null,
        };
      }

      const fileName = (this.fileNameTemplate || `december_#day#.png`).replace(
        "#day#",
        this.day
      );
      return {
        border: this.showBorder ? "2px solid #fff" : null,
        backgroundImage:
          this.day &&
          (!this.disabled || this.forceBackground) &&
          "url(" + require("../assets/images/days/" + fileName) + ")",
      };
    },
  },
};
</script>

<style scoped>
.content__calendar--day {
  width: 100%;
  border-radius: var(--border-radius);
  background-color: var(--days-bck);
  position: relative;
  overflow: visible;
  transition: all ease-in 0.2s;
}
.disabled {
  opacity: 0.25;
}
.content__calendar--day:hover {
  filter: saturate(120%);
  transform: scale(105%);
  transition: all ease-in 0.2s;
}
.content__calendar--day .icon-done {
  display: none;
}
.content__calendar--day.finished .icon-done {
  display: block;
  width: 20px;
  height: 20px;
  position: absolute;
  top: -5px;
  right: -6px;
  z-index: 2;
}
.content__calendar--day.today {
  box-shadow: 0px 0px 20px 0px var(--color-primary);
  transition: box-shadow ease-in 0.2s;
}
.content__calendar--day.today:hover {
  box-shadow: none;
  transition: box-shadow ease-in 0.2s;
}
.content__calendar--day--front {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: var(--border-radius);
}
.content__calendar--day--front {
  z-index: 100;
}
.content__calendar--day--img-wrapper > img {
  /* border-top-left-radius: var(--border-radius);
  border-top-right-radius: var(--border-radius); */
  border-radius: var(--border-radius);
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
}
.content__calendar--day--img-wrapper {
  position: relative;
}
.content__calendar--day--img-wrapper::after {
  content: "";
  display: block;
  padding-bottom: 97%;
}
.content__calendar--day--stats {
  display: flex;
  justify-content: space-between;
  gap: 4px;
  padding: 7px 5px 10px;
}
.content__calendar--day--stats > span {
  font-family: var(--main-font);
  color: var(--color-secondary);
  font-size: 10px;
  white-space: nowrap;
  overflow: hidden;
}
.content__calendar--day--controls {
  display: flex;
  gap: 4px;
}
.content__calendar--day--controls > img {
  width: 14px;
  height: 14px;
}
</style>

<style lang="scss">
@import "../assets/styles/_variables.scss";
.calendar-item {
  $this: &;
  position: relative;
  width: 100%;
  // border: 2px solid #fff;
  color: #fff;
  border-radius: 1px;
  background: center/calc(100% - 6px) no-repeat;
  text-shadow: 1px 1px 3px #292929;
  // background-color: #ff0000aa;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &.is-active {
    font-weight: 600;
    color: $color-main;
    border: 3px solid $color-main;
  }
  &.is-disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }
  &.is-not-clickable {
    cursor: not-allowed;
  }

  &::before {
    content: "";
    padding-bottom: 100%;
    display: flex;
  }

  &__number {
    position: absolute;
    left: 6px;
    top: 0px;
    font-size: 18px;
  }
  &:hover {
    transform: scale(1.1);
  }
}
</style>
