<template>
  <div>
    <div class="page-snowbattle" v-if="!(status === 'deployment' && !ready)">
      <h3>БИТВА СНЕЖКАМИ ⛄️ c {{ opponentName }}</h3>
      <div class="page-snowbattle__wrap">
        <div class="page-snowbattle__bfwrap">
          <div class="page-snowbattle__bf--profile">
            <div
              class="page-snowbattle__bf--username"
              onclick="document.querySelector('div.page-snowbattle__youwin').style.display = 'flex'"
            >
              Вы
            </div>
            <div class="page-snowbattle__bf--score">Баллы: {{ score }}</div>
          </div>
          <b-my-bf
            :engine="engine"
            :store="store"
            :lost="status === 'done' && score < opponentScore"
            v-if="
              status === 'in-progress' ||
              (status === 'deployment' && ready === true) ||
              status === 'done'
            "
          ></b-my-bf>
          <!-- Блок page-snowbattle__youwin нужно перенести во vuetify всплывашку -->
          <div class="page-snowbattle__youwin">
            <h5>Вы выиграли</h5>
            <div>Счёт: 40/17</div>
          </div>
        </div>
        <template v-if="status === 'in-progress'">
          <div
            class="page-snowbattle__bf--status"
            :class="{
              'turn-opp': !active,
            }"
          >
            <span>{{ remaining }}</span>
            <img
              src="https://storage.yandexcloud.net/yo-events/advent-2025/img/snow-battle-arrow.png"
            />
            <h6>{{ active ? "Ваш ход" : "Ход соперника" }}</h6>
          </div>
        </template>

        <div
          v-if="status === 'deployment' && ready"
          class="page-snowbattle__bf--status"
        >
          <span>{{ remaining }}</span>
          <img
            src="https://storage.yandexcloud.net/yo-events/advent-2025/img/snow-battle-arrow.png"
          />
          <h6>Ожидаем соперника</h6>
        </div>

        <div
          v-if="status === 'done'"
          class="page-snowbattle__bf--status"
          :class="{
            'turn-opp': score > opponentScore,
          }"
        >
          <img
            src="https://storage.yandexcloud.net/yo-events/advent-2025/img/snow-battle-arrow.png"
          />
          <h6>{{ score > opponentScore ? "Вы победили" : "Вы проиграли" }}</h6>
          <div
            @click="$emit('close')"
            style="
              border: none;
              outline: none;
              box-sizing: border-box;
              padding: 10px 20px;
              max-width: 160px;
              background: #276a5e;
              font-family: var(--main-font);
              font-size: 12px;
              text-transform: uppercase;
              color: #fff;
              border-radius: 20px;
              cursor: pointer;
            "
          >
            Закрыть
          </div>
        </div>

        <div class="page-snowbattle__bfwrap" v-if="status === 'in-progress'">
          <div class="page-snowbattle__bf--profile">
            <div
              class="page-snowbattle__bf--username"
              onclick="document.querySelector('div.page-snowbattle__youwin').style.display = 'flex'"
            >
              Соперник
            </div>
            <div class="page-snowbattle__bf--score">
              Баллы: {{ opponentScore }}
            </div>
          </div>

          <b-rival-bf
            v-if="status === 'in-progress' || status === 'done'"
            :engine="engine"
            :store="store"
            :lost="status === 'done' && opponentScore < score"
            @attack="onAttack"
          />
        </div>

        <div
          class="page-snowbattle__bfwrap"
          v-if="(status === 'deployment' && ready) || status === 'done'"
        >
          <div class="page-snowbattle__bf--profile">
            <div
              class="page-snowbattle__bf--username"
              onclick="document.querySelector('div.page-snowbattle__youwin').style.display = 'flex'"
            >
              Соперник
            </div>
            <div class="page-snowbattle__bf--score">
              Баллы: {{ opponentScore }}
            </div>
          </div>

          <b-rival-bf
            :engine="engine"
            :store="store"
            :lost="status === 'done' && opponentScore < score"
            @attack="onAttack"
          />
        </div>
      </div>
    </div>
    <div class="page-snowbattle" v-if="status === 'deployment' && !ready">
      <h3>Снежный бой c {{ opponentName }}</h3>
      <div class="page-snowbattle__wrap">
        <div class="page-snowbattle__bfwrap">
          <div class="page-snowbattle__bf--profile">
            <div
              class="page-snowbattle__bf--username"
              onclick="document.querySelector('div.page-snowbattle__youwin').style.display = 'flex'"
            >
              Вы
            </div>
            <div class="page-snowbattle__bf--score">
              Расставьте ваше игровое поле
            </div>
          </div>

          <b-start-bf
            :engine="engine"
            :store="store"
            v-if="status === 'deployment' && ready === false"
            @start-ships-update="onStartShipsUpdate"
          ></b-start-bf>

          <v-btn v-on:click="startGame" color="primary"
            >{{ remaining ? `${remaining} :` : "" }} Я готов
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StartingBattlefield from "./StartingBattlefield.vue";
import Battlefield from "./Battlefield.vue";
import RivalBattlefield from "./RivalBattlefield.vue";

export default {
  name: "Game",
  props: [
    "engine",
    "status",
    "ready",
    "store",
    "active",
    "score",
    "opponent-score",
    "opponent-name",
    "player-name",
    "remaining",
  ],
  data() {
    return {
      msg: "Воздушный бой.",
      showStartBattlefield: true,
      showMyBattlefield: false,
    };
  },
  methods: {
    startGame() {
      this.$emit("ready");
    },
    onStartShipsUpdate(ships) {
      this.$emit("start-ships-update", ships);
    },
    onAttack({ x, y }) {
      this.$emit("attack", { x, y });
    },
  },
  components: {
    "b-start-bf": StartingBattlefield,
    "b-rival-bf": RivalBattlefield,
    "b-my-bf": Battlefield,
  },
  mounted() {
    window.addEventListener("click", () => {
      this.$root.$emit("hideDrop");
    });
  },
};
</script>

<style scoped>
.bf-await {
  background: url(./assets/ab-bf-enemy.png) no-repeat;
  background-size: cover;
}
</style>

<style>
.page-snowbattle {
  padding: 14px;
}
.page-snowbattle h3 {
  font-family: var(--secondary-font);
  font-size: 20px;
  color: var(--quests-header-color);
  margin-bottom: 50px;
  text-align: center;
}
.page-snowbattle__wrap {
  margin: 0 auto;
  display: flex;
  gap: 45px;
}
.page-snowbattle__bf {
  display: grid;
  grid-template-columns: repeat(11, 30px);
  grid-template-rows: repeat(11, 30px);
  grid-template-areas:
    "empty letter letter letter letter letter letter letter letter letter letter"
    "number battle battle battle battle battle battle battle battle battle battle"
    "number battle battle battle battle battle battle battle battle battle battle"
    "number battle battle battle battle battle battle battle battle battle battle"
    "number battle battle battle battle battle battle battle battle battle battle"
    "number battle battle battle battle battle battle battle battle battle battle"
    "number battle battle battle battle battle battle battle battle battle battle"
    "number battle battle battle battle battle battle battle battle battle battle"
    "number battle battle battle battle battle battle battle battle battle battle"
    "number battle battle battle battle battle battle battle battle battle battle"
    "number battle battle battle battle battle battle battle battle battle battle";
}
.page-snowbattle__bf--letters {
  grid-area: letter;
  width: 100%;
  display: flex;
  justify-content: space-around;
  font-family: var(--main-font);
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
  color: var(--color-text);
}
.page-snowbattle__bf--letters > span {
  display: flex;
  align-items: center;
  justify-content: center;
}
.page-snowbattle__bf--numbers {
  grid-area: number;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  font-family: var(--main-font);
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
  color: var(--color-text);
}
.page-snowbattle__bf--grid {
  grid-area: battle;
  z-index: 10;
  outline: 1px solid #91afa1;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: repeat(10, 30px);
  grid-template-rows: repeat(10, 30px);
  background: url("https://storage.yandexcloud.net/yo-events/advent-2025/img/snow-battle-bck.png")
    no-repeat;
  background-size: contain;
  background-position: center center;
}
.page-snowbattle__bf--grid--item {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border: 1px solid #91afa1;
  background-color: rgba(255, 237, 207, 0.4);
}
.page-snowbattle__bf--ships {
  grid-area: battle;
  z-index: 20;
  display: grid;
  grid-template-columns: repeat(10, 30px);
  grid-template-rows: repeat(10, 30px);
  border: none;
}
.page-snowbattle__bf--ships--item {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border: 1px solid #feb04f;
  outline: 1px solid #feb04f;
  background-color: #ffedcf;
}
.page-snowbattle__bf--ships--item > img {
  width: 100%;
  height: auto;
}
.page-snowbattle__bf--ships--item.missed {
  outline: none;
  border: none;
  background: none;
}
.page-snowbattle__bf--ships--item.destroyed {
  border: 1px solid #b20303;
  outline: 1px solid #b20303;
  z-index: 50;
}
.page-snowbattle__info {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-top: 30px;
}
.page-snowbattle__text {
  font-family: var(--main-font);
  font-size: 14px;
  color: var(--color-text);
}
.page-snowbattle__info button {
  border: none;
  outline: none;
  box-sizing: border-box;
  padding: 10px;
  max-width: 160px;
  background: #276a5e;
  font-family: var(--main-font);
  font-size: 12px;
  text-transform: uppercase;
  color: #fff;
  border-radius: 20px;
  cursor: pointer;
}
.page-snowbattle__wrap {
  margin: 0 auto;
  display: flex;
  gap: 45px;
  justify-content: center;
}
.page-snowbattle__bfwrap {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.page-snowbattle__bf--profile {
  padding-left: 15px;
  display: flex;
  justify-content: space-between;
  font-family: var(--main-font);
  font-size: 14px;
  font-weight: 500;
  color: var(--color-text);
}
.page-snowbattle__bf--score {
  color: var(--color-primary);
}
.page-snowbattle__bf--status {
  width: 95px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
}
.page-snowbattle__bf--status > span {
  font-family: var(--main-font);
  font-size: 14px;
  font-weight: 500;
  color: var(--color-text);
}
.page-snowbattle__bf--status > img {
  width: 60px;
  height: 30px;
  transition: transform ease-in 0.5s;
}
.page-snowbattle__bf--status.turn-opp > img {
  transform: rotateY(180deg);
  transition: transform ease-in 0.5s;
}
.page-snowbattle__bf--status > h6 {
  font-family: var(--main-font);
  font-size: 14px;
  font-weight: 500;
  color: var(--color-text);
  text-transform: uppercase;
  text-align: center;
}
.page-snowbattle__youwin,
.page-snowbattle__youlost {
  padding: 20px;
  width: auto;
  background: var(--content-bck);
  border-radius: var(--border-radius, 10px);
  display: none;
  flex-direction: column;
  gap: 10px;
  align-items: center;
}
.page-snowbattle__youwin h5,
.page-snowbattle__youlost h5 {
  font-family: var(--secondary-font);
  font-size: 20px;
  line-height: 30px;
  color: var(--quests-header-color);
  text-align: center;
}
.page-snowbattle__youlost h5 {
  color: #d54a38; /* ЗАДАТЬ ПЕРЕМЕННУЮ */
}
.page-snowbattle__youwin > div,
.page-snowbattle__youlost > div {
  font-family: var(--main-font);
  font-size: 14px;
  font-weight: 500;
  color: var(--color-text);
}
@media (max-width: 600px) {
  .page-snowbattle__wrap {
    flex-direction: column-reverse;
    gap: 20px;
  }
}
@media (max-width: 1280px) {
  .page-snowbattle__wrap {
    gap: 20px;
    flex-direction: column;
    align-items: center;
  }
  .page-snowbattle__bf--status > img {
    transform: rotateZ(90deg);
  }
  .page-snowbattle__bf--status.turn-opp > img {
    transform: rotateZ(270deg);
  }
}
</style>