<template>
  <div>
    <b-markers></b-markers>

    <div
      id="start-battlefield"
      ref="el"
      class="b-canvas-wrapper b-svg-grid"
    ></div>
  </div>
</template>

<script>
import Markers from "./Markers";
import { Engine } from "./startEngine";
// import Store from "./store";

export default {
  name: "StartingBattlefield",
  props: ["engine", "store"],
  data() {
    return {};
  },
  mounted: function () {
    this.engine.start(this.$refs.el);
    this.$emit("start-ships-update", Engine.arShips);
    // store.startShips = Engine.arShips;
  },
  methods: {
    check: function () {},
  },
  components: {
    "b-markers": Markers,
  },
};
</script>