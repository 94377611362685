<template>
  <v-app
    v-if="event"
    style="
      background-color: transparent;
      height: 100vh;
      overflow-y: scroll;
      background-position: center;
      background-size: cover;
      background-attachment: fixed;
    "
    :style="{
      backgroundImage: `url(${bgImage})`,
    }"
  >
    <app-nav-bar :auth="auth" :alarm-remaining="activeAlarmRemainingStr">
      <template v-slot:nav-right><slot name="nav-right"></slot></template>
      <!-- <template v-slot:title><slot name="title"></slot></template> -->
      <template v-slot:title><slot name="title"></slot></template>
    </app-nav-bar>
    <slot name="left-menu">
      <v-navigation-drawer
        app
        :value="drawer || !$vuetify.breakpoint.mobile"
        @input="setDrawer"
        dark
        class="primary"
        :style="{
          ...(theme.primary
            ? {
                backgroundColor: `${theme.primary}ee !important`,
                backdropFilter: 'blur(10px)',
              }
            : {}),
        }"
      >
        <event-menu dark>
          <template v-slot:logo>
            <slot name="logo">
              <v-img
                v-if="theme && theme.logo"
                :src="theme.logo"
                class="ma-4"
              />
              <h4
                style="font-weight: 300; color: white"
                class="d-flex justify-center mb-4 mt-4"
                v-else
              >
                {{ title }}
              </h4>
            </slot>
          </template>
          <template v-slot:drawer-bottom
            ><slot name="drawer-bottom"></slot
          ></template>
        </event-menu>
      </v-navigation-drawer>
    </slot>
    <v-main dark>
      <v-container>
        <v-row>
          <v-col
            :cols="12"
            class="pa-4"
            xl="10"
            offset-xl="1"
            lg="12"
            offset-lg="0"
          >
            <event-content
              v-if="activeItem"
              :content="activeItem.content"
              :layout="activeItem.layout"
              :mapping="mapping"
            />
          </v-col>
        </v-row>
      </v-container>
      <v-btn
        fab
        :color="isNewMessage && !chatDialog ? 'accent' : 'info'"
        @click="chatDialog = true"
        style="position: fixed; bottom: 20px; right: 20px"
        v-if="chatEnabled"
      >
        <v-icon>mdi-chat</v-icon>
      </v-btn>
      <v-dialog
        v-if="chatEnabled"
        v-model="chatDialog"
        :max-width="500"
        :fullscreen="$vuetify.breakpoint.mobile"
      >
        <chat v-if="chatEnabled && chatDialog" @close="chatDialog = false">
        </chat>
      </v-dialog>
      <v-dialog
        v-model="alertDialog"
        :fullscreen="$vuetify.breakpoint.mobile"
        :max-width="$vuetify.breakpoint.mobile ? null : 400"
      >
        <v-card>
          <v-card-title v-if="alertTitle">
            {{ alertTitle }}
          </v-card-title>
          <v-card-text v-if="alertMessage">
            {{ alertMessage }}
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="alertDialog = false">
              OK
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        :value="!user && invitationDialog"
        :fullscreen="$vuetify.breakpoint.mobile"
        :max-width="$vuetify.breakpoint.mobile ? null : 400"
        persistent
      >
        <v-card>
          <v-card-title>Добро пожаловать!</v-card-title>
          <v-card-text>Представьтесь, пожалуйста:</v-card-text>
          <v-card-text>
            <template v-for="field of invitationFields">
              <v-select
                v-if="field.type === 'options'"
                v-show="field.options.length > 1"
                :items="field.options"
                item-value="value"
                item-text="label"
                :label="field.label"
                :key="`invitation-select-${field.id}`"
                v-model="invitationFormData[field.id]"
              ></v-select>
              <v-text-field
                v-else
                v-model="invitationFormData[field.id]"
                :label="field.label"
                :key="`invitation-text-field-${field.id}`"
              />
            </template>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              text
              color="primary"
              :disabled="!isInvitationFormValid"
              @click="validationFormSubmit"
              >Отправить</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        :value="!user && invitationWrongDialog"
        :fullscreen="$vuetify.breakpoint.mobile"
        :max-width="$vuetify.breakpoint.mobile ? null : 400"
        persistent
      >
        <v-card>
          <v-card-title>Это закрытая вечеринка!</v-card-title>
          <v-card-text>Обратитесь к организатору за доступом.</v-card-text>
        </v-card>
      </v-dialog>
    </v-main>
    <v-dialog
      :value="challengeDialog"
      :max-width="600"
      @input="onChangeChallengeDialog"
    >
      <v-card v-if="challengeTargetUser != null">
        <v-card-title> Отправка вызова участнику </v-card-title>
        <v-card-text>{{ challengeTargetUser.name }} </v-card-text>
        <v-card-text>
          <v-select
            full-width
            label="Выберите игру"
            item-text="title"
            item-value="id"
            :value="selectedChallengeGameId"
            @input="onChallengeGameChange"
            :items="supportedChallengeGames"
          />
        </v-card-text>
        <v-card-actions class="pb-4">
          <v-btn color="secondary" text @click="closeChallengeDialog">
            Закрыть
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="onSendChallenge"
            >Отправить вызов</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import Chat from "@/components/event/Content/Chat/Index.vue";
import eventMixin from "@/mixins/eventMixin.js";
import themeMixin from "@/mixins/eventTheme";
import { mapActions, mapGetters, mapMutations } from "vuex";
import _ from "lodash";

// const Peer = function () {};

export default {
  name: "AppEvent",
  mixins: [eventMixin, themeMixin],
  data() {
    return {
      chatDialog: false,
      socket: null,
      isMounted: false,
    };
  },
  components: {
    Chat,
  },
  computed: {
    ...mapGetters({
      chatRooms: "event/chat/rooms",
      activeChallenge: "event/challenge/activeChallenge",
      challengeDialog: "event/challenge/challengeDialog",
      challengeTargetUser: "event/challenge/challengeTargetUser",
      supportedChallengeGames: "event/challenge/supportedGames",
      selectedChallengeGameId: "event/challenge/selectedGameId",
    }),
    chatShouldBePresented() {
      return this.chatEnabled && !!this.userId;
    },
    roomId() {
      return this.eventId;
    },
    userName() {
      return _.get(this.user, "profile.name");
    },
  },
  methods: {
    ...mapMutations({
      closeChallengeDialog: "event/challenge/closeChallengeDialog",
      setSelectedGameId: "event/challenge/setSelectedGameId",
    }),
    ...mapActions({
      sendChallenge: "event/challenge/sendChallenge",
    }),
    onSendChallenge() {
      this.sendChallenge();
    },
    initChat() {},
    disableChat() {},
    onChangeChallengeDialog() {
      this.closeChallengeDialog();
    },
    onChallengeGameChange(value) {
      console.log("onChallengeGameChange", value);
      this.setSelectedGameId(value);
    },
  },
  beforeDestroy() {
    if (this.socket) {
      this.socket.close();
      this.socket = null;
    }
  },
  mounted() {
    this.isMounted = true;
    if (this.chatShouldBePresented) {
      this.$nextTick(() => {
        this.initChat();
      });
    }
  },
};
</script>
