<template>
  <div>
    <div class="page-tournament">
      <h3>Турнирная таблица</h3>
      <p>Игроки с равным количеством баллов отсортированы по алфавиту</p>
      <table class="advent-table">
        <thead>
          <tr>
            <th>
              <span class="advent-table__desktop">Место</span
              ><span class="advent-table__mobile">№</span>
            </th>
            <th><span>Игрок</span></th>
            <th>
              <span class="advent-table__desktop"></span
              ><img
                src="https://storage.yandexcloud.net/yo-events/advent-2025/img/OTR/icon-reward.png"
                class="advent-table__mobile"
              />
            </th>
            <th>
              <span class="advent-table__desktop">Регион</span>
            </th>
            <th>
              <span class="advent-table__desktop">Всего баллов</span
              ><img
                src="https://storage.yandexcloud.net/yo-events/advent-2025/img/OTR/icon-points-total.png"
                class="advent-table__mobile"
              />
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, index) in displayItems"
            :key="item.id"
            style="cursor: pointer"
            @click="onUserProfileClick(item)"
          >
            <td>
              <span>{{ index + 1 }}</span>
            </td>
            <td>
              <div class="advent-table__user">
                <v-avatar color="#38b6b4" size="45">
                  <v-img
                    v-if="item.thumbnail"
                    :src="item.thumbnail"
                    :alt="item.name"
                  />
                  <span v-else class="white--text text-h6">{{
                    item.initials
                  }}</span>
                </v-avatar>
                <span>{{ item.name }}</span>
              </div>
            </td>
            <td style="font-size: 20px; font-family: tahoma">
              <template v-for="(item, index) in item.achievements">
                <v-tooltip
                  bottom
                  :key="`${item.userId}-achievements-${index}-tooltip`"
                  color="deep-orange darken-4"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <span
                      v-bind="attrs"
                      class="mr-1"
                      v-on="on"
                      style="font-size: 20px"
                      >{{ item.symbol }}</span
                    >
                  </template>
                  {{ item.label }}
                </v-tooltip>
              </template>
            </td>
            <td class="text-left">
              {{ item.region }}
            </td>
            <td class="text-center">
              {{ item.points }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <v-dialog
      v-model="userDialog"
      :fullscreen="$vuetify.breakpoint.mobile"
      :max-width="$vuetify.breakpoint.mobile ? null : 800"
    >
      <user-profile :user="selectedUser" @close="userDialog = false" />
      <v-card v-if="false && selectedUser">
        <v-img v-if="selectedUser.image" :src="selectedUser.image"></v-img>
        <v-card-title>
          {{ selectedUser.name }}
        </v-card-title>
        <v-card-text>
          <div>Набранно баллов: {{ selectedUser.points }}</div>
          <div v-if="selectedUser.about" class="mt-2">
            О себе:
            <br />
            {{ selectedUser.about }}
          </div>
          <div v-if="selectedUser.birthCity" class="mt-2">
            Город рождения:
            <br />
            {{ selectedUser.birthCity }}
          </div>
          <div v-if="selectedUser.interestingFact" class="mt-2">
            Интересный факт:
            <br />
            {{ selectedUser.interestingFact }}
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="userDialog = false">
            Закрыть
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import _ from "lodash";
import { mapGetters } from "vuex";
import UserProfile from "./UserPublicProfile.vue";

const getInitials = (name) => {
  if (typeof name !== "string") {
    return null;
  }
  if (name === "") return "";
  return name
    .split(" ")
    .filter((item) => item !== "")
    .map((item) => `${item[0]}`.toUpperCase())
    .reduce((acc, item) => {
      if (acc.length >= 2) return acc;
      return `${acc}${item}`;
    }, "");
};

export default {
  name: "Advent2023Standings",
  props: ["items", "my-points", "my-game-id", "params"],
  components: {
    UserProfile,
  },
  computed: {
    ...mapGetters({
      meta: "event/eventMeta",
    }),
    itemsWithName() {
      return _.filter(this.items, (item) => item);
      // return _.filter(this.items, (item) => !!item.name).map((item) => {
      //   return {
      //     ...item,
      //     points: item.points + (item.image ? 7 : 0),
      //   };
      // });
    },
    sortedItems() {
      return _.orderBy(
        this.itemsWithName,
        ["points", "name", "user"],
        ["desc", "asc", "asc"]
      );
    },
    displayItems() {
      return this.sortedItems.map((item, index) => {
        return {
          ...item,
          index: index + 1,
          initials: getInitials(item.name),
          achievements: this.achievementsByUserId[item.userId] || [],
        };
      });
    },
    highlight() {
      return _.get(this.params, "standings.highlight") || 0;
    },
    metaAchievements() {
      const obj = _.get(this.meta, "achievements");

      return _.isArray(obj) ? obj : [];
    },
    achievementsByUserId() {
      return this.metaAchievements.reduce((acc, item) => {
        const [name, id, symbol, label] = item;
        acc[id] = acc[id] || [];
        acc[id].push({ symbol, label, name });
        return acc;
      }, {});
    },
  },
  data() {
    return {
      selectedUser: null,
      userDialog: false,
    };
  },
  methods: {
    onUserProfileClick(user) {
      this.selectedUser = user;
      this.userDialog = true;
    },
  },
};
</script>

<style scoped>
.page-tournament {
  box-sizing: border-box;
  padding: 30px 115px;
  overflow-y: scroll;
  background: rgba(255, 252, 247, 0.7);
  height: 100%;
}
.page-tournament h3 {
  font-family: var(--secondary-font);
  font-size: 20px;
  color: var(--quests-header-color); /* ПЕРЕНАЗВАТЬ */
  text-align: center;
  margin-bottom: 10px;
}
.page-tournament p {
  font-family: var(--main-font);
  font-size: 14px;
  line-height: 140%;
  color: var(--color-text);
  margin-bottom: 20px;
  text-align: center;
}
.page-tournament::-webkit-scrollbar {
  width: 5px;
}
.page-tournament::-webkit-scrollbar-track {
  background-color: transparent;
}
.page-tournament::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar-color);
  border-radius: 10px;
}
.advent-table {
  border-collapse: collapse;
  width: 100%;
}
.advent-table tr td,
.advent-table tr th {
  outline: 0;
  padding: 10px 5px;
  vertical-align: middle;
  text-align: center;
  height: 34px;
  border-top: 1px solid var(--color-primary);
  border-bottom: 1px solid var(--color-primary);
  font-family: var(--main-font);
  font-size: 14px;
  font-weight: 500;
  color: var(--color-text);
}
.advent-table tr td span,
.advent-table tr th span {
  font-family: var(--main-font);
  font-size: 14px;
  font-weight: 500;
  color: var(--color-text);
}
.advent-table tr th {
  height: 34px;
}
.advent-table tbody tr td {
  height: 30px;
}
.advent-table tbody tr:nth-child(odd) {
  background: var(--days-bck); /* ПЕРЕНАЗВАТЬ */
}
.advent-table tbody tr:nth-child(even) {
  background: #fff;
}
.advent-table__user {
  display: flex;
  gap: 10px;
  align-items: center;
}
.advent-table__user img {
  width: 30px;
  height: 30px;
  border-radius: 30px;
}
.advent-table__user span {
  font-weight: 500;
}
.advent-table tr:nth-child(-n + 5) td span {
  color: var(--menu-item-active-color); /* ПЕРЕНАЗВАТЬ */
}
.advent-table tr:nth-child(-n + 5) td:nth-child(1) span {
  color: var(--color-primary);
}
.advent-table__icon--reward {
  width: 20px;
  height: 20px;
}
.advent-table__mobile {
  display: none;
}
img.advent-table__mobile {
  width: 14px;
  height: 14px;
}
@media (max-width: 768px) {
  .advent-table__mobile {
    display: inline-block;
  }
  .advent-table__desktop {
    display: none;
  }
  .page-tournament {
    padding: 0;
    background: none;
  }
}
</style>