<template>
  <div>
    <calendar
      :min="params.min"
      :max="params.max"
      :dates="dates"
      :clickable-dates="dates"
      @select-date="onDateSelect"
    />
    <v-dialog
      v-if="selectedForm"
      v-model="formDialog"
      :fullscreen="$vuetify.breakpoint.mobile"
      :max-width="$vuetify.breakpoint.mobile ? null : 600"
    >
      <div
        class="secondary darken-2"
        :class="{ 'd-flex': !$vuetify.breakpoint.mobile }"
      >
        <v-card>
          <video-player v-if="selectedForm.video" v-bind="selectedForm.video" />
          <v-img
            :src="selectedForm.image"
            v-else-if="selectedForm.image"
            class="primary"
          />
          <v-card-title class="headline" v-if="selectedForm.title">
            {{ selectedForm.title }}
          </v-card-title>
          <v-card-text v-if="selectedForm.description">
            <div
              v-html="selectedForm.description.replace(/\n/gim, '<br/>')"
            ></div>
          </v-card-text>
          <v-fade-transition>
            <v-card-text
              v-if="
                selectedFormUserSubmittedData &&
                selectedForm.fields &&
                selectedForm.fields.length > 0
              "
            >
              <v-alert type="success" text>Заявка успешно отправлена</v-alert>
            </v-card-text>
          </v-fade-transition>
          <v-card-text
            v-if="
              selectedForm.limit &&
              !selectedFormUserSubmittedData &&
              selectedForm.fields &&
              selectedForm.fields.length > 0
            "
          >
            <v-alert
              text
              :type="
                selectedForm.limit - selectedForm.usage > 0 ? 'info' : 'warning'
              "
              >{{
                selectedForm.limit - selectedForm.usage > 0
                  ? `Мест осталось: ${selectedForm.limit - selectedForm.usage}`
                  : "Регистрация завершена"
              }}</v-alert
            >
          </v-card-text>
          <v-card-text
            v-if="
              !selectedForm.limit || selectedForm.limit - selectedForm.usage > 0
            "
          >
            <v-text-field
              v-for="field of selectedForm.fields"
              :key="`input-${field.name}`"
              v-model="formData[field.name]"
              :label="field.label"
            />
          </v-card-text>
          <v-card-actions
            v-if="
              selectedForm.fields &&
              selectedForm.fields.length > 0 &&
              (!selectedForm.limit ||
                selectedForm.limit - selectedForm.usage > 0)
            "
          >
            <v-spacer></v-spacer>
            <v-btn color="secondary" text @click="formDialog = false">
              Отмена
            </v-btn>
            <v-btn
              color="primary"
              text
              @click="onFormSubmit"
              :disabled="!selectedFormIsValid"
            >
              {{
                selectedFormUserSubmittedData ? "Обновить заявку" : "Записаться"
              }}
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
          <v-card-actions v-else>
            <v-spacer></v-spacer>
            <v-btn color="secondary" text @click="formDialog = false">
              Закрыть
            </v-btn>
          </v-card-actions>
          <v-snackbar v-model="snackbar" :value="true" :color="messageStatus">{{
            message
          }}</v-snackbar>
        </v-card>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import moment from "moment-timezone";
moment.locale("ru");
import Calendar from "@/components/event/Content/Calendar";
import VideoPlayer from "@/components/video/Index";
import formsMixin from "@/mixins/eventForms";
import _ from "lodash";
import { mapActions, mapMutations } from "vuex";

export default {
  name: "KiaAdventCalendar",
  props: ["item-id", "params"],
  mixins: [formsMixin],
  components: {
    Calendar,
    VideoPlayer,
  },
  data() {
    return {
      formDialog: false,
      selectedDate: null,
      formData: {},
      snackbar: false,
      message: null,
      messageStatus: null,
      defaultImage: require("./assets/Logo_W.png"),
    };
  },
  watch: {
    selectedFormId() {
      this.message = null;
      this.messageStatus = null;
    },
  },
  computed: {
    dates() {
      return _.filter(Object.keys(_.get(this, "params.forms") || {}), (key) => {
        const m = moment(key);
        return m.isValid();
      });
    },
    formsByDate() {
      return _.get();
    },
    selectedFormId() {
      if (this.selectedDate == null) return null;
      return this.params.forms && this.params.forms[this.selectedDate];
    },
    selectedFormUserSubmittedData() {
      if (this.selectedForm == null) return null;
      const forms = _.get(this, "user.forms") || {};
      const form = forms[this.selectedForm.id];
      return form && form.data;
    },
    selectedForm() {
      if (this.selectedFormId == null) return null;
      return this.formsById[this.selectedFormId];
    },
    selectedFormIsValid() {
      if (this.selectedForm == null) return false;
      for (const field of this.selectedForm.fields) {
        const value = this.formData[field.name];
        if (!value) return false;
      }
      return true;
    },
  },
  methods: {
    ...mapActions({
      runCommand: "event/runCommand",
    }),
    ...mapMutations({
      setUserForm: "event/setUserForm",
    }),
    onDateSelect(date) {
      this.selectedDate = date;
      if (this.selectedForm) {
        const formData = {
          ..._.reduce(
            _.get(this.selectedForm, "fields"),
            (acc, field) => {
              acc[field.name] = "";
              return acc;
            },
            {}
          ),
          ...this.selectedFormUserSubmittedData,
        };
        this.formData = formData;
        this.formDialog = true;
      }
    },
    async onFormSubmit() {
      const { itemId, selectedFormId, selectedFormUserSubmittedData } = this;
      const isAlreadySubmitted = !_.isEmpty(selectedFormUserSubmittedData);
      const payload = {
        itemId,
        command: isAlreadySubmitted ? "form|update" : "form|submit",
        params: {
          formId: selectedFormId,
          data: this.formData,
        },
      };
      try {
        const response = await this.runCommand(payload);
        this.message = response.message;
        this.messageStatus = "success";
        this.snackbar = true;
        this.setUserForm({ id: this.selectedFormId, data: this.formData });
      } catch (err) {
        this.message = err && err.message;
        this.messageStatus = "error";
        this.snackbar = true;
      }
    },
  },
};
</script>