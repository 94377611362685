<template>
  <div>
    <div class="page-memory">
      <div>
        <h3>БИТВА СНЕЖКАМИ ⛄️</h3>
        <div>
          <p>🆕 А вот и новиночка - БИТВА СНЕЖКАМИ ⛄️</p>

          <p>
            😁 Самая зимняя забава, к тому же по известным всем правилам
            морского боя 🛳️
          </p>

          <p>
            ❄️ вызываете соперника из таблицы (пока любого)<br />
            ❄️ оппоненту прилетает прилашение на почту, с временем жизни 10
            минут<br />
            ❄️ рекомендуем отыскать напарника по внутрикорпоративным каналам для
            уверенности в битве<br />
            ❄️ после подтверждения поединка у вас 10 минут на расстановку своих
            новогодних укреплений<br />
            ❄️ жмем кнопку готовности и... понеслась!<br />
            ❄️ регламент игры прописан по в разделе ПРАВИЛА на экране<br />
            ❄️ веселимся, задуряемся, готовимся к турниру<br />
            ❄️ скоро стартанет и сам турнир, где будут жесткие правила по выбору
            соперника и ограниченные 10 игр<br />
            ❄️ ожидайте анонса Турнира в шапке Адвента<br />
          </p>

          <p>Ух, уже прилетел снежок. Пошли поднимать настроение... 🥳</p>
        </div>
      </div>
    </div>
    <v-dialog @input="onCloseAttempt" :max-width="400" :value="dialog">
      <div style="width: 100%; background-color: white">
        <v-card>
          <v-card-title>Вызов на Битву Снежками</v-card-title>
          <div v-if="status === 'ready'">
            <div class="mb-0 ml-4 mr-4">
              <div class="d-flex align-center pb-0" v-if="!isMyChallenge">
                <v-avatar class="ma-2" color="primary">
                  <v-img
                    v-if="selectedChallenge.from.thumbnail"
                    :src="selectedChallenge.from.thumbnail"
                    :alt="selectedChallenge.from.name"
                  />
                  <span v-else class="white--text text-h6">{{
                    getInitials(selectedChallenge.from.name)
                  }}</span>
                </v-avatar>
                <h3 class="pl-2">{{ selectedChallenge.from.name }}</h3>
              </div>
              <div class="d-flex align-center pb-0" v-else>
                <v-avatar class="ma-2" color="primary">
                  <v-img
                    v-if="selectedChallenge.to.thumbnail"
                    :src="selectedChallenge.to.thumbnail"
                    :alt="selectedChallenge.to.name"
                  />
                  <span v-else class="white--text text-h6">{{
                    getInitials(selectedChallenge.to.name)
                  }}</span>
                </v-avatar>
                <h3 class="pl-2">{{ selectedChallenge.to.name }}</h3>
              </div>
            </div>
            <div class="mb-4 ml-4 mr-4 pb-4 d-flex justify-center">
              <template>
                <v-btn
                  @click="onCancelChallenge"
                  color="error"
                  :disabled="isFinished"
                  >{{
                    !isFinished && selectedChallengeRemaining
                      ? `${selectedChallengeRemaining} | `
                      : ""
                  }}Отменить вызов</v-btn
                >
              </template>
              <v-spacer></v-spacer>
              <template v-if="!isMyChallenge">
                <v-btn
                  @click="onAcceptChallenge"
                  color="primary"
                  class="ml-2"
                  :disabled="isFinished"
                  >Принять</v-btn
                >
              </template>
            </div>
          </div>
          <div v-if="status === 'expired'">
            <v-card>
              <v-card-title>
                Время ожидания истекло. Вызов отменен.
              </v-card-title>
            </v-card>
          </div>
          <div v-if="status === 'cancelled'">
            <v-card>
              <v-card-title> Вызов был отменен. </v-card-title>
            </v-card>
          </div>
        </v-card>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import _ from "lodash";

const getInitials = (name) => {
  if (typeof name !== "string") {
    return null;
  }
  if (name === "") return "";
  return name
    .split(" ")
    .filter((item) => item !== "")
    .map((item) => `${item[0]}`.toUpperCase())
    .reduce((acc, item) => {
      if (acc.length >= 2) return acc;
      return `${acc}${item}`;
    }, "");
};

export default {
  name: "Challenge",
  components: {},
  data() {
    return {
      getInitials,
      chatMessage: "",
      dialog: true,
    };
  },
  computed: {
    ...mapGetters({
      selectedChallenge: "event/challenge/selectedChallenge",
      selectedChallengeRemaining: "event/challenge/selectedChallengeRemaining",
      user: "event/user",
    }),
    isFinished() {
      return ["cancelled", "expired", "resolved"].includes(
        this.selectedChallenge.status
      );
    },
    gameType() {
      return _.get(this.selectedChallenge, "gameId");
    },
    status() {
      return _.get(this.selectedChallenge, "status");
    },
    userId() {
      return _.get(this.user, "id");
    },
    fromUserId() {
      return _.get(this.selectedChallenge, "from.id");
    },
    toUserId() {
      return _.get(this.selectedChallenge, "to.id");
    },
    isMyChallenge() {
      return this.fromUserId === this.userId;
    },
  },
  methods: {
    ...mapActions({
      cancelChallenge: "event/challenge/cancelChallenge",
      acceptChallenge: "event/challenge/acceptChallenge",
      activateChallenge: "event/challenge/activateChallenge",
      runChallengeCommand: "event/challenge/runChallengeCommand",
      postChallengeChatMessage: "event/challenge/postChallengeChatMessage",
    }),
    ...mapMutations({
      setActiveChallenge: "event/challenge/setActiveChallenge",
      setSelectedChallenge: "event/challenge/setSelectedChallenge",
    }),
    onCloseAttempt() {
      if (
        this.selectedChallenge == null ||
        ["cancelled", "expired", "resolved"].includes(
          this.selectedChallenge.status
        )
      ) {
        this.dialog = false;
        this.setActiveChallenge(null);
        this.setSelectedChallenge(null);
      } else {
        this.dialog = true;
      }
    },
    onCancelChallenge() {
      this.cancelChallenge(this.selectedChallenge._id);
    },
    onAcceptChallenge() {
      this.acceptChallenge(this.selectedChallenge._id);
    },
    onActivateChallenge() {
      this.activateChallenge(this.selectedChallenge._id);
    },
    onChallengeCommand({ command, params }) {
      this.runChallengeCommand({
        id: this.selectedChallenge._id,
        command,
        params,
      });
    },
    async onChatMessageChange() {
      const message = this.chatMessage.trim();
      if (!message) return;
      this.chatMessage = "";
      this.postChallengeChatMessage({
        id: this.selectedChallenge._id,
        message,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.page-memory {
  box-sizing: border-box;
  padding: 20px;
  overflow-y: auto;
  height: 100%;
  text-align: center;
  align-items: center;
  align-content: center;
}
.page-memory h3 {
  font-family: var(--secondary-font);
  font-size: 20px;
  color: var(--color-primary);
  text-align: center;
  margin-bottom: 10px;
}
.page-memory p {
  font-family: var(--main-font);
  font-size: 14px;
  line-height: 140%;
  color: var(--color-text);
  margin-bottom: 20px;
  text-align: left;
}
.page-memory__centered {
  margin: 30px auto 0;
  display: flex;
  flex-direction: column;
  gap: 30px;
  justify-content: center;
  align-items: center;
}
.page-memory__centered > img {
  width: 205px;
  height: 205px;
}
.page-memory__btn {
  padding: 10px;
  background-color: var(--color-primary);
  color: white;
  border-radius: 20px;
  cursor: pointer;
  width: 160px;
}
.page-memory__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}
.page-memory__header--status {
  font-family: var(--main-font);
  font-size: 14px;
  color: var(--color-text-memory);
}
.page-memory__header--status span {
  color: var(--color-primary);
}
main {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  max-width: 510px;
  margin: 0 auto;
  column-gap: 10px;
  row-gap: 10px;
}
button {
  margin-top: 20px;
  font-size: 0.8rem;
  padding: 8px 10px;
  text-transform: uppercase;
  background: #f2add2;
  border: none;
  color: white;
  border-radius: 4px;
}
@media (max-width: 640px) {
  main {
    grid-template-columns: repeat(4, 1fr);
  }
  .page-memory {
    padding: 0px;
  }
}
</style>