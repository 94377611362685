<template>
  <v-scale-transition>
    <v-alert
      text
      class="blue lighten-5"
      color="green"
      type="success"
      v-if="gameTask.status === 'success'"
    >
      Вы ответили правильно и набрали {{ gameTask.points }} балл(ов)
    </v-alert>
    <v-alert
      text
      class="blue lighten-5"
      color="green"
      type="success"
      v-if="gameTask.status === 'partial-success'"
    >
      Вы ответили частично правильно и набрали {{ gameTask.points }} балл(ов)
    </v-alert>
    <v-alert
      text
      class="blue lighten-5"
      type="error"
      v-if="gameTask.status === 'expired'"
    >
      Время, отведенное на задание истекло
    </v-alert>
    <v-alert
      text
      class="blue lighten-5"
      type="error"
      v-if="gameTask.status === 'failed'"
    >
      Вы не смогли справиться с заданием. Удачи в других :)
    </v-alert>
    <v-alert
      text
      class="blue lighten-5"
      type="info"
      v-if="false && gameTask.pendingStatus === 'pending'"
    >
      Ваш ответ будет оценен в ближайшее время оператором игры.
    </v-alert>
  </v-scale-transition>
</template>

<script>
export default {
  name: "QuestTaskStatus",
  props: ["gameTask", "questTask"],
};
</script>