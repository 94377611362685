<template>
  <div v-if="quest && game && gameTasks">
    <template v-if="mode === 'quest'">
      <template v-if="isInlineQuestTask">
        <quest-task
          v-if="selectedQuestTask"
          :quest-task="selectedQuestTask"
          :game-task="selectedGameTask"
          @answer="onTaskAnswer"
          @fact-activation="onFactActivation"
          @hint-activation="onHintActivation"
          @answer-file-selected="onTaskAnswerFileSelected"
          :is-admin="isAdmin"
          @inline-answer="onInlineAnswer"
          style="width: 100%"
          :uploading="uploading"
          :key="`inline-game-task-${selectedGameTaskId}`"
        >
          <template v-slot:answer-status>
            <v-snackbar
              :value="!!answerMessage"
              @input="answerMessage = ''"
              :color="answerMessageType"
              centered
              :timeout="5000"
            >
              <div style="font-size: 20px; font-weight: 500">
                {{ answerMessage }}
              </div>
            </v-snackbar>
            <v-alert
              v-if="!!answerMessage"
              text
              :type="answerMessageType"
              class="ma-2"
              transition="scale-transition"
            >
              {{ answerMessage }}
            </v-alert>
          </template>
        </quest-task>
      </template>
      <calendar
        v-else-if="isCalendarQuest"
        v-bind="calendarData"
        @select-date="onCalendarSelectDate"
      />
      <component
        :is="standingsComponent"
        :items="standings"
        :myGameId="gameId"
        :item-id="itemId"
        :myPoints="totalPoints"
        :params="params"
        v-else-if="isStandings"
      />
      <div v-else style="background-color: transparent">
        <quest-tasks-list
          v-if="availableActiveGameTasks.length > 0"
          title="Доступные задания"
          :tasks="availableActiveGameTasks"
          :dependency="dependencyMap"
          @select="
            selectedGameTaskId = $event;
            selectedTaskDialog = true;
          "
        />
        <quest-tasks-list
          v-if="availableFinishedGameTasks.length > 0"
          title="Завершенные задания"
          ƒ
          :tasks="availableFinishedGameTasks"
          :dependency="dependencyMap"
          @select="
            selectedGameTaskId = $event;
            selectedTaskDialog = true;
          "
        />
      </div>
    </template>

    <v-dialog
      v-model="selectedDateDialog"
      :fullscreen="$vuetify.breakpoint.mobile"
      :max-width="$vuetify.breakpoint.mobile ? null : 800"
    >
      <div
        class="d-flex d-flex--quest"
        style="
          margin: 0 !important;
          padding: 0 !important;
          position: relative;
          background-color: transparent;
          overflow-y: scroll;
          width: 100%;
        "
      >
        <quest-task
          v-if="selectedQuestTask"
          :date="selectedDate"
          :quest-task="selectedQuestTask"
          :game-task="selectedGameTask"
          :yesterday-points-ratio="yesterdayPointsRatio"
          @answer="onTaskAnswer"
          @fact-activation="onFactActivation"
          @hint-activation="onHintActivation"
          @answer-file-selected="onTaskAnswerFileSelected"
          @close="selectedDateDialog = false"
          :is-admin="isAdmin"
          style="width: 100%"
        >
          <template
            v-slot:pick-task
            v-if="
              calendarSelectedDateTasksGroups &&
              calendarSelectedDateTasksGroups.length > 1
            "
          >
            <div>
              <tasks-picker
                :groups="calendarSelectedDateTasksGroups"
                :selected-game-task="selectedGameTask"
                @change="selectedGameTaskId = $event"
              />
            </div>
          </template>
          <template v-slot:answer-status>
            <v-snackbar
              :value="!!answerMessage"
              @input="answerMessage = ''"
              :color="answerMessageType"
              centered
              :timeout="5000"
            >
              <div style="font-size: 20px; font-weight: 500">
                {{ answerMessage }}
              </div>
            </v-snackbar>
            <v-alert
              v-if="!!answerMessage"
              text
              :type="answerMessageType"
              class="ma-2"
              transition="scale-transition"
            >
              {{ answerMessage }}
            </v-alert>
          </template>
        </quest-task>
      </div>
    </v-dialog>

    <v-dialog
      v-model="selectedTaskDialog"
      :fullscreen="$vuetify.breakpoint.mobile"
      :max-width="$vuetify.breakpoint.mobile ? null : 800"
    >
      <div class="d-flex" style="position: relative; background-color: white">
        <quest-task
          v-if="selectedQuestTask"
          :quest-task="selectedQuestTask"
          :game-task="selectedGameTask"
          :yesterday-points-ratio="yesterdayPointsRatio"
          @answer="onTaskAnswer"
          @fact-activation="onFactActivation"
          @hint-activation="onHintActivation"
          @answer-file-selected="onTaskAnswerFileSelected"
          :is-admin="isAdmin"
        >
          <template v-slot:answer-status>
            <v-snackbar
              :value="!!answerMessage"
              @input="answerMessage = ''"
              :color="answerMessageType"
              centered
              :timeout="5000"
            >
              <div style="font-size: 20px; font-weight: 500">
                {{ answerMessage }}
              </div>
            </v-snackbar>
            <v-scale-transition>
              <v-alert
                v-if="answerMessage"
                text
                :type="answerMessageType"
                class="ma-2 blue lighten-5"
                transition="scale-transition"
              >
                {{ answerMessage }}
              </v-alert>
            </v-scale-transition>
          </template>
          <template v-if="$vuetify.breakpoint.mobile" v-slot:utils>
            <div style="position: fixed; top: 20px; right: 20px">
              <v-btn
                class="mx-2"
                fab
                dark
                color="secondary"
                small
                @click="selectedTaskDialog = false"
              >
                <v-icon dark> mdi-close </v-icon>
              </v-btn>
            </div>
          </template>
        </quest-task>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import Vue from "vue";
import Calendar from "@/components/event/Content/Calendar";
import TasksPicker from "./TasksPicker.vue";
import QuestTasksList from "./TasksList";
import QuestTask from "./QuestTask";
import QuestStandings from "./QuestStandings";
import { mapActions } from "vuex";
import _ from "lodash";
import moment from "moment-timezone";
import eventBus from "../../../../utils/eventBus";
import userMixin from "../../../../mixins/eventUser";

export default {
  name: "AppQuestContent",
  props: ["params", "itemId", "cmpStandings"],
  mixins: [userMixin],
  components: {
    Calendar,
    QuestTask,
    QuestTasksList,
    QuestStandings,
    TasksPicker,
  },
  data() {
    return {
      loading: false,
      quest: null,
      game: null,
      gameTasks: null,
      mode: "quest", // quest|tasks|task|inline
      selectedGameTaskId: null,
      selectedDateDialog: false,
      selectedTaskDialog: false,
      selectedDate: null,
      answerMessageType: "info", // info, error, warning, success, partial
      answerMessage: "",
      socket: null,
      standings: null,
      uploading: false,
    };
  },
  beforeMount() {
    eventBus.$on("quest:update", (message) => {
      const { quest } = message;
      if (this.quest && this.quest._id === quest) {
        this.fetchQuestInfo();
      }
    });
    eventBus.$on("instance:update", (message) => {
      const { instance } = message;
      if (this.params && this.params.instanceId === instance) {
        this.fetchQuestInfo();
      }
    });
    eventBus.$on("game:update", (message) => {
      const { game } = message;
      if (this.game && this.game._id === game) {
        this.fetchQuestInfo();
      }
    });
    eventBus.$on("standings:update", () => {
      if (this.isStandings) {
        this.fetchStandings();
      }
    });
  },
  beforeDestroy() {
    eventBus.$off("quest:update");
    eventBus.$off("instance:update");
    eventBus.$off("game:update");
    eventBus.$off("standings:update");
  },
  created() {
    this.fetchQuestInfo();
    if (this.isStandings) {
      this.fetchStandings();
    }
  },
  watch: {
    itemId() {
      this.fetchQuestInfo();
    },
    isStandings(newValue) {
      if (newValue) {
        this.fetchStandings();
      }
    },
    gameTaskIdCandidate(newValue) {
      if (newValue != null) {
        this.selectedGameTaskId = newValue;
      }
    },
    availableGameTasks(val) {
      if (val != null && this.isInlineQuestTask) {
        const questTaskName = _.get(this.params, "questTaskName");
        if (questTaskName == null) return;
        const item = _.find(
          val,
          (item) => item.questTask.name === questTaskName
        );
        if (item != null) {
          this.selectedGameTaskId = item.gameTask._id;
        }
      }
    },
  },
  computed: {
    questInfo() {
      if (this.quest == null) return null;
      const locale = _.omit(
        _.find(_.get(this.quest, "localization"), (l) => l.language === "ru"),
        ["_id", "language"]
      );
      const result = {
        ..._.pick(this.quest, [
          "_id",
          "brangindAnswerButtonImage",
          "brangindFinalImage",
          "brangindMainImage",
          "chatEnabled",
          "createDate",
          "distance",
          "duration",
          "hideOtherTasksLocation",
          "image",
          "location",
          "name",
          "statsEnabled",
          "trackLocation",
          "updateDate",
          "userCanPickTask",
        ]),
        ...Object.keys(locale || {}).reduce((acc, key) => {
          const value = locale[key];
          if (value != null) {
            acc[key] = value;
          }
          return acc;
        }, {}),
      };
      return result;
    },
    questTasksByIDs() {
      const tasks = _.map(_.get(this.quest, "tasks"), (task) => {
        const locale = _.omit(
          _.find(_.get(task, "localization"), (l) => l.language === "ru"),
          ["_id", "language", "options"]
        );
        const result = {
          ..._.pick(task, [
            "_id",
            "acceptUniversalAnswer",
            "attempts",
            "audio",
            "createDate",
            "distance",
            "duration",
            "factImage",
            "image",
            "isMultipe",
            "kind",
            "location",
            "maxAnswersCount",
            "minAnswersCount",
            "name",
            "options",
            "points",
            "public",
            "universalAnswerPoints",
            "updateDate",
            "video",
            "hints",
            "tags",
            "dependency",
            "multiAnswersGroups",
          ]),
          ...Object.keys(locale || {}).reduce((acc, key) => {
            const value = locale[key];
            if (value != null) {
              acc[key] = value;
            }
            return acc;
          }, {}),
        };
        return result;
      });
      return _.keyBy(tasks, "_id");
    },
    gameTasksByIDs() {
      return _.keyBy(this.gameTasks, "_id");
    },
    availableGameTasks() {
      if (this.quest == null || this.gameTasks == null || this.game == null)
        return [];
      const tasks = _.map(_.get(this.game, "tasks"), (gameTaskId) => {
        const gameTask = this.gameTasksByIDs[gameTaskId];
        if (gameTask == null) return null;
        const questTask = this.questTasksByIDs[gameTask.task];
        return {
          gameTask,
          questTask,
        };
      })
        .filter(
          (item) =>
            item &&
            item.questTask &&
            item.questTask.public === true &&
            (this.calendarQuestTasksByIDs[item.questTask._id] ||
              !this.params.calendar)
        )
        .map((item) => {
          return {
            ...item,
            ..._.pick(this.calendarQuestTasksByIDs[item.questTask._id], [
              "from",
              "to",
            ]),
          };
        });
      return tasks;
    },
    availableActiveGameTasks() {
      return this.availableGameTasks.filter(({ gameTask }) => {
        return ["ready", "current", "pending", "postponed"].includes(
          gameTask.status
        );
      });
    },
    availableFinishedGameTasks() {
      return this.availableGameTasks.filter(({ gameTask }) => {
        return !["ready", "current", "pending", "postponed"].includes(
          gameTask.status
        );
      });
    },
    selectedGameTask() {
      return this.selectedGameTaskId == null
        ? null
        : this.gameTasksByIDs[this.selectedGameTaskId];
    },
    selectedQuestTask() {
      return this.selectedGameTask == null
        ? null
        : this.questTasksByIDs[this.selectedGameTask.task];
    },
    isCalendarQuest() {
      return _.get(this.params, "mode") === "advent";
    },
    isInlineQuestTask() {
      return _.get(this.params, "mode") === "inline";
    },
    isStandings() {
      return _.get(this.params, "mode") === "standings";
    },
    calendarQuestTasks() {
      return _.get(this.params, "calendar.tasks") || [];
    },
    calendarQuestTasksByIDs() {
      return _.keyBy(this.calendarQuestTasks, "taskId");
    },
    calendarAvailableDates() {
      const result = this.calendarQuestTasks.reduce((acc, { from, to }) => {
        const fromDt = moment(from);
        const toDt = moment(to);
        if (fromDt.isSameOrBefore(toDt, "day")) {
          let currentItem = fromDt;
          while (currentItem.isSameOrBefore(toDt, "day")) {
            acc.add(currentItem.format("YYYY-MM-DD"));
            currentItem = currentItem.add(1, "days");
          }
        }
        return acc;
      }, new Set());
      return result;
    },
    calendarDates() {
      if (!this.isCalendarQuest) return null;
      const result = this.availableGameTasks.reduce((acc, { questTask }) => {
        const dates = _.filter(questTask.tags, (tag) =>
          /^\d+-\d+-\d+$/.test(tag)
        );
        return [...acc, ...dates];
      }, []);
      return result;
    },
    calendarData() {
      if (!this.isCalendarQuest) return null;
      const result = {
        dates: this.calendarDates,
        points: this.calendarDates.reduce((acc, item) => {
          const date = item;
          acc[item] = this.availableFinishedGameTasks
            .filter((item) => {
              return item.questTask.tags.includes(`${date}`);
            })
            .reduce((acc, gameTask) => {
              if (acc == null) return gameTask.gameTask.points;
              return acc + gameTask.gameTask.points;
            }, null);
          return acc;
        }, {}),
        finished: this.calendarDates.reduce((acc, item) => {
          const date = item;
          const availableActiveGameTasks = this.availableGameTasks.filter(
            (item) => item.questTask.tags.includes(`${date}`)
          );
          const availableFinishedGameTasks =
            this.availableFinishedGameTasks.filter((item) =>
              item.questTask.tags.includes(`${date}`)
            );
          acc[item] =
            availableActiveGameTasks.length ===
              availableFinishedGameTasks.length &&
            availableFinishedGameTasks.length > 0;
          return acc;
        }, {}),
        images: _.get(this.params, "images") || {},
        showBorder:
          _.get(this.params, "show-border") == null
            ? true
            : _.get(this.params, "show-border"),
        showNumber:
          _.get(this.params, "show-number") == null
            ? true
            : _.get(this.params, "show-number"),
        disableFuture: !this.isAdmin,
        icons: this.params.icons,
      };
      return result;
    },
    calendarSelectedDateTasks() {
      if (this.selectedDate == null) return [];
      const d = moment(this.selectedDate);
      return this.availableGameTasks.filter(({ questTask }) => {
        const isInSelectedDate =
          _.find(questTask.tags, (tag) => {
            if (/^\d+-\d+-\d+$/.test(tag)) {
              if (d.isSame(moment(tag), "day")) return true;
            }
            return false;
          }) != null;
        return isInSelectedDate;
      });
    },
    calendarSelectedDateTasksGroups() {
      const completedStatuses = [
        "success",
        "partial-success",
        "expired",
        "failed",
      ];
      if (this.calendarSelectedDateTasks.length === 0) return [];
      const rootTasks = this.calendarSelectedDateTasks.filter((item) => {
        const currentQuestTaskId = item.questTask._id;
        if (_.isEmpty(item.questTask.dependency)) return true;
        let isDependencyInToday = false;
        for (const checkItem of this.calendarSelectedDateTasks) {
          if (
            _.find(
              checkItem.questTask.dependency,
              (id) => id === currentQuestTaskId
            )
          ) {
            isDependencyInToday = true;
            break;
          }
        }
        return !isDependencyInToday;
      });
      const fetchTaskChilds = (rootId, acc) => {
        const directChilds = this.calendarSelectedDateTasks.filter((item) => {
          return _.find(item.questTask.dependency, (id) => rootId === id);
        });
        for (const childItem of directChilds) {
          acc.push({
            ...childItem,
            enabled: !this.dependencyMap[childItem.gameTask._id],
            completed: completedStatuses.includes(childItem.gameTask.status),
          });
          fetchTaskChilds(childItem.questTask._id, acc);
        }
      };
      const groups = rootTasks.map((item) => {
        const childs = [];
        fetchTaskChilds(item.questTask._id, childs);
        return {
          ...item,
          enabled: !this.dependencyMap[item.gameTask._id],
          completed: completedStatuses.includes(item.gameTask.status),
          childs,
        };
      });
      return groups;
    },
    gameTaskByQuestTaskIDs() {
      return _.keyBy(this.gameTasks, "task");
    },
    dependencyMap() {
      return _.reduce(
        this.gameTasks,
        (acc, gameTask) => {
          const questTaskId = gameTask.task;
          const questTask = this.questTasksByIDs[questTaskId];
          const dependency = (questTask && questTask.dependency) || [];
          const isDependencyNotClear =
            dependency.find((id) => {
              const gameTask = this.gameTaskByQuestTaskIDs[id];
              if (gameTask == null) return false;
              return !["success", "expired", "failed"].includes(
                gameTask.status
              );
            }) != null;
          acc[gameTask._id] = isDependencyNotClear;
          return acc;
        },
        {}
      );
    },
    totalPoints() {
      return _.reduce(
        this.gameTasks,
        (points, task) => points + task.points,
        0
      );
    },
    gameId() {
      return this.game && this.game._id;
    },
    standingsComponent() {
      return this.cmpStandings || QuestStandings;
    },
    gameTaskIdCandidate() {
      if (_.isEmpty(this.calendarSelectedDateTasksGroups)) return null;

      let firstNotCompleted = null;
      let lastCompleted = null;
      for (const group of this.calendarSelectedDateTasksGroups) {
        const { enabled, completed, gameTask, childs } = group;
        if (enabled && !completed && !firstNotCompleted) {
          firstNotCompleted = gameTask;
        } else if (enabled && completed) {
          lastCompleted = gameTask;
        }
        if (!_.isEmpty(childs)) {
          for (const childItem of childs) {
            const { enabled, completed, gameTask } = childItem;
            if (enabled && !completed && !firstNotCompleted) {
              firstNotCompleted = gameTask;
            } else if (enabled && completed) {
              lastCompleted = gameTask;
            }
          }
        }
      }
      if (firstNotCompleted) {
        return firstNotCompleted._id;
      } else if (lastCompleted) {
        return lastCompleted._id;
      } else {
        return this.calendarSelectedDateTasksGroups[0].gameTask._id;
      }
    },
    yesterdayPointsRatio() {
      const defaultValue = 0.5;
      const rawValue = _.get(this.params, "yesterday-points-ratio");
      if (typeof rawValue === "string") {
        const parsedValue = Number.parseFloat(rawValue);
        return Number.isNaN(parsedValue) ? parsedValue : defaultValue;
      } else if (typeof rawValue === "number") {
        return rawValue;
      } else {
        return defaultValue;
      }
    },
  },
  methods: {
    ...mapActions({
      runCommand: "event/runCommand",
      uploadFile: "event/uploadFileToS3Storage",
    }),
    onInlineAnswer($evt) {
      const { answer, file } = $evt;
      this.onTaskAnswerFileSelected(file, answer);
    },
    async fetchQuestInfo() {
      this.loading = true;
      const { itemId } = this;
      const command = "getGame";
      const { quest, game, gameTasks } = await this.runCommand({
        itemId,
        command,
      });
      this.quest = quest;
      this.game = game;
      this.gameTasks = gameTasks;
      this.loading = false;
    },
    async fetchStandings() {
      this.loading = true;
      const { itemId } = this;
      const command = "getGameStandings";
      try {
        const result = await this.runCommand({
          itemId,
          command,
        });
        this.standings = result;
      } catch (err) {
        console.error(err);
      }
      this.loading = false;
    },
    onCalendarSelectDate(date) {
      if (this.calendarDates.includes(date)) {
        this.selectedDate = date;

        let firstNotCompleted = null;
        let lastCompleted = null;
        for (const group of this.calendarSelectedDateTasksGroups) {
          const { enabled, completed, gameTask, childs } = group;
          if (enabled && !completed && !firstNotCompleted) {
            firstNotCompleted = gameTask;
          } else if (enabled && completed) {
            lastCompleted = gameTask;
          }
          if (!_.isEmpty(childs)) {
            for (const childItem of childs) {
              const { enabled, completed, gameTask } = childItem;
              if (enabled && !completed && !firstNotCompleted) {
                firstNotCompleted = gameTask;
              } else if (enabled && completed) {
                lastCompleted = gameTask;
              }
            }
          }
        }
        if (firstNotCompleted) {
          this.selectedGameTaskId = firstNotCompleted._id;
        } else if (lastCompleted) {
          this.selectedGameTaskId = lastCompleted._id;
        } else {
          this.selectedGameTaskId =
            this.calendarSelectedDateTasksGroups[0].gameTask._id;
        }
        this.selectedDateDialog = true;
      }
    },
    async onFactActivation() {
      const { itemId } = this;
      const command = "activateFact";
      const params = {
        taskId: this.selectedQuestTask._id,
        gameTaskId: this.selectedGameTaskId,
      };
      try {
        await this.runCommand({
          itemId,
          command,
          params,
        });
        await this.fetchQuestInfo();
      } catch (err) {
        if (err && err.message) {
          alert(err.message);
        }
      }
    },
    async onHintActivation() {
      const { itemId } = this;
      const command = "activateHint";
      const params = {
        taskId: this.selectedQuestTask._id,
        gameTaskId: this.selectedGameTaskId,
      };
      try {
        await this.runCommand({
          itemId,
          command,
          params,
        });
        await this.fetchQuestInfo();
      } catch (err) {
        if (err && err.message) {
          alert(err.message);
        }
      }
    },
    async onTaskAnswer(answer, url) {
      const { itemId } = this;
      const command = "sendAnswer";
      const params = {
        taskId: this.selectedQuestTask._id,
        gameTaskId: this.selectedGameTaskId,
        answer,
        ...(url != null ? { url } : {}),
      };
      try {
        const { wrongAnswerMessage, correctAnswerMessage } =
          this.selectedQuestTask;
        const result = await this.runCommand({
          itemId,
          command,
          params,
        });
        await this.fetchQuestInfo();
        const SUCCESS_DEFAULT_MESSAGES = [
          "Верно 👍🏻 идём дальше!",
          "Шикарно! Все верно!",
          "Отлично! Все в точку! Полный вперёд 👏🏻",
          "Двигаемся дальше, здесь всё правильно 👍🏻",
          "Точно в цель! Вот это шикарная работа 👌🏻",
        ];
        const ERROR_DEFAULT_MESSAGES = [
          "Хммм…. Не повезло, а жаль 😞",
          "Прекрасная версия, но увы …",
          "Ну вот нет …",
          "Увы, неверно …",
          "Жаль… в этот раз не свезло 😅",
        ];
        const randomMessage = (messageSet) => () => {
          return messageSet[_.random(0, messageSet.length - 1)];
        };
        const randomSuccessMessage = randomMessage(SUCCESS_DEFAULT_MESSAGES);
        const randomErrorMessage = randomMessage(ERROR_DEFAULT_MESSAGES);
        const notifyStatusMessage = (message, type) => {
          Vue.$toast.open({
            type,
            message,
            position: "top",
            duration: 5000,
            dismissible: true,
          });
        };
        if (result === "success") {
          notifyStatusMessage(
            correctAnswerMessage || randomSuccessMessage(),
            result
          );
        } else if (result === "error") {
          notifyStatusMessage(
            wrongAnswerMessage || randomErrorMessage(),
            result
          );
        } else if (result === "pending") {
          notifyStatusMessage(
            "Ваш ответ принят и будет обработан оператором игры в ближайшее время.",
            "info"
          );
        } else if (result === "partial-success") {
          notifyStatusMessage("Ваш ответ верен, но лишь отчасти :)", "warning");
        }
        // if (this.$vuetify.breakpoint.mobile) {
        //   if (result === "success") {
        //     alert(correctAnswerMessage);
        //   } else if (result === "error") {
        //     alert(wrongAnswerMessage);
        //   } else if (result === "pending") {
        //     alert(
        //       "Ваш ответ принят и будет обработан оператором игры в ближайшее время."
        //     );
        //   } else if (result === "partial-success") {
        //     alert("Ваш ответ верен, но лишь отчасти :)");
        //   }
        // } else {
        //   if (result === "success") {
        //     this.answerMessageType = "success";
        //     this.answerMessage = correctAnswerMessage;
        //   } else if (result === "error") {
        //     this.answerMessageType = "error";
        //     this.answerMessage = wrongAnswerMessage;
        //   } else if (result === "pending") {
        //     this.answerMessageType = "info";
        //     this.answerMessage =
        //       "Ваш ответ принят и будет обработан оператором игры в ближайшее время.";
        //   } else if (result === "partial-success") {
        //     this.answerMessageType = "success";
        //     this.answerMessage = "Ваш ответ верен, но лишь отчасти :)";
        //   }
        //   setTimeout(() => {
        //     this.answerMessage = false;
        //   }, 5000);
        // }
      } catch (err) {
        if (err && err.message) {
          alert(err.message);
        }
      }
    },
    async onTaskAnswerFileSelected(file, answer) {
      this.uploading = true;
      const { itemId } = this;
      const { name, type, size } = file;
      const params = {
        taskId: this.selectedQuestTask._id,
        gameTaskId: this.selectedGameTaskId,
        name,
        type,
        size,
      };
      try {
        const { downloadUrl, uploadUrl, headers } = await this.runCommand({
          itemId,
          command: "getFileUploadURL",
          params,
        });
        // Отправка файла с ответом
        await this.uploadFile({ file, uploadUrl, headers });
        // Отправка ответа (ссылки на загруженный файл) в БД
        await this.onTaskAnswer(answer || downloadUrl, downloadUrl);
      } catch (err) {
        if (err && err.message) {
          alert(err.message);
        }
      } finally {
        this.uploading = false;
      }
    },
  },
};
</script>

<style>
.v-toast__item {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
.d-flex--quest {
  height: 90vh !important;
}
@media (max-width: 1280px) {
  .d-flex--quest {
    height: 100vh !important;
  }
}
</style>
