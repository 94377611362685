<template>
  <event-layout :event-id="eventId" />
</template>

<script>
import EventLayout from "@/layouts/advent-one/Index.vue";

export default {
  name: "AppPageEvent",
  components: {
    EventLayout,
  },
  data() {
    return {
      eventId: this.$route.params.id || "greenatom",
    };
  },
  created() {},
};
</script>