<template>
  <div
    class="page-wrap-full"
    @click="$emit('click')"
    :style="{ cursor: clickable ? 'pointer' : 'default' }"
  >
    <div class="page-countdown">
      <p>Немного терпения!</p>
      <h2>До старта адвента осталось:</h2>
      <div class="page-countdown__timer">
        <div class="page-countdown__timer--item">
          <p>Дни</p>
          <div>{{ days }}</div>
        </div>
        <div class="page-countdown__timer--item">
          <p>Часы</p>
          <div>{{ hours }}</div>
        </div>
        <div class="page-countdown__timer--item">
          <p>Минуты</p>
          <div>{{ minutes }}</div>
        </div>
        <div class="page-countdown__timer--item">
          <p>Секунды</p>
          <div>{{ seconds }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment-timezone";

const formatN = (input) => {
  return `${input < 10 ? "0" : ""}${input}`;
};

export default {
  props: ["diff", "clickable"],
  computed: {
    duration() {
      return moment.duration(this.diff);
    },
    days() {
      return formatN(this.duration.days());
    },
    hours() {
      return formatN(this.duration.hours());
    },
    minutes() {
      return formatN(this.duration.minutes());
    },
    seconds() {
      return formatN(this.duration.seconds());
    },
  },
};
</script>

<style scoped>
.page-wrap-full {
  box-sizing: border-box;
  width: 100%;
  height: 100vh;
  padding: 0 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.page-countdown {
  max-width: 360px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.page-countdown p {
  text-align: center;
  font-family: var(--main-font);
  font-size: 14px;
  color: var(--color-text);
}
.page-countdown h2 {
  font-family: var(--secondary-font);
  font-size: 20px;
  color: var(--color-primary);
  text-align: center;
  margin: 25px 0;
}
.page-countdown__timer {
  display: flex;
  gap: 10px;
}
.page-countdown__timer--item > p {
  text-align: center;
  font-family: var(--main-font);
  font-size: 8px;
  font-weight: 500;
  color: var(--color-text);
  text-transform: uppercase;
  margin-bottom: 5px;
}
.page-countdown__timer--item > div {
  box-sizing: border-box;
  width: 49px;
  height: 51px;
  padding: 10px;
  border-radius: 5px;
  background: #276a5e;
  font-family: var(--main-font);
  font-size: 24px;
  line-height: 29px;
  font-weight: 500;
  color: #fff;
  text-align: center;
}
</style>