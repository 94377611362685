var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"page-wrap"},[_c('header',[_c('div',{staticClass:"header__welcome"},[_c('img',{staticClass:"header__welcome--nav-btn",attrs:{"src":"https://storage.yandexcloud.net/yo-events/advent-2025/img/OTR/icon-nav.png"},on:{"click":function($event){return _vm.setIsLeftMenuForMobileVisible(true)}}}),_c('img',{staticClass:"header__welcome--av",staticStyle:{"object-fit":"cover"},style:({ borderRadius: _vm.profileThumbnail ? '100px' : '' }),attrs:{"src":_vm.profileThumbnail || _vm.menuWelcomeImg}}),_c('h2',[_vm._v(" Привет,"),_c('br'),_c('span',[_vm._v(_vm._s(_vm.user.profile.name))])])]),_c('div',{staticClass:"header__controls"},[(_vm.activeChallenge)?_c('div',{staticClass:"header__control control-user"},[_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('img',_vm._g(_vm._b({attrs:{"src":"https://storage.yandexcloud.net/yo-events/advent-2025/img/icons/icon-snow-fight.png"},on:{"click":_vm.onSnowFightClick}},'img',attrs,false),on))]}}],null,false,1491287218)},[_c('span',[_vm._v("Вызов на снежный бой")])])],1):_vm._e(),_c('div',{staticClass:"header__control control-user"},[_c('v-menu',{attrs:{"bottom":"","close-on-content-click":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('img',_vm._g(_vm._b({attrs:{"src":"https://storage.yandexcloud.net/yo-events/advent-2025/img/OTR/icon-user.png"}},'img',attrs,false),on))]}}])},[_c('div',{staticClass:"header__control--menu"},[_c('div',{staticClass:"header__control--menu--item",on:{"click":_vm.onIsMyProfile}},[_c('img',{attrs:{"src":"https://storage.yandexcloud.net/yo-events/advent-2025/img/OTR/icon-user.png"}}),_c('span',[_vm._v("Мой профиль")])]),_c('div',{staticClass:"header__control--menu--item",on:{"click":_vm.onSignOut}},[_c('img',{attrs:{"src":"https://storage.yandexcloud.net/yo-events/advent-2025/img/OTR/icon-logout.png"}}),_c('span',[_vm._v("Выйти")])])])])],1)])]),_c('menu',{class:{
        'menu-mobile-hidden': !_vm.isLeftMenuForMobileVisible,
        'menu-mobile-visible': _vm.isLeftMenuForMobileVisible,
      },attrs:{"id":"menu"}},[_c('div',{staticClass:"menu__close",on:{"click":function($event){_vm.isLeftMenuForMobileVisible = false}}},[_c('img',{staticClass:"menu__close--img",attrs:{"src":"https://storage.yandexcloud.net/yo-events/advent-2025/img/OTR/icon-close.png"},on:{"click":function($event){_vm.isLeftMenuForMobileVisible = false}}})]),_c('div',{staticClass:"menu__logo"},[_c('img',{attrs:{"src":_vm.menuLogo,"width":"210","height":"60"}})]),_c('div',{staticClass:"menu"},[_c('div',{staticClass:"menu__items"},_vm._l((_vm.visibleItems),function(item,index){return _c('div',{key:index,staticClass:"menu__item",class:{ active: item.id == _vm.activeItemId },on:{"click":function($event){return _vm.onItemSelect(item.id)}}},[_vm._v(" "+_vm._s(item.title)+" ")])}),0),_vm._m(0)])]),_c('div',{staticClass:"content"},[(_vm.activeItem)?_c('event-content',{attrs:{"content":_vm.activeItem.content,"layout":_vm.activeItem.layout,"mapping":_vm.mapping}}):_vm._e()],1)]),(_vm.isMyProfileVisible)?_c('my-profile',{on:{"input":function($event){_vm.isMyProfileVisible = $event}}}):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"menu__happy-new-year",staticStyle:{"display":"none","background-image":"url(https://storage.yandexcloud.net/yo-events/advent-2025/img/OTR/menu-happy-ny.png)"}},[_c('div',{staticClass:"menu__happy-new-year--count"},[_vm._v(" До Нового года осталось ")]),_c('div',{staticClass:"menu__happy-new-year--counter"},[_vm._v("28 дней")])])}]

export { render, staticRenderFns }